import React, { useEffect, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import ReactApexChart from 'react-apexcharts';
import COLORS from '../../../common/data/enumColors';
import DeviceService from '../../../services/DeviceService';
import { useMst } from '../../../models'
import { useNavigate } from 'react-router-dom';
import Page from '../../../layout/Page/Page';

const DonutChart = () => {
	const navigate = useNavigate()

	const [deviceCount, setDeviceCount] = useState([])
	const [userCount, setUserCount] = useState([])
	const [groupCount, setGroupCount] = useState([])
	const [installCount, setInstallCount] = useState([])
	const [errorCount, setErrorCount] = useState([])

	const [deviceTotal, setDeviceTotal] = useState(0)
	const [userTotal, setUserTotal] = useState(0)
	const [groupTotal, setGroupTotal] = useState(0)
	const [installTotal, setInstallTotal] = useState(0)

	const [arTotal, setArTotal] = useState(0)
	const [nrTotal, setNrTotal] = useState(0)
	const [srTotal, setSrTotal] = useState(0)
	const [wrTotal, setWrTotal] = useState(0)

	const [arNomal, setArNomal] = useState(0)
	const [nrNomal, setNrNomal] = useState(0)
	const [srNomal, setSrNomal] = useState(0)
	const [wrNomal, setWrNomal] = useState(0)

	const [arErrors, setArErrors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

	const errorCode = ['BAT Low(b\'d)', '침수', '누수', '독거', '동파', '고온', '검침기 연결', '검침기 통신',
		'Data Limit', 'Water 오염', '유량 과부하', '역류', '옥내 누수', 'BAT Low(W_meter)', '정상']

	const { user } = useMst()

	const errorbit = [
		{ label: '전체', value: 'all', color: '#F5BB00' },
		{ label: '정상', value: 'normal', color: '#E9897E' },
		{ label: '배터리부족', value: 'low_battery', color: '#CF6766' },
		{ label: '침수', value: 'flood', color: '#5A5A66' },
		{ label: '누수', value: 'leak', color: '#A2B8AD' },
		{ label: '장기미사용', value: 'long_term_unused', color: '#FFD700' },
		{ label: '동파', value: 'freeze', color: '#CF4500' },
		{ label: '고온', value: 'high_temperature', color: '#A8DDCA' },
		{ label: '검침기 연결', value: 'meter_connection', color: '#008000' },
		{ label: '검침기 통신', value: 'meter_communication', color: '#0000FF' },
		{ label: 'Data Limit', value: 'data_limit', color: '#800080' },
		{ label: '수질 오염', value: 'water_pollution', color: '#A52A2A' },
		{ label: '유량 과부화', value: 'flow_overload', color: '#D2691E' },
		{ label: '역류', value: 'backflow', color: '#FF00FF' },
		{ label: '옥내 누수', value: 'indoor_leak', color: '#1E90FF' },
		{ label: '배터리 부족', value: 'low_battery', color: '#8B0000' },
	];
	const chartOptions = {
		title: {
			align: 'center'
		},
		colors: errorbit.map(e => e.color),
		chart: {
			type: 'donut',
			height: 250,

		},
		stroke: {
			width: 0,
		},
		labels: errorCode,
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				expandOnClick: true,
				donut: {
					labels: {
						show: true,
						name: {
							show: true,
							fontSize: '24px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 0,
							formatter(val) {
								return val;
							},
						},
						value: {
							show: true,
							fontSize: '16px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 16,
							formatter(val) {
								return val;
							},
						},
					},
				},
			},
		},
		legend: {
			show: false,
			position: 'bottom',
		},
	};

	const [DUMMY_DATA, setDUMMY_DATA] = useState({
		AR: {
			series: arErrors,
			options: {
				...chartOptions,
				title: {
					text: '수도검침',
					align: 'center',
				},
			},
		},
		NR: {
			series: arErrors,
			options: {
				...chartOptions,
				title: {
					text: '층간소음',
					align: 'center',
				},
			},
		},
		SR: {
			series: arErrors,
			options: {
				...chartOptions,
				title: {
					text: '스마트팜',
					align: 'center'
				},
			},
		},
		WR: {
			series: arErrors,
			options: {
				...chartOptions,
				title: {
					text: '수질관리',
					align: 'center'
				},
			},
		},
	})

	// const [state, setState] = useState({
	// 	series: DUMMY_DATA.ALL.series,
	// 	options: chartOptions,
	// });

	const SALE_PER_TAB = {
		ALL: 'all',
		FOTA: 'fota',
	};
	const [activeSalePerTab, setActiveSalePerTab] = useState(SALE_PER_TAB.ALL);

	const op_code = {
		AR: '수도검침(AR)',
		ER: '수도검침(ER)',
		NR: '층간소음',
		WR: '수질관리',
		SR: '스마트팜',
		XR: '미지정'
	}

	useEffect(() => {
		DeviceService.getDeviceDashboard().then(r => {
			if (user.me.type !== 'SUPERADMIN') {
				setDeviceCount(r.data.device.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setUserCount(r.data.users.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setGroupCount(r.data.group.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setErrorCount(r.data.error.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setInstallCount(r.data.install.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
			} else {
				setDeviceCount(r.data.device)
				setUserCount(r.data.users)
				setGroupCount(r.data.group)
				setErrorCount(r.data.error)
				setInstallCount(r.data.install)
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// console.log(user.me.type)

	const errorCheck = (error) => {
		let result = []
		if (error) {
			error.split("").reverse().map((e, i) => {
				if (e === '1') {
					result.push(i)
				}
			})
		}

		return result
	}

	useEffect(() => {
		deviceCount.map(d => setDeviceTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [deviceCount])

	useEffect(() => {
		userCount.map(d => setUserTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [userCount])

	useEffect(() => {
		groupCount.map(d => setGroupTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [groupCount])

	useEffect(() => {
		installCount.map(d => setInstallTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [installCount])

	useEffect(() => {
		let arError = []
		let nrError = []
		let srError = []
		let wrError = []
		let xrError = []

		let arTotal = 0
		let nrTotal = 0
		let srTotal = 0
		let wrTotal = 0

		let arNomal = 0
		let nrNomal = 0
		let srNomal = 0
		let wrNomal = 0

		errorCount.map(e => {
			if (!e?.error || e.error === '00000000000000000000000000000000') {
				if (e.op_code === 'AR' || e.op_code === 'ER') {
					arNomal+=parseInt(e.count)
				}
				if (e.op_code === 'NR') {
					nrNomal += parseInt(e.count)
				}
				if (e.op_code === 'SR') {
					srNomal += parseInt(e.count)
				}
				if (e.op_code === 'WR') {
					wrNomal += parseInt(e.count)
				}
			} else {
				const erchk = errorCheck(e.error)
				if (e.op_code === 'AR' || e.op_code === 'ER') {
					erchk.map(er => {
						if (!arError[er]) arError[er] = 0
						arError[er] += parseInt(e.count)
						arTotal += parseInt(e.count)
					})
				}
				if (e.op_code === 'NR') {
					erchk.map(er => {
						if (!nrError[er]) nrError[er] = 0
						nrError[er] += parseInt(e.count)
						nrTotal += parseInt(e.count)
					})
				}
				if (e.op_code === 'SR') {
					erchk.map(er => {
						if (!srError[er]) srError[er] = 0
						srError[er] += parseInt(e.count)
						srTotal += parseInt(e.count)
					})
				}
				if (e.op_code === 'WR') {
					erchk.map(er => {
						if (!wrError[er]) wrError[er] = 0
						wrError[er] += parseInt(e.count)
						wrTotal += parseInt(e.count)
					})
				}
			}
		})

		let arSeries = []
		let nrSeries = []
		let srSeries = []
		let wrSeries = []
		for (let i = 0; i < errorCode.length; i ++) {
			arSeries.push(arError[i] || 0)
			nrSeries.push(nrError[i] || 0)
			srSeries.push(srError[i] || 0)
			wrSeries.push(wrError[i] || 0)
		}

		setArTotal(arTotal)
		setSrTotal(srTotal)
		setNrTotal(nrTotal)
		setWrTotal(wrTotal)

		setArNomal(parseInt(deviceCount.find(d => d.op_code === 'AR')?.count) + parseInt(deviceCount.find(d => d.op_code === 'ER')?.count) - arTotal)
		setSrNomal(deviceCount.find(d => d.op_code === 'SR')?.count - srTotal)
		setNrNomal(deviceCount.find(d => d.op_code === 'NR')?.count - nrTotal)
		setWrNomal(deviceCount.find(d => d.op_code === 'WR')?.count - wrTotal)

		setDUMMY_DATA({
			AR: {
				series: arSeries,
				options: {
					...chartOptions,
					chart: {
						type: 'donut',
						height: 250,
						events: {
							dataPointSelection: function (event, chartContext, config) {
								// config.seriesIndex 및 config.dataPointIndex를 사용하여 클릭된 데이터 포인트에 접근할 수 있음
								console.log('Clicked on data point: ', config.dataPointIndex);
								// 여기서 원하는 액션을 수행

								console.log(errorbit[config.dataPointIndex].value)
								DeviceService.getDeviceErrorList({
									OP_code: ['AR', 'ER'],
									errorIndex: config.dataPointIndex
								}).then(r => {
									navigate('/collectedData', { state: { IMEI: r.data } })
								})
							}
						}
					},
				},
			},
			NR: {
				series: nrSeries,
				options: {
					...chartOptions,
					chart: {
						type: 'donut',
						height: 250,
						events: {
							dataPointSelection: function (event, chartContext, config) {
								// config.seriesIndex 및 config.dataPointIndex를 사용하여 클릭된 데이터 포인트에 접근할 수 있음
								console.log('Clicked on data point: ', config.dataPointIndex);
								// 여기서 원하는 액션을 수행

								console.log(errorbit[config.dataPointIndex].value)
								DeviceService.getDeviceErrorList({
									OP_code: ['NR'],
									errorIndex: config.dataPointIndex
								}).then(r => {
									navigate('/collectedData', { state: { IMEI: r.data } })
								})
							}
						}
					}
				},
			},
			SR: {
				series: srSeries,
				options: {
					...chartOptions,
					chart: {
						type: 'donut',
						height: 250,
						events: {
							dataPointSelection: function (event, chartContext, config) {
								// config.seriesIndex 및 config.dataPointIndex를 사용하여 클릭된 데이터 포인트에 접근할 수 있음
								console.log('Clicked on data point: ', config.dataPointIndex);
								// 여기서 원하는 액션을 수행

								console.log(errorbit[config.dataPointIndex].value)
								DeviceService.getDeviceErrorList({
									OP_code: ['SR'],
									errorIndex: config.dataPointIndex
								}).then(r => {
									navigate('/collectedData', { state: { IMEI: r.data } })
								})
							}
						}
					}
				},
			},
			WR: {
				series: wrSeries,
				options: {
					...chartOptions,
					chart: {
						type: 'donut',
						height: 250,
						events: {
							dataPointSelection: function (event, chartContext, config) {
								// config.seriesIndex 및 config.dataPointIndex를 사용하여 클릭된 데이터 포인트에 접근할 수 있음
								console.log('Clicked on data point: ', config.dataPointIndex);
								// 여기서 원하는 액션을 수행

								console.log(errorbit[config.dataPointIndex].value)
								DeviceService.getDeviceErrorList({
									OP_code: ['WR'],
									errorIndex: config.dataPointIndex
								}).then(r => {
									navigate('/collectedData', { state: { IMEI: r.data } })
								})
							}
						}
					}
				},
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorCount])

	useEffect(() => {
		// console.log(DUMMY_DATA)
	}, [DUMMY_DATA])
	
	return (
			<Page>
				<div className='row' style={{ marginTop: 20}}>
					<Card stretch className={`custom-box-shadow rounded-2`}>
						<CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} >
							<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 250}}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #2F3268', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#2F3268', color: '#ffffff' }}>
										<CardTitle style={{ fontSize: '1.5rem', marginTop: 10, cursor: 'pointer'}}
											onClick={() => navigate('/collectedData')}
										>전체 단말기 수 : {deviceTotal}</CardTitle>
										{deviceCount?.length > 0 &&
											deviceCount.map((d, i) => (
												i % 2 === 0 &&
												<div key={d.op_code} className='d-flex flex-row justify-content-between text-left'>
														<CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/deviceSettings', { state: { op_code: d.op_code } })}
														>{op_code[d.op_code]} : <b>{parseInt(d.count).toLocaleString()}</b></CardSubTitle>
														{deviceCount[i + 1]?.op_code && <CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/deviceSettings', { state: { op_code: deviceCount[i + 1].op_code } })}
														>{op_code[deviceCount[i + 1].op_code]} : <b>{parseInt(deviceCount[i + 1].count).toLocaleString()}</b></CardSubTitle>}
												</div>
											))
										}
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 250 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #6799FF', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#6799FF', color: '#ffffff' }}>
										<CardTitle style={{ fontSize: '1.5rem', marginTop: 10, cursor: 'pointer'}}
											onClick={() => navigate('/userDataList')}
										>전체 사용자 수 : {userTotal}</CardTitle>
										{
											userCount.map((u, i) => (
												i % 2 === 0 &&
												<div key={u.model} className='d-flex flex-row justify-content-between text-left'>
														<CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/userDataList', { state: { op_code: u.model } })}
														>{op_code[u.model]} : <b>{parseInt(u.count).toLocaleString()}</b></CardSubTitle>
														{userCount[i + 1]?.model && <CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/userDataList', { state: { op_code: userCount[i + 1].model } })}
														>{op_code[userCount[i + 1].model]} : <b>{parseInt(userCount[i + 1].count).toLocaleString()}</b></CardSubTitle>}
												</div>
											))
										}
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 250 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #FE808B', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#FE808B', color: '#ffffff' }}>
										<CardTitle style={{ fontSize: '1.5rem', marginTop: 10, cursor: 'pointer'}}
											onClick={() => navigate('/groupDataList')}
										>전체 그룹 수 : {groupTotal}</CardTitle>
										{
											groupCount.map((u, i) => (
												i % 2 === 0 &&
												<div key={u.model} className='d-flex flex-row justify-content-between text-left'>
														<CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/groupDataList', { state: { op_code: u.model } })}
														>{op_code[u.model]} :<b>{parseInt(u.count).toLocaleString()}</b></CardSubTitle>
														{groupCount[i + 1]?.model && <CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/groupDataList', { state: { op_code: groupCount[i + 1].model } })}
														>{op_code[groupCount[i + 1].model]} : <b>{parseInt(groupCount[i + 1].count).toLocaleString()}</b></CardSubTitle>}
												</div>
											))
										}
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 250 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #FFB063', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#FFB063', color: '#ffffff' }}>
										<CardTitle style={{ fontSize: '1.5rem', marginTop: 10, cursor: 'pointer'}}
											onClick={() => navigate('/deviceInstall')}
										>전체 설치 수 : {installTotal}</CardTitle>
										{
											installCount.map((d, i) => (
												i % 2 === 0 &&
												<div key={d.op_code} className='d-flex flex-row justify-content-between text-left'>
														<CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/deviceInstall', { state: { op_code: d.op_code } })}
														>{op_code[d.op_code]} : <b>{parseInt(d.count).toLocaleString()}</b></CardSubTitle>
														{installCount[i + 1]?.op_code && <CardSubTitle className='mt-3 w-100 pointer'
															onClick={() => navigate('/deviceInstall', { state: { op_code: installCount[i + 1].op_code } })}
														>{op_code[installCount[i + 1].op_code]} : <b>{parseInt(installCount[i + 1].count).toLocaleString()}</b></CardSubTitle>}
												</div>
											))
										}
									</CardBody>
								</Card>
							</div>
						</CardBody>
					</Card>
				</div>
						
				
				<div className='row' style={{ marginTop: 30 }}>
					{(user.me.model === 'NR' || user.me.type === 'SUPERADMIN') &&
					<div className='col-xl-3 col-lg-6 col-md-6 align-items-center text-center' style={{ padding: 10, height: 400 }}>
						<Card stretch className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`} >
							<ReactApexChart
								options={DUMMY_DATA.NR.options}
								series={DUMMY_DATA.NR.series}
								width={'100%'}
								type={DUMMY_DATA.NR.options.chart.type}
								height={DUMMY_DATA.NR.options.chart.height}
							/>
							<div>정상: <span style={{ color: "blue" }}><b>{nrNomal.toLocaleString()}</b></span> / 경고 : <span style={{ color: "red" }}><b>{nrTotal.toLocaleString()}</b></span></div>
							</CardBody>
						</Card>
					</div>
					}
					{(user.me.model === 'SR' || user.me.type === 'SUPERADMIN') &&
						<div className='col-xl-3 col-lg-6 col-md-6 align-items-center text-center' style={{ padding: 10 }}>
						<Card stretch className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`} >
							<ReactApexChart
								options={DUMMY_DATA.SR.options}
								series={DUMMY_DATA.SR.series}
								width={'100%'}
								type={DUMMY_DATA.SR.options.chart.type}
								height={DUMMY_DATA.SR.options.chart.height}
							/>
							<div>정상: <span style={{ color: "blue" }}><b>{srNomal.toLocaleString()}</b></span> / 경고 : <span style={{ color: "red" }}><b>{srTotal.toLocaleString()}</b></span></div>
							</CardBody>	
						</Card>	
						</div>}
					{(user.me.model === 'WR' || user.me.type === 'SUPERADMIN') &&
						<div className='col-xl-3 col-lg-6 col-md-6 align-items-center text-center' style={{ padding: 10 }}>
						<Card stretch className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`} >
							<ReactApexChart
								options={DUMMY_DATA.WR.options}
								series={DUMMY_DATA.WR.series}
								width={'100%'}
								type={DUMMY_DATA.WR.options.chart.type}
								height={DUMMY_DATA.WR.options.chart.height}
							/>
							<div>정상: <span style={{ color: "blue" }}><b>{wrNomal.toLocaleString()}</b></span> / 경고 : <span style={{ color: "red" }}><b>{wrTotal.toLocaleString()}</b></span></div>
							</CardBody>	
						</Card>
						</div>}
					{(user.me.model === 'AR' || user.me.type === 'SUPERADMIN') &&
						<div className='col-xl-3 col-lg-6 col-md-6 align-items-center text-center' style={{ padding: 10 }}>
						<Card stretch className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`} >
							<ReactApexChart
								options={DUMMY_DATA.AR.options}
								series={DUMMY_DATA.AR.series}
								width={'100%'}
								type={DUMMY_DATA.AR.options.chart.type}
								height={DUMMY_DATA.AR.options.chart.height}
							/>
							<div>정상: <span style={{ color: "blue" }}><b>{arNomal.toLocaleString()}</b></span> / 경고 : <span style={{ color: "red" }}><b>{arTotal.toLocaleString()}</b></span></div>
							</CardBody>
						</Card>	
					</div>}
				</div>
			</Page>
	);
};

export default DonutChart;
