import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button, { ButtonGroup } from "../../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useMst } from "../../../../models";
import Input from "../../../../components/bootstrap/forms/Input";
import Icon from "../../../../components/icon/Icon";
import Checks from "../../../../components/bootstrap/forms/Checks";
import DeviceService from "../../../../services/DeviceService";
import moment from "moment";

const NrDeviceModal = observer(({ isOpen, setIsOpen, devices, setIMEI }) => {
    const navigate = useNavigate();
    const { user } = useMst();

    const [checkAll, setCheckAll] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredDevices, setFilteredDevices] = useState(devices.sort((a, b) => a.packets[0]?.Sensing_Time < b.packets[0]?.Sensing_Time ? 1 : -1))

    const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
    const forceUpdate = useCallback(() => updateState({}), []);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
        if (search === '') {
            setFilteredDevices(devices.filter(d => !d.favorite));
            return;
        }
        setFilteredDevices(devices.filter(d => !d.favorite).filter((device) => {
            return device?.IMEI?.includes(search) || device?.SerialNumber?.includes(search) || device?.install?.installName?.includes(search);
        }));
    }, [devices, search]);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop={true}
            isScrollable={true}
            isCentered={true}
            //fullScreen={true}
            fullScreen={'lg'}
            size={'lg'}>
            <ModalHeader className='belt-header d-flex justify-content-between'>
                <div className={'text-light'} style={{ fontSize: '1.5rem' }}>
                    <b>조회 단말기 선택</b>
                </div>
                <Icon
                    type='button'
                    color='light'
                    icon='Close'
                    size='2x'
                    onClick={() => {
                        setIsOpen(false);
                    }}>
                </Icon>
            </ModalHeader>
            <ModalHeader className='border-top d-flex justify-content-between'>
                <Input type='text' style={{ marginLeft: 10 }} placeholder='별칭, IMEI'
                    value={search} onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                 />
                <Icon icon='Search' size='3x' style={{ marginLeft: 10 }} />
            </ModalHeader>
            <ModalBody style={{ height: '50vh', padding: 5, overflowY: 'auto', scrollbarWidth: 'thin' }}>
                {filteredDevices.map((device, index) => (
                    <div key={`device-modal-${index}`} className='d-flex justify-content-left'
                        style={{ border: '1px solid #5d87ff', backgroundColor: '#ecf2ff', color: '#5d87ff', padding: 15,
                            margin: 10, borderRadius: 10, cursor: 'pointer', fontSize: '1.1rem',
                        }}
                        onClick={() => {
                            setIMEI(device.IMEI)
                            setIsOpen(false);
                        }}
                    >
                        <div style={{marginLeft: 10, width: '100%'}}>
                            {/* <span style={{ color:'#294faa'}}><b>{device.install?.installName || ''}</b></span> */}
                            <div className='d-flex justify-content-around w-100'>
                                <span><b>{device.install?.installName || '별칭 없음'}</b></span>
                                <span>IMEI: {device.IMEI}</span>
                                <span>BAT: {parseInt(parseFloat(device.BAT) * 100)}%</span>
                                <span>마지막 수신 데이터: {moment(device.packets[0]?.Sensing_Time).format('YYYY-MM-DD HH:mm:ss')}</span>
                                {/* <span>S/N: {device.SerialNumber}</span> */}
                            </div>
                        </div>
                    </div>
                ))}
            </ModalBody>
            {/* <ModalFooter>
                <Button
                    style={{ width: '100%' }}
                    type='button'
                    color='info'
                    onClick={() => {
                        updateDevices()
                        setIsOpen(false);
                    }}>
                    추가
                </Button>
            </ModalFooter> */}
        </Modal>
    );
});

export default NrDeviceModal;