import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMst } from '../../../models';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import ReactApexChart from 'react-apexcharts';
import DeviceService from '../../../services/DeviceService';
import HeaderTop from '../HeaderTop';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import User from '../../../layout/User/User';
import ArDeviceModal from './ArDeviceModal';
import ArUsage from './ArUsage';
import MapModal from '../MapModal';
import { use } from 'i18next';
import PacketService from '../../../services/PacketService';
import ReactSelect from 'react-select';
import { setDate } from 'date-fns';
import moment from 'moment';
import SubHeader from '../../../layout/SubHeader/SubHeader';
import { get, set } from 'lodash';
import { de, vi } from 'react-date-range/dist/locale';
import { on } from 'process';
import ArErrorModal from './ArErrorModal';
import UserAddModal from './UserAddModal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import UserService from '../../../services/UserService';
import { removeItem } from '../../../lib/localstorage';
import DaumPostcode from 'react-daum-postcode';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import { serachAddress } from '../../../lib/map';
import showNotification from '../../../components/extras/showNotification';
import * as XLSX from 'xlsx';

const datas = [
    { label: '온도', type: 'Temperature_Out', icon: 'Thermometer', value: 25, unit: '℃' },
    { label: '습도', type: 'Humidity_Out', icon: 'Droplet', value: 50, unit: '%' },
    // { label: '내부온도', type: 'Temperature_In', icon: 'Thermometer', value: 25, unit: '℃'},
    // { label: '내부습도', type: 'Humidity_In', icon: 'Water', value: 50, unit: '%'},
    { label: '지온', type: 'Ground_Temperature', icon: 'ThermometerSun', value: 25, unit: '℃'},
    { label: '지습', type: 'Ground_Humidity', icon: 'Moisture', value: 50, unit: '%'},
    { label: 'ph', type: 'pH', icon: 'DropletHalf', value: 7, unit: '.'},
    { label: 'EC', type: 'EC', icon: 'Messenger', value: 1.5, unit: 'dS/m'},
    { label: 'CO2', type: 'CO2', icon: 'Wind', value: 500, unit: 'ppm'},
]

const dateTypes = [
    { value: 'daily', label: '일간' },
    { value: 'monthly', label: '월간' },
    { value: 'yearly', label: '연간' },
]

const errorbit = [
    { label: '전체', value: 'all', color: '#F5BB00' },
    { label: '정상', value: 'normal', color: '#E9897E' },
    { label: '배터리부족', value: 'low_battery', color: '#CF6766' },
    { label: '침수', value: 'flood', color: '#5A5A66' },
    { label: '누수', value: 'leak', color: '#A2B8AD' },
    { label: '장기미사용', value: 'long_term_unused', color: '#FFD700' },
    { label: '동파', value: 'freeze', color: '#CF4500' },
    { label: '고온', value: 'high_temperature', color: '#A8DDCA' },
    { label: '검침기 연결', value: 'meter_connection', color: '#008000' },
    { label: '검침기 통신', value: 'meter_communication', color: '#0000FF' },
    { label: 'Data Limit', value: 'data_limit', color: '#800080' },
    { label: '수질 오염', value: 'water_pollution', color: '#A52A2A' },
    { label: '유량 과부화', value: 'flow_overload', color: '#D2691E' },
    { label: '역류', value: 'backflow', color: '#FF00FF' },
    { label: '옥내 누수', value: 'indoor_leak', color: '#1E90FF' },
    { label: '배터리 부족', value: 'low_battery', color: '#8B0000' },
];

const findCenter = (coordinates) => {
    console.log('coordinates', coordinates)
    if (coordinates.length === 0) {
        return null;
    }

    const total = coordinates.reduce(
        (acc, coord) => {
            acc.lat += coord.lat;
            acc.lng += coord.lng;
            return acc;
        },
        { lat: 0, lng: 0 }
    );

    const numCoords = coordinates.length;

    return {
        lat: total.lat / numCoords,
        lng: total.lng / numCoords,
    };
};

const errorLabel = (error) => {
    if (!error) return '정상';
    const reversedString = error.split('').reverse().join('');

    // 오른쪽에서부터 `1`이 나타나는 인덱스를 찾습니다.
    for (let i = 0; i < reversedString.length; i++) {
        if (reversedString[i] === '1') {
            return errorbit[i+2].label;

        }
    }
}

let mapMarkers = []

const MainPage = observer(() => {
    const { user, company, notifiction } = useMst();
    const navigate = useNavigate();
    const { t } = useTranslation(['translation', 'menu']);

    const [dailySum, setDailySum] = useState(0);
    const [monthlySum, setMonthlySum] = useState(0);
    const [yearlySum, setYearlySum] = useState(0);
    const [rank, setRank] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartSeries, setChartSeries] = useState([]);
    const [deviceChartData, setDeviceChartData] = useState([]);
    const [favoriteDevice, setFavoriteDevices] = useState([]);
    const [devices, setDevices] = useState([]);
    const [errors, setErrors] = useState([]);
    const [status, setStatus] = useState('정상');
    const [params, setParams] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [isUserAddOpen, setIsUserAddOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [viewTab, setViewTab] = useState('MAP2');
    const [viewTab2, setViewTab2] = useState('CHART');
    const [detailViewDevice, setDetailViewDevice] = useState({});
    const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
    const forceUpdate = useCallback(() => updateState({}), []);
    const [selectedIndex, setSelectedIndex] = useState(0);
    
    const [selectedDevice, setSelectedDevice] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [settings, setSettings] = useState({});
    const [isOpenAddress, setIsOpenAddress] = useState(false);

    const [lastData, setLastData] = useState({});
    const [progress, setProgress] = useState(100);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        // 위치 정보를 얻는 함수 (예: 브라우저의 Geolocation API 사용)
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
        });
    }, []);

    
    const generateRandomCoordinates = (center, range, numPoints) => {
        const { lat, lng } = center;
        const coordinates = [];

        for (let i = 0; i < numPoints; i++) {
            const randomLat = lat + (Math.random() - 0.5) * range;
            const randomLng = lng + (Math.random() - 0.5) * range;
            coordinates.push({ lat: randomLat, lng: randomLng });
        }

        return coordinates;
    }

    const getDevice = useCallback(async () => {
        const { data } = await DeviceService.getDashboardDeviceList()
        // console.log('getDevice', data)

        setDevices(data)
        setFavoriteDevices(data.filter(d => d.favorite))
        setTimeout(() => {setViewTab('MAP')}, 100)
    }, [])

    useEffect(() => {
        getDevice()
    }, [getDevice]);

    useEffect(() => {
        console.log('viewTab', viewTab)
        console.log('detailViewDevice', detailViewDevice)

        if (viewTab === 'DETAIL') {
            for (let i = 0; i < datas.length; i++) {
                if (deviceChartData.length > 0 && deviceChartData[deviceChartData.length - 1][datas[i].type] !== null) {
                    setSelectedIndex(i)
                    break;
                }
            }
        } else if (viewTab === 'MAP2') {
            getMaps([detailViewDevice])
        }    

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailViewDevice, viewTab, deviceChartData]);

    const getLastData = useCallback(async (device) => {
        if (device.IMEI) {
            const { data } = await PacketService.getPacketList2({ imei: device.IMEI, perPage: 1 })
            setLastData(data.list[0])
        }
    }, [])

    const getDevicePacket = useCallback(async (device) => {
        if (device.IMEI) {
            params.imei = device.IMEI
            const { data } = await PacketService.getPacketList2(params)
            setDeviceChartData(data.list)
        }
    }, [params])

    useEffect(() => {
        getDevicePacket(detailViewDevice)
    }, [detailViewDevice, getDevicePacket, params]);

    const updateDevices = useCallback((device) => {
        DeviceService.updateDevices({ devices: [device] }).then((res) => {
            getDevice()
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    useEffect(() => {
        if (favoriteDevice.length > 0) {
            getMaps(favoriteDevice)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favoriteDevice]);

    const getAlarm = useCallback(async () => {
        const { data } = await DeviceService.getAlarmList({ IMEI: favoriteDevice.map(d => d.IMEI)})
        setErrors(data.list)
    }, [favoriteDevice])

    useEffect(() => {   
        if (viewTab === 'MAP') {    
            setDetailViewDevice({})
        }
    }, [viewTab])

    useEffect(() => {
        getAlarm()
    }, [getAlarm]);

    useEffect(() => {
        if (search === '') {
            setFavoriteDevices(devices.filter(d => d.favorite));
            return;
        }
        setFavoriteDevices(devices.filter(d => d.favorite).filter((device) => {
            return device?.IMEI?.includes(search) || device?.SerialNumber?.includes(search) || device?.install?.installName?.includes(search);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ search]);

    const getMaps = useCallback((markers) => {
        if (!window.kakao) {
            return;
        }   
        if (markers.length === 0) {
            return;
        }
        console.log('markers', markers)
        kakao.maps.load(async () => {
            console.log('get map');
            let container = document.getElementById('kakaoMap');
            if (!container) return
            let center = findCenter(markers.map((device) => device.install).filter((install) => install?.lat && install?.lng));
        
            console.log('center', center)
            if (!center) {
                center = { lat: 37.2087208128284, lng: 127.097707173097 };
            }

            let options = {
                center: new kakao.maps.LatLng(center.lat, center.lng),
                level: 6,
            };

            const map = new window.kakao.maps.Map(container, options);

            // 위성지도 설정
            map.setMapTypeId(window.kakao.maps.MapTypeId.ROADMAP);

            const greenMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png', // 초록색 마커 이미지 URL
                new window.kakao.maps.Size(24, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );

            const redMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png', // 빨간색 마커 이미지 URL
                new window.kakao.maps.Size(30, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );

            const infowindow = new kakao.maps.InfoWindow({ zIndex: 1 });

            mapMarkers.forEach((marker) => { 
                marker.setMap(null);
            });

            markers.forEach((device, i) => {
                if (device.install?.lat && device.install?.lng) {
                    const marker = new kakao.maps.Marker({
                        title: device.install.installName,
                        position: new kakao.maps.LatLng(device.install.lat, device.install.lng),
                        image: !device.ErrorBit || device.ErrorBit.length === 0 ? greenMarkerImage : redMarkerImage,
                    });
                    kakao.maps.event.addListener(marker, 'click', () => {
                        // infowindow.setContent(`<div style="padding:5px;">${device.install.installName}</div>`);
                        // infowindow.open(map, marker);
                        setDetailViewDevice(device)
                        setViewTab('DETAIL')
                    });
                    marker.setMap(map);
                    mapMarkers.push(marker)
                }
            });

            

            // const circle = new kakao.maps.Circle({
            // 	center: new kakao.maps.LatLng(latitude, longitude), // 원의 중심좌표 입니다
            // 	radius: radius, // 미터 단위의 원의 반지름입니다
            // 	strokeWeight: 1, // 선의 두께입니다
            // 	strokeColor: '#75B8FA', // 선의 색깔입니다
            // 	strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            // 	strokeStyle: 'solid', // 선의 스타일 입니다
            // 	fillColor: '#CFE7FF', // 채우기 색깔입니다
            // 	fillOpacity: 0.7, // 채우기 불투명도 입니다
            // });

            // circle.setMap(map);
        });
    }, []);

    let options = {
        legend: {
            show: true
        },
        series: [{
            name: datas[selectedIndex].label,
            data: deviceChartData.map(d => d[datas[selectedIndex].type])
        }],
        labels: deviceChartData.map(d => moment(d.Sensing_Time).format('MM/DD HH:mm:ss')),
        colors: [
            '#06C',
            '#B8BBBE',
            '#5752D1',
            '#C9190B',
            '#4CB140',
            '#009596',
            '#F4C145',
            '#EC7A08',
            '#7D1007',
            '#002F5D',
            '#23511E',
            '#003737',
            '#2A265F',
            '#C58C00',
            '#8F4700',
            '#2C0000',
            '#6A6E73',
        ],
        chart: {
            type: 'line',
            height: 500,
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true
            },
            offsetX: -20
        },
        dataLabels: {
            enabled: true,
            formatter: (value, { dataPointIndex }) => {
                if (value === Math.max(...deviceChartData.map(d => d[datas[selectedIndex].type])) || value === Math.min(...deviceChartData.map(d => d[datas[selectedIndex].type]))) {
                    return value + ' ' + datas[selectedIndex].unit; 
                } else {
                    return '';
                }
            },
            style: {
                colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7']
            }
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 1,
            dashArray: 0,
        },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shade: 'light',
        //         type: "vertical",
        //         opacityFrom: 0.4,
        //         opacityTo: 1,
        //         colorStops: []
        //     },
        // },
        // stroke: {
        // 	show: true,
        // 	lineCap: 'butt',
        // 	colors: undefined,
        // 	width: 1,
        // 	dashArray: 0,
        // },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shadeIntensity: 1,
        //         // inverseColors: false,
        //         opacityFrom: 0.5,
        //         opacityTo: 0,
        //         stops: [0, 90, 100]
        //     },
        // },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: '',
                offsetX: 32,
                offsetY: -222,
                rotate: 0,
            },
        },
        xaxis: {
            tickAmount: 6,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
            },
            title: {
                text: ''
            },
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val + ' ' + datas[selectedIndex].unit;
                }
            }
        },
        markers: {
            size: 3, // 점의 크기
            colors: ['#E91E63'], // 점의 색상
            strokeColors: '#fff', // 점의 테두리 색상
            strokeWidth: 2, // 점의 테두리 두께
            shape: "circle", // 점의 모양 (circle, square, triangle 등)
            radius: 2, // 점의 반경
        },
    };

    const styles = {
        topCard: {
            flex: 1,
            // border: '1px solid #49beff',
            backgroundColor: '#fff',
            height: '90vh', 
            transition: '.25s all',
        },
    };

    useEffect(() => {
        forceUpdate()
    }, [viewTab, forceUpdate])
    
    useEffect(() => {   
        setUserInfo(user.me)
        console.log(user.me)
    }, [user.me])

    const addAddress = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';
        try {
            if (data.addressType === 'R') {
                if (data.bname !== '') {
                    extraAddress += data.bname;
                }
                if (data.buildingName !== '') {
                    extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
                }
                fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
            }
            userInfo.address = fullAddress;

            const addressRespons = await serachAddress(fullAddress);
            if (addressRespons.documents) {
                //console.log(addressRespons.documents);
                userInfo.lat = parseFloat(addressRespons.documents[0].y);
                userInfo.lng = parseFloat(addressRespons.documents[0].x);
                userInfo.gu = data.sigungu;
                userInfo.dong = data.bname;
            }
            setUserInfo(userInfo);

        } catch (e) {
            //console.log('');
        } finally {
            setIsOpenAddress(false); // 창닫기
        }
    };

    const excelDownload = async () => {
        let fileName = ''
        showNotification(t('엑셀다운'), t('엑셀 다운로드가 시작 되었습니다.'), 'success')
        let tempData = []
        let data = []

        let i = 0;
        let count = 0;
        do {
            params.type = 'DATA'
            params.dateType = 'DAY'
            params.page = Math.floor(tempData.length / 30) + 1
            if (detailViewDevice) {
                params.searchText = detailViewDevice.IMEI
                params.searchType = 'IMEI'
            }

            const response = await PacketService.getPacketList2(params)
            data = response.data?.list?.reverse() || []
            if (count === 0) count = response.data?.totalCount || 0

            if (i === 0) {
                fileName = `${moment(data[0].SensingTime).format('YYYY년 MM월 DD일')}`
            }
            i++
            tempData = tempData.concat(data)
            user.setProgress(Math.floor((tempData.length / count) * 100))
        } while (data.length > 0 && tempData.length < count)

        fileName = `${fileName}~${moment(tempData[tempData.length - 1].SensingTime).format('YYYY년 MM월 DD일')}`
        // console.log(tempData)
        const wb = XLSX.utils.book_new();
        let temp = []
        tempData.map((x) => {
            temp.push({
                '저장시간': moment(x.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                'IMEI': x.install.IMEI,
                'S/N': x.install.SerialNumber,
                'SIG': x.Sig,
                'MODEL': x.Model,
                'Error': errorLabel(x.Error),
                '절전모드': x.Sleep,
                '측정시간': moment(x.Sensing_Time).format('YYYY-MM-DD HH:mm:ss'),
                '온도': x.Temperature_Out,
                '습도': x.Humidity_Out,
                '배터리': x.BAT,
                '지온': x.Ground_Temperature,
                '지습': x.Ground_Humidity,
                'EC': x.EC,
                'pH': x.pH,
                'CO2': x.CO2,
                '통신성공률': x.E_RATE,
                'CID': x.CID,
                'RSSI': x.RSSI,
                'RSRP': x.RSRP,
                'SNR': x.SNR,
                'Version': x.FW_Version,
            })
        })

        const ws = XLSX.utils.json_to_sheet(temp);
        XLSX.utils.book_append_sheet(wb, ws, '데이터');
        XLSX.writeFile(wb, `${fileName}_${moment().format('YYYY년 MM월 DD일')}.xlsx`);
    }

    return (
        <PageWrapper className='fluid' title={t('대시보드')} >
            <SubHeader className={'belt-header'}>
                <div className='d-flex flex-row justify-content-between'>
                    <div style={{ marginTop: 4, marginLeft: 50 }} className='d-flex flex-row justify-content-between pointer' onClick={() => { setViewTab('MAP') }}>
                        <Icon icon='House-Fill' color='light' fontSize={25} forceFamily='bootstrap' />
                        <div style={{paddingLeft: 10, marginTop: -2}}>
                            <span style={{color:'#fff', fontSize: '1.5rem', fontWeight: 800}}>{t(user.me.nickname || user.me.name)}</span>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-end' style={{paddingTop: 2}}>
                        <Icon className="pointer" style={{ marginRight: 30 }} fontSize={28} color='light' isOutline icon={'Person'} forceFamily='bootstrap' onClick={() => { setViewTab('SETTING') }}></Icon>
                        <Icon className="pointer" fontSize={28} color='light' isOutline icon={'Gear'} forceFamily='bootstrap' onClick={() => { setViewTab('SETTING') }}></Icon>
                    </div>
                </div>
            </SubHeader>
            <div className='row' style={{paddingTop: 10}}>
                {/* 사이드 기기 목록 */}
                <div className='col-xl-2 col-lg-2 col-md-2 text-center'>
                    <Card className={`custom-box-shadow rounded-2 belt-header`} style={{ padding: 0, marginLeft: 0, marginRight: 0, height: 87, marginTop: -20 }}>
                        <CardBody className={`rounded-2 align-items-center text-center`} style={{padding: '1rem'}} >
                            <div className='d-flex flex-column justify-content-between' style={{marginTop: 15}}>
                            <InputGroup>
                                <Input 
                                    type='text' 
                                    placeholder='별칭, IMEI, S/N' 
                                    style={{
                                        borderRadius: 10,
                                        borderTopRightRadius: 10,
                                        borderBottomRightRadius: 10,
                                        border: '1px solid #5d87ff',
                                        backgroundColor: '#fff',
                                    }}
                                    value={search} onChange={(e) => {
                                        setSearch(e.target.value);
                                    }} />

                                    <Icon 
                                        style={{
                                            cursor: 'pointer', 
                                            marginLeft: 10, 
                                            marginTop: 5
                                        }}
                                        color='light'
                                        icon='LibraryAdd' 
                                        fontSize={30}
                                        onClick={(e) => {
                                            setIsOpen(true)
                                        }}
                                    />
                            </InputGroup>
                            </div>
                        </CardBody>
                    </Card>
                    {/* <Card className={` custom-box-shadow rounded-2`} style={{padding: 0, marginLeft: 0, marginRight: 0 }}>
                        <CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '1rem' }} > */}
                        <div className='d-flex flex-column justify-content-between'>
                            <div style={{ overflowY: 'auto', height: 'calc(90vh - 85px)', scrollbarWidth: 'none'}}>
                                {favoriteDevice.map((d, i) => (
                                    <Card key={i} className={`custom-box-shadow rounded-2`}
                                        style={{ border: detailViewDevice.IMEI === d.IMEI ? ('2px solid #fa896b') : ('1px solid ' + (d.Error ? '#fa896b' : '#5d87ff')), marginTop: 10, cursor: 'pointer'}}
                                        onClick={(e) => {
                                            console.log('click', d);
                                            setDetailViewDevice(d)
                                            if (viewTab !== 'DATA') setViewTab('DETAIL')
                                        }}
                                    >
                                        <CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '1rem', backgroundColor: d.Error ? '#fdede8' : '#fff', color: '#000' }}>
                                            <div className='d-flex flex-row justify-content-between'>
                                                <div><CardTitle style={{ color: '#294faa', fontSize: '1.5rem', marginTop: 0 }}>{d?.install?.installName || '별칭없음'}</CardTitle></div>
                                                <div>
                                                    <Icon icon='Gear-Fill' fontSize={20} forceFamily='bootstrap' 
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            e.preventDefault()
                                                            e.nativeEvent.stopPropagation()
                                                            e.nativeEvent.preventDefault()
                                                            setSelectedDevice(d)
                                                            setIsUserAddOpen(true)
                                                        }}
                                                     />
                                                    <Icon icon='Close' fontSize={25} onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        e.nativeEvent.stopPropagation()
                                                        e.nativeEvent.preventDefault()
                                                        d.favorite = false
                                                        updateDevices(d)
                                                    }} style={{cursor:'pointer'}} />
                                                </div>
                                            </div>
                                            <div className='w-100 text-left' style={{ marginTop: 0 }}>
                                                <div className='d-flex flex-row justify-content-between'>
                                                    <div style={{ fontSize: '1.0rem' }}>IMEI: {d?.IMEI}</div>
                                                    <div style={{  fontSize: '1.0rem' }}>버전: {d?.FW_Version}</div>
                                                </div>
                                                <div className='d-flex flex-row justify-content-between'>
                                                    <div style={{  fontSize: '1.0rem' }}>S/N: {d?.SerialNumber}</div>
                                                    <div style={{ fontSize: '1.0rem' }}>Model: {d?.Model}</div>
                                                </div>
                                                <hr />
                                                {(d.packets.length > 0 && (d.packets[0]?.Temperature_Out !== null || d.packets[0]?.Humidity_Out !== null)) &&
                                                <div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
                                                    {(d.packets.length > 0 && (d.packets[0]?.Temperature_Out !== null)) && <div style={{ width: '100%' }}> 온도: <span style={{ color: "blue" }}><b>{d.packets[0]?.Temperature_Out}</b></span> ℃</div>}
                                                        {(d.packets.length > 0 && (d.packets[0]?.Humidity_Out !== null)) && <div style={{ width: '100%' }}> 습도: <span style={{ color: "blue" }}><b>{d.packets[0]?.Humidity_Out}</b></span>  %</div>}
                                                </div>
                                                }
                                                {(d.packets.length > 0 && (d.packets[0]?.Ground_Temperature !== null || d.packets[0]?.Ground_Humidity !== null)) &&
                                                <div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
                                                        {(d.packets.length > 0 && d.packets[0]?.Ground_Temperature !== null) && <div style={{ width: '100%' }}>지온: <span style={{ color: "blue" }}><b>{d.packets[0]?.Ground_Temperature}</b></span> ℃</div>}
                                                        {(d.packets.length > 0 && d.packets[0]?.Ground_Humidity !== null) && <div style={{ width: '100%' }}>지습: <span style={{ color: "blue" }}><b>{d.packets[0]?.Ground_Humidity}</b></span> %</div>}
                                                </div>}
                                                {(d.packets.length > 0 && (d.packets[0]?.pH !== null || d.packets[0]?.EC !== null)) &&
                                                <div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
                                                    {(d.packets.length > 0 && d.packets[0]?.pH != null) && <div style={{ width: '100%' }}>ph: <span style={{ color: "blue" }}><b>{d.packets[0]?.pH}</b></span></div>}
                                                    {(d.packets.length > 0 && d.packets[0]?.EC != null) && <div style={{ width: '100%' }}>EC: <span style={{ color: "blue" }}><b>{d.packets[0]?.EC}</b></span> dS/m</div>}
                                                </div>}
                                                {(d.packets.length > 0 && (d.packets[0]?.BAT !== null || d.packets[0]?.CO2 !== null)) &&
                                                <div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
                                                        {(d.packets.length > 0 && d.packets[0]?.BAT !== null) && <div style={{ width: '100%' }}>배터리: <span style={{ color: "blue" }}><b>{d.packets[0]?.BAT}</b></span> %</div>}
                                                    {(d.packets.length > 0 && d.packets[0]?.CO2 != null) && <div style={{ width: '100%' }}>CO2: <span style={{ color: "blue" }}><b>{d.packets[0]?.CO2}</b></span> ppm</div>}
                                                </div>}
                                                {d.Error && d.ErrorBit.length > 0 && <div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
                                                    <div style={{ width: '100%' }}>이상감지: <span style={{ color: "red" }}><b>{errorLabel(d.Error)}</b></span></div>
                                                </div>}
                                            </div>
                                            {/* <div className='w-100 text-left' style={{ marginTop: 0 }}>
                                                <div>IMEI: {d?.IMEI}</div>
                                                <div>S/N: {d?.SerialNumber}</div>
                                                <div>온도: {deviceChartData.length > 0 ? deviceChartData[0]?.Temperature_In : ''}℃ 습도: {deviceChartData.length > 0  ? deviceChartData[0]?.Humidity_In : ''}%</div>
                                                <div>지온: {deviceChartData.length > 0  ? deviceChartData[0]?.Ground_Temperature : ''}℃ 지습: {deviceChartData.length > 0  ? deviceChartData[0]?.Ground_Humidity : ''}%</div>
                                                <div>ph: {deviceChartData.length > 0  ? deviceChartData[0]?.pH : ''} EC: {deviceChartData.length > 0  ? deviceChartData[0]?.EC : ''}dS/m CO2: {deviceChartData.length > 0  ? deviceChartData[0]?.CO2 : ''}ppm</div>
                                            </div> */}
                                        </CardBody>
                                    </Card>
                                ))}
                            </div>
                            </div>
                        {/* </CardBody>
                    </Card> */}
                </div>
                {/* Body */}
                <div className='col-xl-10 col-lg-10 col-md-10 text-center'>
                    {((viewTab === 'DETAIL' || viewTab === 'DATA' || viewTab === 'MAP2') && detailViewDevice?.IMEI) && (
                        <div>
                            <Card className={`custom-box-shadow rounded-2`} style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
                                <CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} style={{ padding: 0 }}>
                                    <CardTitle style={{ fontSize: '1.5rem', marginLeft: 20, marginTop: 6 }}>{viewTab === 'MAP2' ? '단말기 위치 데이터' : '단말기 실시간 데이터'}</CardTitle>
                                    <Card className={`custom-box-shadow rounded-2`} style={{ border: '1px solid blue', height: 87, marginTop: -20}}>
                                        <CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} style={{ paddingTop: 25, fontSize: '1.1rem', backgroundColor: '#3e66c4', color: '#fff', paddingLeft: 10 }}>
                                            <Icon className="pointer" style={{ marginRight: 10 }} fontSize={28} color={(detailViewDevice?.install?.lat && detailViewDevice?.install.lng) ? 'light' : 'danger'} isOutline icon={'TravelExplore'}
                                             onClick={() => {
                                                 if ((detailViewDevice?.install?.lat && detailViewDevice?.install.lng)) {
                                                    setViewTab('MAP2')
                                                    getMaps([detailViewDevice]); 
                                                 } else {
                                                     showNotification('위치정보', '위치정보가 없습니다. 주소를 입력해주세요.', 'danger')
                                                     setSelectedDevice(detailViewDevice)
                                                     setIsUserAddOpen(true)
                                                 }
                                            }}></Icon>
                                            <div style={{ marginRight: 8, textAlign: 'left' }}>
                                                <div className='d-flex flex-row justify-content-between'>
                                                    <div>IMEI: <span style={{ color: "white" }}><b>{detailViewDevice.IMEI}</b></span></div>
                                                    <div>&nbsp;</div>
                                                    <div>S/N: <span style={{ color: "white" }}><b>{detailViewDevice.SerialNumber}</b></span></div>
                                                </div>
                                                <div>마지막 수신 데이터: <span style={{ color: "white" }}><b>{detailViewDevice.updatedAt}</b></span></div>
                                            </div>
                                            <Button color='light'
                                                icon={viewTab === 'DETAIL' ? 'Description' : 'BarChart'}
                                                size='lg'
                                                style={{ marginTop: 5 }}
                                                onClick={() => {
                                                    setViewTab(viewTab === 'DETAIL' ? 'DATA' : 'DETAIL')
                                                }}>
                                                {''}
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </CardBody>
                            </Card>
                        </div>
                    )}
                    {(viewTab === 'MAP' || (viewTab === 'DATA' && !detailViewDevice?.IMEI)) && (
                        <Card className={`custom-box-shadow rounded-2`} style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
                            <CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} style={{ padding: '0.93rem' }}>
                                <Button color='info' size='lg'>전체 단말기 수 {devices.length}</Button>
                                <div>
                                    <Button color='light' icon={viewTab === "DATA" ? "House-Fill" : "Description"} onClick={() => setViewTab(viewTab === 'DATA' ? 'MAP' : 'DATA')}>{viewTab === 'DATA' ?  '홈' : '수집 데이터'}</Button>
                                </div>
                                <Button color='danger' size='lg'>이상 단말기 수 {devices.filter(f => f.Error).length}</Button>
                            </CardBody>
                        </Card>
                    )}
                    {(viewTab === 'DATA' && !detailViewDevice?.IMEI) && (
                        <Card className={`custom-box-shadow rounded-2`} style={{ height: viewTab === 'DATA' ? 'calc(90vh - 85px)' : 'calc(90vh - 280px)', padding: 0, marginLeft: 0, marginRight: 0 }}>
                            <CardBody className={`rounded-2 align-items-center text-center d-flex flex-row`} style={{ padding: '1rem' }}>
                                <div className='d-flex flex-column' style={{ flex: 1, padding: 10, marginTop: -20, height: viewTab === 'DATA' ? '100%' : '100%' }}>
                                    <HeaderTop setParams={setParams} progress={progress} excelDownload={excelDownload}
                                        disableExcelUp disableMap
                                        startDate={moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')} endDate={moment().format('YYYY-MM-DD HH:mm:ss')}
                                        type={'SR'} totalText={`전체 데이터: `} disableSecond={detailViewDevice} />

                                        <ArUsage detailViewDevice={detailViewDevice} params={params} style={{ marginTop: -10 }} />
                                </div>
                            </CardBody>
                        </Card>
                    )}
                    {((viewTab === 'DETAIL' || viewTab === 'DATA') && detailViewDevice?.IMEI) && (
                        <div className='col-xl-12 col-lg-12 col-md-12 text-center'>
                            {viewTab === 'DETAIL' && (
                                <Card className={`custom-box-shadow rounded-2`} style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
                                    <CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-around`} style={{ padding: '0.93rem', backgroundColor: '#ffffff' }}>
                                        {datas.map((data, i) => (
                                            <div key={i} className='d-flex' onClick={() => {
                                                if (deviceChartData[deviceChartData.length - 1] && deviceChartData[deviceChartData.length - 1][data.type] !== null) setSelectedIndex(i)
                                            }} style={{ cursor: deviceChartData[deviceChartData.length - 1] && deviceChartData[deviceChartData.length - 1][data.type] !== null ? 'pointer' : 'not-allowed' }}>
                                                <Card stretch className={`custom-box-shadow rounded-2 d-flex`} style={{opacity: selectedIndex === i ? 1 : 0.5,
                                                    width: 100, textAlign: 'center', border: deviceChartData[deviceChartData.length - 1] && deviceChartData[deviceChartData.length - 1][data.type] !== null ? (selectedIndex === i ? '2px solid #3e66c4' : '1px solid #aaaaaa') : '1px solid #aaaaaa', }}>
                                                    <CardBody className={`rounded-2 align-items-center text-center`} 
                                                        style={{
                                                            backgroundColor: deviceChartData[deviceChartData.length - 1] && deviceChartData[deviceChartData.length - 1][data.type] !== null ? (selectedIndex === i ? '#e8f7ff' : '#ffffff') : '#efefef', 
                                                            color: deviceChartData[deviceChartData.length - 1] && deviceChartData[deviceChartData.length - 1][data.type] !== null ? (selectedIndex === i ? '#3e66c4' : '#666') : '#aaaaaa'}}>
                                                        <Icon icon={data.icon} fontSize={30} forceFamily='bootstrap' />
                                                        <CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{data.label}</CardTitle>
                                                        <CardSubTitle className='mt-3' style={{ fontSize: '1.0rem', marginTop: 20 }}>{deviceChartData.length > 0 && (deviceChartData[deviceChartData.length - 1][data.type])}{data.unit}</CardSubTitle>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}
                                    </CardBody>
                                </Card>
                            )}
                            <Card className={`custom-box-shadow rounded-2`} style={{ height: viewTab === 'DATA' ?  'calc(90vh - 85px)' : 'calc(90vh - 280px)', padding: 0, marginLeft: 0, marginRight: 0 }}>
                                <CardBody className={`rounded-2 align-items-center text-center d-flex flex-row`} style={{ padding: '1rem' }}>
                                    <div className='d-flex flex-column' style={{ flex: 1, padding: 10, marginTop: -20, height: viewTab === 'DATA' ? '100%' : '100%'}}>
                                        <HeaderTop setParams={setParams} progress={progress} excelDownload={excelDownload} 
                                        disableExcelUp disableMap
                                        startDate={moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')} endDate={moment().format('YYYY-MM-DD HH:mm:ss')}
                                        type={'SR'} totalText={`전체 데이터: `} disableSecond={detailViewDevice} />

                                {viewTab === 'DATA' ? (
                                        <ArUsage detailViewDevice={detailViewDevice} params={params} style={{marginTop: -10}}  />
                                ) : (
                                    <ReactApexChart
                                        style={{ paddingLeft: 20, flex: 1}}
                                        width={'100%'}
                                        height={'95%'}
                                        options={options}
                                        series={options.series}
                                        type={'line'}
                                    />)}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    )}
                    {(
                        <div style={{ display: viewTab === 'MAP' || viewTab === 'MAP2' ? '' : 'none'}}>
                            <div className='row col-xl-12 col-lg-12 col-md-12 text-center'>
                                <div className={viewTab === 'MAP2' ? '' : 'col-xl-6 col-lg-6 col-md-6 text-center'}>
                                    <Card className={`custom-box-shadow rounded-2`} style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
                                        <CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '0.5rem' }}>
                                            <div
                                                className='position-relative border w-100 d-inline-block bg-l25-light border'
                                                style={{
                                                    width: '100%',
                                                }}>
                                                <div id='kakaoMap' className='kakao-map' style={{ width: '100%', height: viewTab === 'MAP' ? 'calc(90vh - 272px)' : 'calc(90vh - 102px)' }}></div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {viewTab === 'MAP' && (
                                    <Card className={`custom-box-shadow rounded-2`} style={{ padding: 0, marginLeft: 0, marginRight: 0 }}>
                                        <CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '0.93rem' }}>
                                            <div className='d-flex flex-row justify-content-between'>
                                                <div style={{ marginTop: 5 }}>
                                                    <h5><b>이상 알림 내역</b></h5>
                                                </div>
                                                <Button color='info' onClick={() => { setIsErrorOpen(true) }}>전체 내역</Button>
                                            </div>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: 90 }}>
                                                {errors.map((e, i) => (i < 4 &&
                                                    <div key={i} className='d-flex flex-row justify-content-between' style={{ marginTop: 5 }}>
                                                        <div><b>{
                                                            moment(e.Sensing_Time).format('YYYY-MM-DD HH:mm') + ' '}</b><span style={{ color: "blue" }}>{(e?.install?.installName || 'S/N:' + e.SerialNumber)}</span>
                                                            {' 단말기에서 '}<span style={{ color: "red" }}><b>{errorLabel(e?.Error)}</b></span>
                                                            {'이(가) 감지되었습니다.'
                                                            }</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </CardBody>
                                    </Card>
                                    )}
                                </div>
                                {viewTab === 'MAP' && (
                                    <div className='col-xl-6 col-lg-6 col-md-6 text-center' style={{ display: (viewTab !== 'MAP') ? 'none' : '' }}>
                                        <Card className={`custom-box-shadow rounded-2`} style={{ height: 'calc(90vh - 85px)', padding: 0, marginLeft: 0, marginRight: 0 }} >
                                            <CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '0.5rem' }}>
                                                <iframe id='weatherIframe' src={`https://www.weather.go.kr/w/iframe/dfs.do?lat=${location?.latitude || 0}&lon=${location?.longitude || 0}`} width={'100%'} height={'93%'} title="KMA Weather"></iframe>
                                                <Button color='info' isOutline onClick={() => { }}>출처 : 기상청 날씨누리</Button>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {(viewTab === 'SETTING') &&
                        <Page className='d-flex flex-row justify-content-between' style={{marginTop: 20}}>
                                <div className='d-flex flex-column justify-content-center'>
                                    <Card className={`custom-box-shadow rounded-2`} style={styles.topCard}>
                                        <CardBody className={`rounded-2 align-items-center text-left`} >
                                            <CardTitle style={{ marginTop: 10 }}>사용자 정보</CardTitle>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('이름')}>
                                                            <InputGroup>
                                                                <Input
                                                                    id={'name'}
                                                                    type={'text'}
                                                                    placeholder={t('이름을 입력해주세요')}
                                                                    value={userInfo.name}
                                                                    onChange={(e) => {
                                                                        setUserInfo({ ...userInfo, name: e.target.value })
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('아이디')}>
                                                            <Input
                                                                id={'userId'}
                                                                type={'text'}
                                                                placeholder={t('아이디를 입력해주세요')}
                                                                value={userInfo.userId}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, userId: e.target.value })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('휴대폰 번호')}>
                                                            <Input
                                                                id={'phone'}
                                                                type={'text'}
                                                                placeholder={t('휴대폰 번호를 입력해주세요')}
                                                                value={userInfo.phone}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, phone: e.target.value })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('주소')}>
                                                            <InputGroup>
                                                                <Input
                                                                    style={{ backgroundColor: '#ececec' }}
                                                                    disabled={true}
                                                                    id={'address'}
                                                                    type={'text'}
                                                                    placeholder={t('주소를 입력해주세요')}
                                                                    value={userInfo.address}
                                                                    onChange={() => { }}
                                                                    onClick={() => setIsOpenAddress(true)}
                                                                />
                                                                <Button type={'button'} color={'info'} onClick={() => setIsOpenAddress(true)}>{t('주소검색')}</Button>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('상세주소')}>
                                                            <Input
                                                                id={'detailAddress'}
                                                                type={'text'}
                                                                placeholder={t('상세주소를 입력해주세요')}
                                                                value={userInfo.detailAddress}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, detailAddress: e.target.value })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('이메일')}>
                                                            <Input
                                                                id={'email'}
                                                                type={'text'}
                                                                placeholder={t('이메일를 입력해주세요')}
                                                                value={userInfo.email}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, email: e.target.value })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className={`custom-box-shadow rounded-2`} style={styles.topCard}>
                                        <CardBody className={`rounded-2 align-items-center text-left`} >
                                            <CardTitle style={{ marginTop: 10 }}>비밀번호 수정</CardTitle>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('비밀번호')}>
                                                            <Input
                                                                id={'password'}
                                                                type={'password'}
                                                                value={userInfo.password}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, password: e.target.value })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-sm-10' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('비밀번호 확인')}>
                                                            <Input
                                                                id={'confirmPassword'}
                                                                type={'password'}
                                                                value={userInfo.confirmPassword}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, confirmPassword: e.target.value })
                                                                }}
                                                            />

                                                            {(userInfo.password && userInfo.password !== userInfo.confirmPassword) && <span style={{ color: 'red' }}>일치하지 않습니다.</span>}
                                                            {(userInfo.password && userInfo.password === userInfo.confirmPassword) && <span style={{ color: 'blue' }}>일치합니다.</span>}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className='row'>
                                        <div style={{ marginTop: 10 }}>
                                            <Button type={'button'} style={{ width: 200 }} color={'info'} onClick={() => {
                                                UserService.modify(userInfo).then((res) => {
                                                    console.log('updateUser', res);
                                                    showNotification({ title: '사용자 정보 수정', message: '사용자 정보가 수정되었습니다.', icon: 'success', color: 'success' });
                                                });
                                            }}>{t('저장')}</Button>
                                        </div>
                                        <div style={{ marginTop: 20, cursor: 'pointer' }}>
                                            <div
                                                onClick={() => {
                                                    UserService.logout().then(async () => {
                                                        await Promise.all([
                                                            removeItem('access-token'),
                                                            removeItem('refresh-token'),
                                                            removeItem('rootState'),
                                                            removeItem('webToken'),
                                                            removeItem('authUser'),
                                                            user.logout(),
                                                        ]).then(() => {
                                                            navigate(`/login`);
                                                        });
                                                    });
                                                }}
                                            ><u>{t('로그아웃')}</u></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-column justify-content-center'>
                                    <Card className={`custom-box-shadow rounded-2`} style={styles.topCard}>
                                        <CardBody className={`rounded-2 align-items-center text-left`} >
                                            <CardTitle style={{ marginTop: 10 }}>알람 기준 값</CardTitle>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='col-sm-6' style={{ marginTop: 20, height: 243 }}>
                                                        <FormGroup label={t('온도')}>
                                                            <InputGroup>
                                                                <InputGroupText>최고</InputGroupText>
                                                                <Input
                                                                    id={'maxTemperature'}
                                                                    type={'text'}
                                                                    value={settings.maxTemperature}
                                                                    onChange={() => {
                                                                        setSettings({ ...settings, maxTemperature: e.target.value })
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <InputGroupText>최저</InputGroupText>
                                                                <Input
                                                                    id={'minTemperature'}
                                                                    type={'text'}
                                                                    value={settings.minTemperature}
                                                                    onChange={() => {
                                                                        setSettings({ ...settings, minTemperature: e.target.value })
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <div className='col-sm-6' style={{ marginTop: 20 }}>
                                                        <FormGroup label={t('습도')}>
                                                            <InputGroup>
                                                                <InputGroupText>최고</InputGroupText>
                                                                <Input
                                                                    id={'maxHumidity'}
                                                                    type={'text'}
                                                                    value={settings.maxHumidity}
                                                                    onChange={() => {
                                                                        setSettings({ ...settings, maxHumidity: e.target.value })
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <InputGroupText>최저</InputGroupText>
                                                                <Input
                                                                    id={'minHumidity'}
                                                                    type={'text'}
                                                                    value={settings.minHumidity}
                                                                    onChange={() => {
                                                                        setSettings({ ...settings, minHumidity: e.target.value })
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className={`custom-box-shadow rounded-2`} style={styles.topCard}>
                                        <CardBody className={`rounded-2 align-items-center text-left`} >
                                            <CardTitle style={{ marginTop: 10 }}>알림 설정</CardTitle>
                                            <div className='row'>
                                                <div className='col-md-8' style={{ marginTop: 20 }}>
                                                    <ChecksGroup className="d-flex flex-row justify-content-between">
                                                        <Checks label={'이메일'} checked={settings.email} onChange={() => {
                                                            setSettings({ ...settings, email: !settings.email })
                                                        }} />
                                                        <Checks label={'SMS'} checked={settings.sms} onChange={() => {
                                                            setSettings({ ...settings, sms: !settings.sms })
                                                        }} />
                                                        <Checks label={'카카오톡'} checked={settings.kakaotalk} onChange={() => {
                                                            setSettings({ ...settings, kakaotalk: !settings.kakaotalk })
                                                        }} />
                                                        <Checks label={'텔레그램'} checked={settings.telegram} onChange={() => {
                                                            setSettings({ ...settings, telegram: !settings.telegram })
                                                        }} />
                                                    </ChecksGroup>
                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                    <div className='row'>
                                        <div style={{ marginTop: 10 }}>
                                            <Button type={'button'} style={{ width: 200 }} color={'info'} onClick={() => { }}>{t('설정 저장')}</Button>
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            <div>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                        </Page>}
                </div>
            </div>
            <ArDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={devices} status={status} getDevice={getDevice} />
            <ArErrorModal isOpen={isErrorOpen} setIsOpen={setIsErrorOpen} devices={errors} status={status} getDevice={getDevice} />
            <UserAddModal isModalOpen={isUserAddOpen} setIsModalOpen={setIsUserAddOpen} selectedUser={selectedDevice} />    
            <Modal isOpen={isOpenAddress} fullScreen='xxl' size='xl' setIsOpen={setIsOpenAddress} isStaticBackdrop={true} isCentered={true}>
                <ModalHeader setIsOpen={setIsOpenAddress}></ModalHeader>
                <ModalBody>
                    <DaumPostcode onComplete={addAddress} style={{ height: '50vh' }} />
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
        </PageWrapper>
    );
});

export default MainPage;