import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import CollectedDataList from './ArCollectedDataList';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import useFilter from '../../../hooks/useFilter';
import { object } from 'prop-types';
import Popovers from '../../../components/bootstrap/Popovers';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { set } from 'lodash';

let isLoaded = [];
let prevPage = -1
const ArCollectedData = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [params, setParams] = useState({});
	const [loading, setLoading] = useState(true);
	const [filterData, setFilterData] = useState([]);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 100,
				fixedHeight: false,
			}),
		[]
	);

	const columns = useMemo(
		() => [
			// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
			{ grow: 1, minWidth: 200, name: '저장일자', key1: 'createdAt' },
			{ grow: 1, minWidth: 200, name: '수용가 명', key1: 'customerName' },
			{ grow: 1, minWidth: 200, name: '수용가 번호', key1: 'acceptNumber' },
			{ grow: 1, minWidth: 200, name: '주소', key1: 'customerAddress' },
			{ grow: 1, minWidth: 30, name: '계량기 번호', key1: 'IDENT_NR' },
			{ grow: 1, minWidth: 30, name: '계량기 구경(mm)', key1: 'Diameter' },
			{ grow: 1, minWidth: 30, name: '보고 주기', key1: 'AR_term' },
			{ grow: 1, minWidth: 30, name: '검침 주기', key1: 'SR_term' },
			{ grow: 1, minWidth: 30, name: '검침 시간', key1: 'Sensing_Time' },
			{ grow: 1, minWidth: 30, name: '검침값(m³)', key1: 'Water_Meter' },
			{ grow: 1, minWidth: 30, name: '검침값 이력(m³)', key1: 'Water_Meter_ALL' },
			{ grow: 1, minWidth: 30, name: '온도', key1: 'Temperature_In' },
			{ grow: 1, minWidth: 30, name: '습도', key1: 'Humidity_In' },
			{ grow: 1, minWidth: 30, name: '통신성공률', key1: 'E_RATE' },
			{ grow: 1, minWidth: 30, name: '기기 상태', key1: 'Error' },
			{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
			{ grow: 1, minWidth: 30, name: '탁도(NTU)', key1: 'NTU' },
			{ grow: 1, minWidth: 200, name: 'IMEI', key1: 'IMEI' },
			{ grow: 1, minWidth: 30, name: 'SERIAL', key1: 'SerialNumber' },
			{ grow: 1, minWidth: 30, name: 'RSSI', key1: 'RSSI' },
			{ grow: 1, minWidth: 30, name: 'RSRP', key1: 'RSRP' },
			{ grow: 1, minWidth: 30, name: 'RSRQ', key1: 'RSRQ' },
			{ grow: 1, minWidth: 30, name: 'SNR', key1: 'SNR' },
			{ grow: 2, minWidth: 30, name: '펌웨어 버전', key1: 'FW_Version' },
		],
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									if (['USER', 'GROUP'].indexOf(user.me.type) < 0) {
										setDetailData(rowData)
										setArrayModalOpen(true)
									}
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter_ALL':
					data = rowData?.Water_Meter ?
						(
							<Badge
								color='success'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{rowData.Water_Meter}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'MIC':
					data = columnData ?
						(
							<Badge
								color='success'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.install?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.install?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.install?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData || 'N/A';
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								// setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
		console.log(loading, e.scrollTop, e.clientHeight, e.scrollHeight, totalCount, filterData.length, isLoaded)
		if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
			if (isLoaded.indexOf(filterData.length) < 0) {
				console.log('getData')
				isLoaded.push(filterData.length);
				getData();
			}
		} else {
			// const keysToCheck = ['id', 'name']; // 체크할 키들
			// const isDuplicate = checkDuplicate(originData, keysToCheck);
			// console.log(isDuplicate); // true
		}

		setScrollLeft(e.scrollLeft)
	};



	const getData = useCallback(async (load) => {
		setLoading(true);
		params.type = 'DATA'
		params.page = Math.floor(filterData.length / 30) + 1
		if (load === 'init') {
			setFilterData([])
			isLoaded = [];
			params.page = 1
			prevPage = -1
		}
		console.log(params, filterData, prevPage)	
		if (!params.dateType || prevPage === params.page) {
			setLoading(false);
			return
		}
		prevPage = params.page

		const { data } = await PacketService.getPacketList2(params)
		console.log('data.list.length', data.list.length)
		if (load === 'init') {
			setFilterData(data.list.reverse())
		} else {
			setFilterData((prev) => [...prev, ...data.list.reverse()])
		}
		setTotalCount(data.totalCount)
		setFilterCount(data.filterCount)

		setLoading(false);
	}, [params, filterData])

	useEffect(() => {
		getData('init');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const excelDownload = async () => {
		let tempData = []
		let data = []

		let count = 0;
		do {
			params.type = 'DATA'
			params.page = Math.floor(tempData.length / 30) + 1
			const response = await PacketService.getPacketList2({...params, excel: true})
			data = response.data?.list?.reverse() || []
			if (count === 0) count = response.data?.totalCount || 0

			tempData = tempData.concat(data)
			user.setProgress(Math.floor((tempData.length / count) * 100))
		} while (data.length > 0 && tempData.length < count) 
		
		const wb = XLSX.utils.book_new();

		let temp = []
		let dayFormat = 'YYYY-MM-DD'
		let monthFormat = 'YYYY-MM'
		let yearFormat = 'YYYY'

		let day = {}
		let month = {}
		let year = {}

		tempData.map((x) => {
			let dayKey = moment(x.Sensing_Time).format(dayFormat)
			let monthKey = moment(x.Sensing_Time).format(monthFormat)
			let yearKey = moment(x.Sensing_Time).format(yearFormat)

			if (!day[dayKey + '_' + x.install?.acceptNumber]) {
				day[dayKey + '_' + x.install?.acceptNumber] = {
					'일자': dayKey,
					'수용가명': x.install?.customerName || '',
					'수용가번호': x.install?.acceptNumber || '',
					'계량기번호': x?.IDENT_NR || '',
					'총 사용량': x?.Water_Meter[0] || '',
					'통신성공률': x?.E_RATE || '',
					'IMEI': x?.IMEI || '',
					'SerialNumber': x?.SerialNumber || '',
					'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
					'계량기구경': x?.Diameter || '',
					'보고주기': x?.AR_term || '',
					'검침주기': x?.SR_term || '',
					'온도': x?.Temperature_In || '',
					'습도': x?.Humidity_In || '',
					'기기상태': x?.Status || '',
					'배터리': x?.BAT || '',
					'탁도': x?.NTU || '',
				}
			}

			if (!month[monthKey + '_' + x.install?.acceptNumber]) {
				month[monthKey + '_' + x.install?.acceptNumber] = {
					'연도': monthKey.split('-')[0],
					'월': monthKey.split('-')[1],
					'수용가명': x.install?.customerName || '',
					'수용가번호': x.install?.acceptNumber || '',
					'계량기번호': x?.IDENT_NR || '',
					'총 사용량': x?.Water_Meter[0] || '',
					'통신성공률': x?.E_RATE || '',
					'IMEI': x?.IMEI || '',
					'SerialNumber': x?.SerialNumber || '',
					'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
					'계량기구경': x?.Diameter || '',
					'보고주기': x?.AR_term || '',
					'검침주기': x?.SR_term || '',
					'온도': x?.Temperature_In || '',
					'습도': x?.Humidity_In || '',
					'기기상태': x?.Status || '',
					'배터리': x?.BAT || '',
					'탁도': x?.NTU || '',
				}
			}

			if (!year[yearKey + '_' + x.install?.acceptNumber]) {
				year[yearKey + '_' + x.install?.acceptNumber] = {
					'연도': yearKey,
					'수용가명': x.install?.customerName || '',
					'수용가번호': x.install?.acceptNumber || '',
					'계량기번호': x?.IDENT_NR || '',
					'총 사용량': x?.Water_Meter[0] || '',
					'통신성공률': x?.E_RATE || '',
					'IMEI': x?.IMEI || '',
					'SerialNumber': x?.SerialNumber || '',
					'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
					'계량기구경': x?.Diameter || '',
					'보고주기': x?.AR_term || '',
					'검침주기': x?.SR_term || '',
					'온도': x?.Temperature_In || '',
					'습도': x?.Humidity_In || '',
					'기기상태': x?.Status || '',
					'배터리': x?.BAT || '',
					'탁도': x?.NTU || '',
				}
			}

			day[dayKey + '_' + x.install?.acceptNumber]['총 사용량'] += x.Water_Meter[0]
			month[monthKey + '_' + x.install?.acceptNumber]['총 사용량'] += x.Water_Meter[0]
			year[yearKey + '_' + x.install?.acceptNumber]['총 사용량'] += x.Water_Meter[0]

			temp.push({
				'일자': moment(x.Sensing_Time).format('YYYY-MM-DD HH:mm:ss'),
				'수용가명': x.install?.customerName || '',
				'수용가번호': x.install?.acceptNumber || '',
				'계량기번호': x?.IDENT_NR || '',
				'총 사용량': x?.Water_Meter[0] || '',
				'통신성공률': x?.E_RATE || '',
				'IMEI': x?.IMEI || '',
				'SerialNumber': x?.SerialNumber || '',
				'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
				'계량기구경': x?.Diameter || '',
				'보고주기': x?.AR_term || '',
				'검침주기': x?.SR_term || '',
				'온도': x?.Temperature_In || '',
				'습도': x?.Humidity_In || '',
				'기기상태': x?.Status || '',
				'배터리': x?.BAT || '',
				'탁도': x?.NTU || '',
			})
		})

		const ws = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws, '일자별');

		temp = []
		Object.keys(month).map((key) => {
			temp.push(month[key])
		})

		const ws2 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws2, '월별');

		temp = []
		Object.keys(year).map((key) => {
			temp.push(year[key])
		})

		const ws3 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws3, '연도별');

		XLSX.writeFile(wb, `데이터수집 ${moment(params.startDate).format('YYYY년 MM월 DD일')}~${moment(params.endDate).format('YYYY년 MM월 DD일')}_${moment().format('YYYY년 MM월 DD일')}.xlsx`);
	}

	return (
		<PageWrapper className='fluid' title={t('수집 데이터')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={'/arcollectedData'}>
							{t('수집 데이터')}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid' style={{ paddingTop: '0.7rem' }} >
				<Card stretch className={`custom-box-shadow rounded-2 p-0`} >
					<CardBody className={`rounded-2 align-items-center text-center p-3 py-1`} >
						<HeaderTop setParams={setParams} disableMap={true} totalText={`전체 데이터: ${filterCount}/${totalCount}`}
							startDate={moment().subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD')}
							endDate={moment().format('YYYY-MM-DD')}
						disableExcelUp excelDownload={excelDownload} />
					</CardBody>
				</Card>
				<div className='row'>
					<Card className={`custom-box-shadow rounded-2`} style={{ height: 'calc(90vh - 120px)' }}>
						<CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: 0 }} >
							{filterData?.length > 0 && (
								<AutoSizer
									onResize={() => {
										listRef.current.recomputeGridSize();
									}}>
									{({ width, height }) => {
										// console.log(width, height);
										return (
											<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width}>
												{({ adjustedWidth, getColumnWidth, registerChild }) => (
													<div className=''>
														<MultiGrid
															ref={listRef}
															// deferredMeasurementCache={cellCache}
															cellRenderer={cellRenderer}
															classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
															classNameTopRightGrid='border-light border-bottom fw-bold'
															classNameBottomLeftGrid='border-light border-end'
															// classNameBottomRightGrid="border border-0 border-light border-end-1"
															fixedColumnCount={3}
															fixedRowCount={1}
															height={height}
															width={width}
															overscanRowCount={5}
															overscanColumnCount={5}
															// columnCount={Object.keys(data[0]).length}
															columnCount={columns.length}
															columnWidth={cellCache.columnWidth}
															rowCount={filterData.length + 1}
															rowHeight={cellCache.rowHeight}
															// isScrolling={isScrolling}
															onScroll={onScrollHandler}
															scrollLeft={scrollLeft}
														/>
													</div>
												)}
											</ColumnSizer>
										);
									}}
								</AutoSizer>
							)}

						</CardBody>
					</Card>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default memo(ArCollectedData);
