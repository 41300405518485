import React, { useCallback, useEffect, useState } from "react";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button, { ButtonGroup } from "../../components/bootstrap/Button";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";
import showNotification from "../../components/extras/showNotification";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft } from "../../layout/SubHeader/SubHeader";
import Breadcrumb, { BreadcrumbItem } from "../../components/bootstrap/Breadcrumb";
import { useMst } from '../../models';
import Checks from "../../components/bootstrap/forms/Checks";

const Profile = () => {
    const { t } = useTranslation(['translation', 'menu']);
    const { user } = useMst();

    const [settings, setSettings] = useState({})
    const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
    const forceUpdate = useCallback(() => updateState({}), []);


    const recommended = [
        { title: "알림", alarm: 'isReceivePush', id: "receivePush", type: "switch" },
        { title: "문자", alarm: 'isReceiveSMS', id: "receiveSMS", type: "switch" },
        { title: "이메일", alarm: "isReceiveEmail", id: 'receiveEmail', type: "switch" },
    ];

    useEffect(() => {
        // if (store.Auth.user?.receivePush) {
        //   setReceivePush(store.Auth.user?.receivePush)
        // }

        // if (store.Auth.user?.receivePhone) {
        //   setReceivePhoneEnabled(true)
        //   setReceivePhone(store.Auth.user?.receivePhone)
        // }

        // if (store.Auth.user?.receiveEmail) {
        //   setReceiveEmailEnabled(true)
        //   setReceiveEmail(store.Auth.user?.receiveEmail)
        // }
        setSettings(user.me?.alarm ? JSON.parse(user.me?.alarm) : {})
        forceUpdate()
    }, [user.me, forceUpdate])


    console.log(settings)


    const saveSystemSetting = (e) => {
        console.log({
            e: e,
            id: user.me?.id || 0,
            alarm: settings,
        })
        let data = {
            id: user.me?.id || 0,
            alarm: settings,
        }

        if (e === 'receiveSMS' && !/[0-9]{3}-[0-9]{3,4}-[0-9]{3,4}/i.test(settings.receiveSMS)) {
            Toast.show({
                type: 'error',
                text1: '핸드폰 번호를 확인해주세요.',
                text2: 'setting'
            });
            return
        }

        if (e === 'receiveEmail' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(settings.receiveEmail)) {
            Toast.show({
                type: 'error',
                text1: '잘못된 이메일 형식입니다.',
                text2: 'setting'
            });
            return
        }

        UserService.saveAlarm(data).then((r2) => {
            console.log(r2.data)
            AuthService.me().then(r => {
                console.log(r.data)
                store.Auth.setData(r.data)
            })
            showNotification(t('시스템 설정'), t('시스템 설정 저장 하였습니다.'), 'success');
        })

    }

    return (
        <PageWrapper className='full' title={t('시스템 설정')}>
            <SubHeader className={'belt-header'}>
                <SubHeaderLeft className={'flex-wrap'}>
                    <Breadcrumb list={null} tag={'nav'}>
                        <BreadcrumbItem tag='nav' to={'/dashboard'}>
                            {t('프로필')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </SubHeaderLeft>
            </SubHeader>
            <div style={{width: 400, textAlign: 'center'}}>
                <Card shadow='sm' borderSize={1} borderColor='light' className='rounded'>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{t('사용자 정보')}</CardTitle>
                    </CardHeader>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{t('사용자 이름')}</CardTitle>
                        <CardTitle>{user.me.name}</CardTitle>
                    </CardHeader>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{`${t('별칭')} `}</CardTitle>
                        <CardTitle>{user.me.nickname}</CardTitle>
                    </CardHeader>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{`${t('휴대전화')} `}</CardTitle>
                        <CardTitle>{user.me.phone}</CardTitle>
                    </CardHeader>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{`${t('이메일')} `}</CardTitle>
                        <CardTitle>{user.me.email}</CardTitle>
                    </CardHeader>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{`${t('등록일')} `}</CardTitle>
                        <CardTitle>{user.me.createdAt}</CardTitle>
                    </CardHeader>
                </Card>
            </div>
        </PageWrapper>
    )
}

export default React.memo(Profile);