import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Select from '../../../components/bootstrap/forms/Select';
import { useFormik } from 'formik';
import showNotification from '../../../components/extras/showNotification';
// import ValidMultiGrid from './ValidMultiGrid';
import Modal, { ModalHeader, ModalBody, ModalTitle, ModalFooter } from '../../../components/bootstrap/Modal';
import Checks from '../../../components/bootstrap/forms/Checks'
import Icon from '../../../components/icon/Icon';
import moment from 'moment';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import Label from '../../../components/bootstrap/forms/Label';
import UserService from '../../../services/UserService';
import DaumPostcode from 'react-daum-postcode';
import { serachAddress } from '../../../lib/map';
import DeviceService from '../../../services/DeviceService';

const UserAddModal = ({ refresh, isModalOpen, setIsModalOpen, selectedUser }) => {
	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const { company, user } = useMst();
	const [activeTab, setActiveTab] = useState('singleAddTap');
	const [ranks, setRanks] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [groups, setGroups] = useState([]);
	const [file, setFile] = useState('');
	const [loading, setIsLoading] = useState(false);
	const [isMailCheck, setIsMailCheck] = useState(false);
	const [isSendMail, setIsSendMail] = useState(false);
	const [uploadData, setUploadData] = useState([]);
	const [validTableData, setValidTableData] = useState([]);
	const [ checkedUser, setCheckedUser] = useState({name : '', email:''});
	const [isSelectModalOpen, setIsSelectModalOpen] = useState(false)
	const [successAdd, setSuccessAdd] = useState(false);
	const [isOpenAddress, setIsOpenAddress] = useState(false);
	
	useEffect(() => {
		// loadGroupRank();
		// loadGroupNDepartment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setValidTableData([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

	useEffect(() => {
		if(isModalOpen)
			formik.resetForm()
		else
			setUploadData([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalOpen]);


	const s2ab = (s) => {
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};
	//직급 정보
	const loadGroupRank = async () => {
		let response = await RankService.getRank(company.get.id);
		if (response) {
			let rank = [];

			for (let r in response.data.list) {
				rank.push({
					value: response.data.list[r].id,
					label: t(response.data.list[r].name),
				});
			}
			setRanks(rank);
		}
	};

	//부서 및 그룹 정보
	const loadGroupNDepartment = async () => {
		let response = await DepartmentService.getDepartement(company.get.id);
		if (response) {
			let depart = [];

			for (let r in response.data.list) {
				depart.push({
					value: response.data.list[r].id,
					label: t(response.data.list[r].name),
				});
			}
			setDepartments(depart);
			//console.log('departments====', depart)
		}

		response = await GroupService.list(company.get.id);
		if (response) {
			let group = [];

			for (let r in response.data) {
				group.push({
					value: response.data[r].id,
					label: t(response.data[r].name),
				});
			}
			setGroups(group);
			//console.log('group====', group)
		}
	};
	const checkEmail =  async (email) => {
		const checkedEmail = await AuthService.isExistEmail2(email)
		
		return checkedEmail.data
		//return  checkedEmail.data
		// if (checkedEmail.data === true) {
		// 	showNotification('이메일 중복체크','이미 가입되어있는 메일입니다.','danger')
		// 	setIsSelectModalOpen(true)
		// }else{
		// 	showNotification('이메일 중복체크','사용 가능한 메일입니다.','info')
		// 	setIsMailCheck(true)
		// }
	};

	const validate = (values) => {
		const errors = {};
		// if (!values.name) {
		// 	errors.name = t('필수입니다');
		// 	// errors.name = t('이름을 입력해주세요');
		// } else if (values.name.length > 15) {
		// 	errors.name = t('15자리 이하로 입력해주세요');
		// }

		// if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		// 	errors.email = t('잘못된 이메일 형식입니다');
		// }

		// if (!values.model) {
		// 	// errors.department = t('소속부서를 선택해주세요');
		// 	errors.model = t('필수입니다');
		// }

		// if (!values.userId) {
		// 	// errors.joinDate = t('입사일을 선택해주세요');
		// 	errors.userId = t('필수입니다');
		// }

		// if (!selectedUser?.id && !values.password) {
		// 	// errors.password = t('비밀번호를 입력해주세요');
		// 	errors.password = t('필수입니다');
		// }

		// if (values.password !== values.password2) {
		// 	errors.password2 = t('비밀번호와 틀립니다.');
		// }

		// if (!values.phone) {
		// 	// errors.rank = t('직급을 선택해주세요');
		// 	errors.phone = t('필수입니다');
		// }

		// if(values.phone && !/[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}/i.test(values.phone)) {
		// 	errors.phone = t('번호를 확인해주세요');
		// }
		return errors;
	};
	//1인등록
	const singleAdd = async (values,skip) => {
		if (selectedUser?.id) {
			await DeviceService.getInstallModify(values).then((response) => {
				if (response) {
					showNotification(t('단말기 설치정보 수정'), t('단말기 설치정보 수정을 완료 하였습니다'), 'info');
					setIsModalOpen(false);
					setIsSelectModalOpen(false);
					setSuccessAdd(true);
				} else {
					showNotification(t('단말기 설치정보 수정'), t('단말기 설치정보 수정에 실패 하였습니다'), 'danger');
				}
			});
		} 
	};

	//1인초대
	const singleInvite = async () => {
		const form = { data: formik.values, companyId: company.get.id };
		try{
			const res = await CompanyService.joinRequestAdmin(form)
			//이메일 발송
			if(res?.result){
				showNotification('직원초대','직원들을 초대 하였습니다','info')
				
				setIsModalOpen(false);
				setIsSelectModalOpen(false);
			}else{
				showNotification('직원초대','직원초대에 실패 하였습니다','danger')
			}
		}catch(e){
			console.log(e)
		}
	}
	

	//엑셀다중등록
	const multiAdd = async (verification) => {
	
		// let form
		// form = new FormData();
		// form.append('companyId', company.get.id);
		// form.append('userId', user.me.id);
		// form.append('file', file);
		let files = verification.data
		//  [Object.keys(verification.data[0]).join(),Object.values(verification.data).join()]

		let form = {
			companyId : company.get.id,
			userId : user.me.id,
			files : files,
			isSendMail : isSendMail
		}
		
		await CompanyService.joinCompanyDirect(form).then((res) => {
			let a = [];
			
			if (res.code == 200 && res.result) {
				setUploadData(res.data)
				a = res.data && res.data.filter((index) => index.state && index.state == 'FAIL');
				if (a.length > 0) {
					showNotification(
						t('직원등록'),
						`${t('필수값 부족으로 인해 등록을 실패하였습니다')} : ${a.map((index) => index.name)}`,
						'danger',
					);
					
				} else {
					showNotification(t('직원등록'), t('직원을 등록 완료 하였습니다'), 'info');
					setValidTableData([])
					//setIsModalOpen(false)
					refresh()
					
				}
			} else {
				showNotification(t('직원등록'), t('직원 등록을 실패 하였습니다'), 'danger');
				
			}
		});
		setIsLoading(false)
	};

	const formik = useFormik({
		initialValues: {
			id: 0,
			customerName: '',
			customerAddress: '',
			customerAddressDetail: '',
			customerPhone: '',
			customerEmail: '',
			gu: '',
			dong: '',
			lat: 0,
			lng: 0,
		},
		validate,
		onSubmit: (values) => {
			singleAdd(values);
		},
	});
	

	useEffect(() => {
		console.log('selectedUser', selectedUser)	
		formik.values.id = selectedUser?.install?.id || 0
		formik.values.customerName = selectedUser?.install?.installName || ''
		formik.values.customerAddress = selectedUser?.install?.customerAddress || ''
		formik.values.customerAddressDetail = selectedUser?.install?.customerAddressDetail || ''
		formik.values.customerPhone = selectedUser?.install?.customerPhone || ''
		formik.values.customerEmail = selectedUser?.install?.customerEmail || ''
		formik.values.gu = selectedUser?.install?.gu || ''
		formik.values.dong = selectedUser?.install?.dong || ''
		formik.values.lat = selectedUser?.install?.lat || 0
		formik.values.lng = selectedUser?.install?.lng || 0
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser])

	useEffect(() => {}, [formik.values]);

	const excelDownload = useCallback(async () => {

		const xlsx = require('sheetjs-style-v2');
		let row = {
			이름: '김ㅇㅇ',
			휴대폰번호: '010-1234-4567',
			부서: 'ㅇㅇ부',
			근무그룹: 'ㅇㅇ부(선택)',
			직급: '사원',
			입사일: moment().format('YYYY-MM-DD'),
			사번: 'ABCD01234',
			이메일: 'abcedf@abc.com'
		};
		let xlsxData = [];
		xlsxData.push(row);
		let wb = xlsx.utils.book_new();
		let ws = xlsx.utils.json_to_sheet(xlsxData);

        const rangeRef = ws['!ref']
        const range = xlsx.utils.decode_range(rangeRef)

        let columsW = []
        let wsrows = []
        for (let R = range.s.r; R <= range.e.r; ++R) {
          if (R === 0)
            wsrows.push({ hpt: 22 })
          else
            wsrows.push({ hpt: 18 })
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell_address = { c: C, r: R };
            const cell_ref = xlsx.utils.encode_cell(cell_address);
            let cell = ws[cell_ref]

            if (!columsW[C])
              columsW[C] = 0

            switch (typeof (cell.v)) {
              case 'string':
                if (columsW[C] < cell.v.length * 1.5)
                  columsW[C] = cell.v.length * 1.5
                break
              case 'number':
                if (columsW[C] < cell.v.length)
                  columsW[C] = cell.v.length
                break
            }


            if (R === 0) {
              ws[cell_ref].s = {
                font: {
                  name: '맑은 고딕',
                  bold: true,
                  sz: 11,
                  color: { rgb: "FFFFFFFF" }
                },
                fill: {
                  patternType: "solid",
                  bgColor: { rgb: "FF556ee6" },
                  fgColor: { rgb: "FF556ee6" }
                },
                alignment: {
                  vertical: "center",
                  horizontal: "center"
                },
                border: {
                  bottom: {
                    style: 'thin',
                    color: { rgb: "FF000000" },
                  }
                }
              }
            } else {
              ws[cell_ref].s = {
                font: {
                  name: '맑은 고딕',
                  bold: false,
                  sz: 10,
                },
                alignment: {
                  vertical: "center",
                  horizontal: "center"
                }
              }
            }
          }
        }

        let wscols = []
        columsW.map((v, i) => {
          wscols.push({ wch: parseInt(v) * 1.5 })
        })
        // console.log(wscols)
        ws['!cols'] = wscols;
        ws['!rows'] = wsrows;
		
		// xlsx.utils.sheet_set_array_formula(ws, "C2:C999", )

		xlsx.utils.book_append_sheet(wb, ws, "직원등록");
		xlsx.writeFile(wb, '직원등록_샘플.xlsx');

	}, []);

	// 레이블 옆에 * (필수 사항)
	const labelWithRequired = (label, value) => {
		return (
			<Label>
				<div className='d-flex'>
					<>{t(label)}</>
					{/* <div className={`${value.length > 0 ? 'text-success' : 'text-danger'} px-1 fw-normal`}>{value.length > 0 ? '' : '*'}</div> */}
				</div>
			</Label>
		);
	};

	// 해당 필드 아래쪽에 나오는 에러메세지
	const errorMessage = (pxStyle, errors = '') => {
		return <div className={`text-danger py-1 px-${pxStyle}`}>{errors && errors !== '필수입니다' ? '* ' + errors : '' }</div>;
	};

	const addAddress = async (data) => {
		let fullAddress = data.address;
		let extraAddress = '';
		try {
			if (data.addressType === 'R') {
				if (data.bname !== '') {
					extraAddress += data.bname;
				}
				if (data.buildingName !== '') {
					extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
				}
				fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
			}
			formik.setFieldValue('customerAddress', fullAddress);

			const addressRespons = await serachAddress(fullAddress);
			if (addressRespons.documents) {
				//console.log(addressRespons.documents);
				formik.setFieldValue('lat', parseFloat(addressRespons.documents[0].y));
				formik.setFieldValue('lng', parseFloat(addressRespons.documents[0].x));
				formik.setFieldValue('gu', data.sigungu);
				formik.setFieldValue('dong', data.bname);
			}
		} catch (e) {
			//console.log('');
		} finally {
			setIsOpenAddress(false); // 창닫기
		}
	};

	const _contents = (
		<div>
			{activeTab === 'singleAddTap' && ( //등록 탭
				<div style={{padding: '1.2rem'}}>
					<div className='row'>
						<div className='col-md-6'>
							{' '}
							{/* 왼쪽 컬럼 */}
							<div className='col-sm-10 form-item'>
								<FormGroup label={t('IMEI')}>
									<Input
										style={{ backgroundColor: '#ececec' }}
										disabled={true}
										id={'userId'}
										type={'text'}
										value={selectedUser.IMEI}
									/>
								</FormGroup>
							</div>
							<div className='col-sm-10 form-item'>
								<FormGroup label={'고객이름'}>
									<InputGroup>
										<Input
											id={'customerName'}
											type={'text'}
											placeholder={t('고객이름을 입력해주세요')}
											value={formik.values.customerName}
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
										/>
									</InputGroup>
								</FormGroup>
							</div>
							<div className='col-sm-10 form-item'>
								<FormGroup label={'고객 Tel'} >
									<InputGroup>
										<Input id={'customerPhone'} placeholder={t('휴대폰번호를 (숫자만) 입력해주세요')} type={'tel'} value={formik.values.customerPhone} maxLength={13}
											onChange={(e) => {
												formik.setFieldValue('customerPhone',
													formik.values.
														customerPhone = e.target.value
															.replace(/[^0-9]/g, '')
															.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
												)
											}} />
									</InputGroup>
								</FormGroup>
							</div>
						</div>
						<div className='col-md-6'>
							{' '}
							{/* 오른쪽 컬럼 */}
							<div className='col-sm-10 form-item'>
								<FormGroup label={t('S/N')}>
									<Input
										style={{ backgroundColor: '#ececec' }}
										disabled={true}
										id={'name'}
										type={'text'}
										value={selectedUser.SerialNumber}
									/>
								</FormGroup>
							</div>
							<div className='col-sm-10 form-item'>
								<FormGroup label={'고객 Email'}>
									<InputGroup>
										<Input
											id={'customerEmail'}
											type={'text'}
											placeholder={t('고객 Email 입력해주세요')}
											value={formik.values.customerEmail}
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
										/>
									</InputGroup>
								</FormGroup>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6'>
							<div className='col-sm-10 form-item'>
								<FormGroup label={t('주소')}>
									<InputGroup>
										<Input 
										style={{ backgroundColor: '#ececec' }}
										disabled={true}
											id={'customerAddress'} type={'text'} placeholder={t('주소를 검색해주세요')} value={formik.values.customerAddress}
										onChange={formik.handleChange}
										onClick={() => setIsOpenAddress(true)}
										/>
										<Button type={'button'} color={'info'} onClick={() => setIsOpenAddress(true)}>{t('주소검색')}</Button>
									</InputGroup>
								</FormGroup>
							</div>
							<div className='col-sm-10 form-item'>
								<FormGroup label={t('설치자 이름')}>
									<Input 
									style={{ backgroundColor: '#ececec' }}
									disabled={true}
									id={'workerName'} 
									type={'text'} 
									value={selectedUser?.install?.user?.name || ''} 
									/>
								</FormGroup>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='col-sm-10 form-item'>
								<FormGroup label={t('상세주소')}>
									<Input id={'customerAddressDetail'} type={'text'} placeholder={t('상세주소를 입력해주세요')} value={formik.values.customerAddressDetail} onChange={formik.handleChange} />
								</FormGroup>
							</div>
							<div className='col-sm-10 form-item'>
								<FormGroup label={t('설치자 연락처')}>
									<Input
										style={{ backgroundColor: '#ececec' }}
										disabled={true}
										id={'workerPhone'}
										type={'text'}
										value={selectedUser?.install?.user?.phone || ''}
									/>
								</FormGroup>
							</div>
						</div>
					</div>
					<div style={{width: '100%', textAlign:'right'}}>
						<ButtonGroup style={{width:'15%', marginTop: 50}}>
							<Button
								type={'submit'}
								color={'info'}
								//isOutline={true}
								// isDisable={!formik.isValid}
								onClick={()=> {
									if(!formik.isValid){
										showNotification(t('사용자 추가'), t('필수값을 확인해주세요.'), 'danger');
									} else {
										formik.handleSubmit();
									}
									}}>
								{selectedUser?.id ? t('수정') : t('등록')}
							</Button>
						</ButtonGroup>
					</div>
				</div>
			)}
			{activeTab === 'multiAddTap' && ( //일괄 등록 탭
				<Card shadow='sm' borderSize={1} borderColor='light' className='rounded'>
					<CardHeader borderSize={1} borderColor='light' className='rounded'>
						<CardTitle>{`${t('파일로 일괄 등록하기')} (${t('지원파일 형식 및 크기')} : xls, xlsx)`}</CardTitle>
					</CardHeader>
					<CardBody className='h-100'>
						<div className='my-0'>
							<ButtonGroup>
								<Button
									type={'button'}
									//isOutline
									isActive={false}
									// color={'light'}
									isDisable={true}>
									{`${t('예시파일')} : `}
								</Button>
								<Button
									// icon={'FileDownload'}
									type={'button'}
									// color={'info'}
									className='d-flex align-items-center justify-items-center'
									//isOutline={true}
									size={'sm'}
									onClick={async () => {
										excelDownload();
										// await CompanyService.sampleDownload('xlsx').then((res) => {
										// 	const downloadUrl = window.URL.createObjectURL(
										// 		new Blob([s2ab(atob(res.data))], {
										// 			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
										// 		})
										// 	);
										// 	const link = document.createElement('a');
										// 	link.href = downloadUrl;
										// 	link.setAttribute('download', 'sample.xlsx'); //any other extension
										// 	document.body.appendChild(link);
										// 	link.click();
										// 	link.remove();
										// });
									}}>
									<span className='fs-5 text-info fw-bold text-decoration-underline'>{`xlsx ${t('다운로드')}`}</span>
									<Icon icon='FileDownload' size='lg' color='info' className='ms-3' />
								</Button>
							</ButtonGroup>
						</div>

						<div className='mt-2'>* {t('등록할 멤버(직원)이 많을 경우 xls 또는 xlsx 엑셀 파일에 멤버 등록정보를 입력하여 일괄 등록할 수 있습니다.')}</div>
						<div className='mt-2'>
							<InputGroup>
								<Input
									className='form-control'
									id='formFileSm'
									type={'file'}
									accept={'.xls,.xlsx'}
									onChange={(e) => {
										var files = e.target.files; //input file 객체를 가져온다.
										var i, f;
										for (i = 0; i != files.length; ++i) {
											f = files[i];
											var reader = new FileReader(); //FileReader를 생성한다.

											//성공적으로 읽기 동작이 완료된 경우 실행되는 이벤트 핸들러를 설정한다.
											reader.onload = function (e) {
												setFile(f);
											};

											//파일객체를 읽는다. 완료되면 원시 이진 데이터가 문자열로 포함됨.
											reader.readAsBinaryString(f);
										} //end. for
									}}
								/>
								<Button
									type={'button'}
									color={'success'}
									icon='FileUpload'
									isDisable={loading}
									onClick={async () => {
										//setIsLoading(true);
										if (file === '') {
											showNotification(t('직원등록'), t('파일을 등록해 주시기 바랍니다'), 'danger');
										
										} else {
											setValidTableData([]);
											const verification = await verificationFile(file, validTableData, [], company.get.id, 'memberAdd');
											// console.log('result >> ', verification);
											setValidTableData(verification.data);
											// if (verification.result) multiAdd(verification);
											// else setValidTableData(verification.data);
										}
									}}>
									{t('파일 전송')}
								</Button>
							</InputGroup>
						</div>

						{/* 결과 */}
						{loading ? (
							<div className={`w-100 h-100 d-flex gap-2 justify-content-center align-items-center position-absolute top-50 start-50 translate-middle ${loading ? 'bg-light opacity-75':''}`}>
								<Spinner tag='span' color='primary' isGrow />
								<Spinner tag='span' color='secondary' isGrow />
								<Spinner tag='span' color='barnd' isGrow />
							</div>
						) : uploadData?.length > 0 ? (
							<div className='py-5'>
								<table className='table align-middle'>
									<thead>
										<tr className='text-center'>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('순번')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('이름')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('이메일')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('휴대폰번호')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('부서')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('직급')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('입사일')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('사번')}
											</td>
											<td style={{ position: 'sticky', top: 0 }} className='bg-white'>
												{t('상태')}
											</td>
										</tr>
									</thead>
									<tbody>
										{uploadData
											?.filter((v) => v.row[0] !== '')
											.map((v, i) => {
												return (
													<tr key={`u-${i}`} className='text-center'>
														<td className='py-3'>{i + 1}</td>
														<td>{v.row['이름']}</td>
														<td>{v.row['이메일']}</td>
														<td>{v.row['휴대폰번호']}</td>
														<td>{v.row['부서']}</td>
														<td>{v.row['직급']}</td>
														<td>{v.row['입사일'].indexOf('/') > -1 ? moment(v.row['입사일'],'MM/DD/YY').format('YYYY-MM-DD') : v.row['입사일']}</td>
														<td>{v.row['사번']}</td>
														<td>{v.label}</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						) : validTableData?.length > 0 ? (
							<>
								<div className='h-100 mt-4 px-0 border border-light rounded my-1' style={{ minHeight: 300 }}>
									{/* <ValidMultiGrid dataList={validTableData} setData={setValidTableData} /> */}
								</div>
								<div className='mt-2 w-100 text-end'>
									<Button
										type={'button'}
										color={'success'}
										icon='FileUpload'
										size='sm'
										//isOutline={true}
										onClick={async () => {
											const verification = await verificationFile(file, validTableData, [], company.get.id, 'memberAdd');
											// console.log('verification>>', verification);
											if (verification.result) {
												setIsLoading(true)
												multiAdd(verification)
											}
											else setValidTableData(verification.data);
										}}>
										{t('등록')}
									</Button>
								</div>
							</>
						) : (
							<div className='mt-5'>
								<p className='lead'>{t('xls 및 xlsx 파일 작성 가이드')}</p>
								<ol>
									<li>{t('E-Mail, 이름 (필수) 등록할 사용자의 이메일 주소 및 이름 입력')}</li>
									<li>{t('소속, 직급, 입사일, 전화번호 (필수) 등록할 멤버(직원)의 전화번호, 소속 및 직급, 입사일 정보 입력')}</li>
									<li>{t('사번, (선택) 등록할 멤버(직원)의 사번 입력')}</li>
									<li>{t('근무그룹명 (선택) 기본 외 추가 근무그룹에 추가시 입력')}</li>
									<li>{t('(중요) 일괄 등록시 "예시파일을 다운로드" 후 형식을 동일하게 하여 xlsx형식으로 등록')}</li>
									<li>{t('계정 비밀번호는 휴대폰번호 뒤 4자리로 설정됩니다.')}</li>
									<li>{t('이미 가입되어 있는 사용자는 계정생성을 하지 않고 직원등록만 진행됩니다.')}</li>
									<li>{t('등록은 엑셀 예제인 2행부터 시작 됩니다. 예제를 지우고 사용해주세요')}</li>
								</ol>
							</div>
						)}
					</CardBody>
				</Card>
			)}
		</div>
	);

	return (
		<>
			<Modal isOpen={isModalOpen} isCentered={true} isScrollable={true} isStaticBackdrop={true} size='lg'>
				<ModalHeader className='belt-header d-flex justify-content-between'>
					<div className={'text-light'} style={{ fontSize: '1.5rem' }}>
						<b>단말기 설치 정보를</b>
					</div>
					<Icon
						type='button'
						color='light'
						icon='Close'
						size='2x'
						onClick={() => {
							setIsModalOpen(false);
						}}>
					</Icon>
				</ModalHeader>
				<ModalBody>{_contents}</ModalBody>
			</Modal>

			<Modal isOpen={successAdd} setIsOpen={setSuccessAdd} isCentered={true} isScrollable={true} isStaticBackdrop={true} size='sm'>
			<ModalHeader setIsOpen={setSuccessAdd}>
					<ModalTitle><div className='fw-bold fs-4'>{selectedUser?.id ? t('작업자 수정') : t('작업자 추가')}</div></ModalTitle>
			</ModalHeader>
			<ModalBody>
					<div className='fw-bold d-flex justify-content-center'>{selectedUser?.id ? t('작업자 수정이 완료 되었습니다.') : t('작업자 추가가 완료 되었습니다.')}</div> 
			</ModalBody>
			<ModalFooter>
					<Button 
					color={'info'}
					onClick={()=>{setSuccessAdd(false)}}
					>{'닫기'}</Button>
			</ModalFooter>
			</Modal>

			<Modal isOpen={isOpenAddress} fullScreen='xxl' size='xl' setIsOpen={setIsOpenAddress} isStaticBackdrop={true} isCentered={true}>
				<ModalHeader setIsOpen={setIsOpenAddress}></ModalHeader>
				<ModalBody>
					<DaumPostcode onComplete={addAddress} style={{ height: '50vh' }} />
				</ModalBody>
				<ModalFooter></ModalFooter>
			</Modal>
		</>
	);
};

export default UserAddModal;
