import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button, { ButtonGroup } from "../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../components/bootstrap/Modal";
import Card, { CardBody, CardHeader } from "../../../components/bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useMst } from "../../../models";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import Checks from "../../../components/bootstrap/forms/Checks";
import DeviceService from "../../../services/DeviceService";
import moment from "moment";

const errorbit = [
    { label: '전체', value: 'all', color: '#F5BB00' },
    { label: '정상', value: 'normal', color: '#E9897E' },
    { label: '배터리부족', value: 'low_battery', color: '#CF6766' },
    { label: '침수', value: 'flood', color: '#5A5A66' },
    { label: '누수', value: 'leak', color: '#A2B8AD' },
    { label: '장기미사용', value: 'long_term_unused', color: '#FFD700' },
    { label: '동파', value: 'freeze', color: '#CF4500' },
    { label: '고온', value: 'high_temperature', color: '#A8DDCA' },
    { label: '검침기 연결', value: 'meter_connection', color: '#008000' },
    { label: '검침기 통신', value: 'meter_communication', color: '#0000FF' },
    { label: 'Data Limit', value: 'data_limit', color: '#800080' },
    { label: '수질 오염', value: 'water_pollution', color: '#A52A2A' },
    { label: '유량 과부화', value: 'flow_overload', color: '#D2691E' },
    { label: '역류', value: 'backflow', color: '#FF00FF' },
    { label: '옥내 누수', value: 'indoor_leak', color: '#1E90FF' },
    { label: '배터리 부족', value: 'low_battery', color: '#8B0000' },
];

const errorLabel = (error) => {
    const reversedString = error.split('').reverse().join('');

    // 오른쪽에서부터 `1`이 나타나는 인덱스를 찾습니다.
    for (let i = 0; i < reversedString.length; i++) {
        if (reversedString[i] === '1') {
            return errorbit[i + 2].label;

        }
    }
}
const ArErrorModal = observer(({ isOpen, setIsOpen, devices, status, getDevice }) => {
    const navigate = useNavigate();
    const { user } = useMst();

    const [checkAll, setCheckAll] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredDevices, setFilteredDevices] = useState(devices);

    const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
    const forceUpdate = useCallback(() => updateState({}), []);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const updateDevices = useCallback(() => {
        console.log('updateDevices', devices);
        DeviceService.updateDevices({devices}).then((res) => {
            console.log('updateDevices', res);
            getDevice()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    useEffect(() => {
        if (search === '') {
            setFilteredDevices(devices.filter(d => !d.favorite));
            return;
        }
        setFilteredDevices(devices.filter(d => !d.favorite).filter((device) => {
            return device.IMEI.includes(search) || device.SerialNumber.includes(search) || device.install.installName.includes(search);
        }));
    }, [devices, search]);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop={true}
            isScrollable={true}
            isCentered={true}
            //fullScreen={true}
            fullScreen={'lg'}
            size={'lg'}>
            <ModalHeader className='belt-header d-flex justify-content-between'>
                <div className={'text-light'} style={{ fontSize: '1.5rem' }}>
                    <b>이상알림 내역</b>
                </div>
                <Icon
                    type='button'
                    color='light'
                    icon='Close'
                    size='2x'
                    onClick={() => {
                        setIsOpen(false);
                    }}>
                </Icon>
            </ModalHeader>
            <ModalHeader className='border-top d-flex justify-content-between'>
                <Input type='text' style={{ marginLeft: 10 }} placeholder='별칭, IMEI, S/N'
                    value={search} onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                 />
                <Icon icon='Search' size='3x' style={{ marginLeft: 10 }} />
            </ModalHeader>
            <ModalBody style={{ height: '50vh', padding: 5, overflowY: 'auto', scrollbarWidth: 'thin' }}>
                {filteredDevices.map((e, index) => (
                    <div key={`device-modal-${index}`} className='d-flex justify-content-left'
                        style={{
                            border: '1px solid #fa896b', backgroundColor: '#fdede8', color: '#000', padding: 15,
                            margin: 10, borderRadius: 10,  fontSize: '1.1rem',
                        }}
                    >
                        <div><b>{
                            moment(e.Sensing_Time).format('YYYY-MM-DD HH:mm') + ' '}</b><span style={{ color: "blue" }}>{(e?.install?.installName || 'S/N:' + e.SerialNumber)}</span>
                            {' 단말기에서 '}<span style={{ color: "red" }}><b>{errorLabel(e?.Error)}</b></span>
                            {'이(가) 감지되었습니다.'
                            }</div>
                    </div>
                ))}
            </ModalBody>
            {/* <ModalFooter>
                <Button
                    style={{ width: '100%' }}
                    type='button'
                    color='info'
                    onClick={() => {
                        updateDevices()
                        setIsOpen(false);
                    }}>
                    추가
                </Button>
            </ModalFooter> */}
        </Modal>
    );
});

export default ArErrorModal;