import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button, { ButtonGroup } from "../../components/bootstrap/Button";
import Modal, { ModalBody, ModalHeader } from "../../components/bootstrap/Modal";
import Icon from "../../components/icon/Icon";
import Popovers from "../../components/bootstrap/Popovers";
import showNotification from "../../components/extras/showNotification";

const findCenter = (coordinates) => {
    if (coordinates.length === 0) {
        return null;
    }

    const total = coordinates.reduce(
        (acc, coord) => {
            acc.lat += coord.lat;
            acc.lng += coord.lng;
            return acc;
        },
        { lat: 0, lng: 0 }
    );

    const numCoords = coordinates.length;

    return {
        lat: total.lat / numCoords,
        lng: total.lng / numCoords,
    };
};

let mapMarkers = []

const MapModal = observer(({ markers, color, mapFailed }) => {
    const [isOpenMap, setIsOpenMap] = useState(false);
    const [isMarker, setIsMarker] = useState(false);

    const handleClose = () => {
        setIsMapModalOpen(false);
    };

    useEffect(() => {
        setIsMarker(markers.map((device) => device?.install || {}).filter((install) => install?.lat && install?.lng).length > 0) 
    }, [markers]);

	useEffect(() => {
		if (isOpenMap) {
			getMaps(37.205, 127.106, 3)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenMap]);

    const getMaps = useCallback((latitude, longitude, radius) => {
        kakao.maps.load(async () => {
            console.log('get map');
            let container = document.getElementById('kakaoMap');
            
            let center = findCenter(markers.map((device) => device.install).filter((install) => install.lat && install.lng));

            if (!center) {
                center = { lat: 37.2087208128284, lng: 127.097707173097 };
            }

            let options = {
                center: new kakao.maps.LatLng(center.lat, center.lng),
                level: 5,
            };

            const map = new window.kakao.maps.Map(container, options);

            // 위성지도 설정
            map.setMapTypeId(window.kakao.maps.MapTypeId.ROADMAP);

            const greenMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png', // 초록색 마커 이미지 URL
                new window.kakao.maps.Size(24, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );

            const redMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png', // 빨간색 마커 이미지 URL
                new window.kakao.maps.Size(30, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );

            // const center = { lat: 37.205, lng: 127.106 };
            // const range = 0.02; // ±0.01 정도의 범위
            // const numPoints = 10;

            mapMarkers.forEach((marker) => {
                marker.setMap(null);
            });

            markers.forEach((device, i) => {
                if (device.install?.lat && device.install?.lng) {
                    const marker = new kakao.maps.Marker({
                        title: device.install.installName,
                        position: new kakao.maps.LatLng(device.install.lat, device.install.lng),
                        image: !device.ErrorBit || device.ErrorBit.length === 0 ? greenMarkerImage : redMarkerImage,
                    });
                    kakao.maps.event.addListener(marker, 'click', () => {
                        // infowindow.setContent(`<div style="padding:5px;">${device.install.installName}</div>`);
                        // infowindow.open(map, marker);
                    });
                    marker.setMap(map);
                    mapMarkers.push(marker)
                }
            });
            
            // const circle = new kakao.maps.Circle({
            // 	center: new kakao.maps.LatLng(latitude, longitude), // 원의 중심좌표 입니다
            // 	radius: radius, // 미터 단위의 원의 반지름입니다
            // 	strokeWeight: 1, // 선의 두께입니다
            // 	strokeColor: '#75B8FA', // 선의 색깔입니다
            // 	strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            // 	strokeStyle: 'solid', // 선의 스타일 입니다
            // 	fillColor: '#CFE7FF', // 채우기 색깔입니다
            // 	fillOpacity: 0.7, // 채우기 불투명도 입니다
            // });

            // circle.setMap(map);
        });
    }, [markers]);

    return (
			<>
                <Popovers trigger="hover" desc="지도 보기">
                    <Icon style={{ marginRight: 10 }} fontSize={28} color={isMarker ? (color || 'info') : 'danger'} isOutline icon={'TravelExplore'}
                        onClick={(e) => {
                            console.log(e)
                            e.stopPropagation()
                            e.preventDefault()
                            e.nativeEvent.stopPropagation()
                            e.nativeEvent.preventDefault()

                            if (isMarker) {
                                setIsOpenMap(true)
                            } else {
                                showNotification('위치정보', '위치정보가 없습니다. 주소를 입력해주세요.', 'danger')
                                mapFailed(markers)
                            }
                        }}></Icon>
                </Popovers>
				<Modal
					setIsOpen={setIsOpenMap}
					isOpen={isOpenMap}
					isStaticBackdrop={true}
					isScrollable={true}
					isCentered={true}
					//fullScreen={true}
					fullScreen={'lg'}
					size={'xl'}>
					<ModalHeader className='border-top d-flex justify-content-between'>
						<div className={'text-dark'} style={{ fontSize: '1.5rem' }}>
							<b>수용가 위치</b>
						</div>
						<div>
							<ButtonGroup>
								<Button
                                shadow='sm'
									type='button'
									color='dark'
									icon='Close'
									onClick={() => {
										setIsOpenMap(false);
									}}>
									{''}
								</Button>
							</ButtonGroup>
						</div>
					</ModalHeader>
					<ModalBody>
						<div
							className='position-relative border w-100 d-inline-block bg-l25-light border'
							style={{
								width: '100%',
								height: '80vh',
							}}>
							<div id='kakaoMap' className='kakao-map' style={{ width: '100%', height: '80vh' }}></div>
						</div>
					</ModalBody>
				</Modal>
			</>
    );
});

export default MapModal;