import { types } from 'mobx-state-tree';
import moment from 'moment';
import AuthService from '../services/UserService';

export const User = types
	.model('User', {
		id: types.optional(types.number, 0),
		type: types.maybe(types.string),
		userId: types.maybe(types.string),
		name: types.maybe(types.string),
		email: types.maybe(types.string),
		profile: types.maybe(types.string),
		phone: types.maybe(types.string),
		nickname: types.maybe(types.string),
		model: types.maybe(types.string),
		gender: types.maybe(types.string),
		birth: types.maybe(types.string),
		address: types.maybe(types.string),
		addressDetail: types.maybe(types.string),
		createdAt: types.maybe(types.string),
		udpatedAt: types.maybe(types.string),
		parentId: types.maybe(types.number, 0),
		progress: types.maybe(types.number, 100),
		arday: types.maybe(types.number, 1),
		memo: types.maybe(types.string),
		IMEI: types.maybe(types.string),
		alarm: types.maybe(types.string),
	})
	.views((self) => ({
		get me() {
			try {
				return {
					...self,
					profile: self.profile?.includes('http') ? self?.profile || null : self.profile ? '//rnslab.bhz.co.kr' + self.profile : null,
				};
			} catch (e) {
				window.location.replace('/');
			}
		},
	}))
	.actions((self) => ({
		async authMe() {
			try {
				const response = await AuthService.me();
				if (response.data) {
					// console.log("AuthService.me", response.data)
					self.setData(response.data);
					return response.data;
				} else {
					if (window.location.pathname !== '/login' && window.location.pathname !== '/fft' && window.location.pathname !== '/srmobile'
						&& window.location.pathname !== '/mic' && window.location.pathname !== '/xyz' && window.location.pathname !== '/xyz2'
					) {
						// alert('세션이 종료되어 로그아웃 됩니다.');
						window.location.href = '/login';
					}
				}
			} catch (err) {
				if (window.location.pathname !== '/login' && window.location.pathname !== '/fft' && window.location.pathname !== '/srmobile'
					&& window.location.pathname !== '/mic' && window.location.pathname !== '/xyz' && window.location.pathname !== '/xyz2'
				) {
					// alert('세션이 종료되어 로그아웃 됩니다.');
					window.location.href = '/login';
				}
			}
		},

		async setRank(rank) {
			self.userRank = rank;
		},

		async setProgress(progress) {
			self.progress = progress;
		},

		async setIMEI(IMEI) {
			self.IMEI = IMEI;
		},

		async setDepartment(depart) {
			self.userDepartment = depart;
		},

		async setData(data) {
			//console.log(data);
			self.id = data?.id || self.id;
			self.userId = data?.userId || self.userId;
			self.type = data?.type || self.type;
			self.name = data?.name || self.name;
			self.email = data?.email || self.email;
			self.profile = data?.profile || self.profile;
			self.phone = data?.phone || self.phone;
			self.nickname = data?.nickname || self.nickname;
			self.model = data?.model || self.model;
			self.gender = data?.gender || self.gender;
			self.birth = data?.birth || self.birth;
			self.address = data?.address || self.address;
			self.addressDetail = data?.addressDetail || self.addressDetail;
			self.parentId = data?.parentId || self.parentId;
			self.memo = data?.memo || self.memo;
			self.arday = data?.arday || self.arday;
			self.alarm = data?.alarm ? JSON.stringify(data?.alarm) : self.alarm;
			self.createdAt = moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss');
			self.udpatedAt = moment(data?.udpatedAt).format('YYYY-MM-DD HH:mm:ss');
		},

		async logout() {
			self.id = 0;
			self.userId = '';
			self.type = '';
			self.name = '';
			self.email = '';
			self.profile = '';
			self.phone = '';
			self.nickname = '';
			self.model = '';
			self.gender = '';
			self.birth = '';
			self.address = '';
			self.addressDetail = '';
			self.parentId = 0;
			self.memo = '';
			self.alarm = '';
			self.arday = 0;
			self.udpatedAt = '';
			self.createdAt = '';
		},
	}));

export default User;
