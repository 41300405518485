import React, { memo, useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import useFilter from '../../../hooks/useFilter';
import Card, { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Icon from '../../../components/icon/Icon';
import DeviceService from '../../../services/DeviceService';
import moment from 'moment';
import MapModal from '../MapModal';
import NrDeviceModal from '../dashboard/nrchart/NrDeviceModal';
import { useNavigate } from 'react-router-dom';
import UserAddModal from '../dashboard/nrchart/UserAddModal';

const NrDeviceList = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const { user } = useMst();

	const [refresh, setRefresh] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [mode, setMode] = useState('');
	const [isUserAddOpen, setIsUserAddOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [devices, setDevices] = useState([]);
	const [IMEI, setIMEI] = useState('');
	const [info, setInfo] = useState({});

	useEffect(() => {
		DeviceService.getDashboardDeviceList().then(({ data }) => {
			if (data.length > 0) {
				setDevices(data)
				setTotalCount(data.length)
			}
			setLoading(false);
		})
	}, []);

	useEffect(() => {
		setFilterCount(devices.filter((device) => (device.IMEI.includes(searchText) || device.install?.installName?.includes(searchText))).length)
	}, [searchText, devices])

	const mapFailed = (markers) => {
		setInfo(markers[0])
		setIsUserAddOpen(true)
	};


	return (
		<PageWrapper className='full' title={t('기기 관리')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft className={'flex-wrap'}>
					<span className='ms-2 title'>{`${t('총')} : ${totalCount || 0} ${t('건')} / ${t('필터된 건수')} : ${filterCount || 0} ${t('건')}`}</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Input
						type='text'
						placeholder={t('IMEI, 단말기 이름')}
						value={searchText}
						onChange={(e) => {
							const removeGapValue = e.target.value.trim();
							setSearchText(removeGapValue);
						}}
					/>
					<Icon icon='SavedSearch' color="light" style={{fontSize: '2.5rem'}} />
				</SubHeaderRight>
				<SubHeaderRight>
					<Button
						icon='Refresh'
						isDisable={loading}
						type={'button'}
						color={'warning'}
						onClick={() => {
							setRefresh(!refresh)
						}}>
						{`${t('새로고침')}`}
					</Button>
				</SubHeaderRight>
			</SubHeader>

			<Page container='fluid' style={{ marginTop: 0 }}>
				<Card stretch className={`custom-box-shadow rounded-2`}>
					<CardBody className={`rounded-2 row`} >
						{devices.filter((device) => (device.IMEI.includes(searchText) || device.install?.installName?.includes(searchText))).map((d, index) => (
							<div className='col-12 col-md-6 col-lg-4 col-xl-3' key={index}>
								<Card key={index} className={`custom-box-shadow rounded-2`}
									style={{ border: user.me.IMEI === d.IMEI ? ('2px solid #fa896b') : ('1px solid ' + (d.Error ? '#fa896b' : '#5d87ff')), marginTop: 10, cursor: 'pointer' }}
									onClick={(e) => {
										console.log('click', d);
										user.setIMEI(d.IMEI);
										navigate('/nrdashboard')
									}}
								>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '1rem', backgroundColor: d.Error ? '#fdede8' : '#fff', color: '#000' }}>
										<div className='d-flex flex-row justify-content-between'>
											<div><CardTitle style={{ color: '#294faa', fontSize: '1.5rem', marginTop: 0 }}>{d?.install?.installName || '별칭없음'}</CardTitle></div>
											<div>
												<MapModal markers={[d]} mapFailed={mapFailed} />
												<Icon icon='Gear-Fill' fontSize={20} forceFamily='bootstrap'
													onClick={(e) => {
														e.stopPropagation()
														e.preventDefault()
														e.nativeEvent.stopPropagation()
														e.nativeEvent.preventDefault()
														setInfo(d)
														setIsUserAddOpen(true)
													}}
												/>
											</div>
										</div>
										<div className='w-100 text-left' style={{ marginTop: 0 }}>
											<div className='d-flex flex-row justify-content-between'>
												<div style={{ fontSize: '1.0rem' }}>IMEI: {d?.IMEI}</div>
												{(d.packets.length > 0 && d.packets[0]?.BAT !== null) && <div >배터리: <span style={{ color: "blue" }}><b>{parseInt(parseFloat(d.BAT) * 100)}</b></span> %</div>}
											</div>
											<hr />
											<div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
												{(d.packets.length > 0 && (d.packets[0]?.MIC)) && <div style={{ width: '100%' }}> <span style={{ color: "blue" }}><b>{d.packets[0]?.MIC[0] }</b></span> dB</div>}
											</div>
											<div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
												{(d.packets.length > 0 && d.packets[0]?.XYZ) && <div style={{ width: '100%' }}>X: <span style={{ color: "blue" }}><b>{parseInt(parseFloat(d.packets[0]?.XYZ[0]?.split(',')[0]) * 100) / 100}</b></span></div>}
												{(d.packets.length > 0 && d.packets[0]?.XYZ) && <div style={{ width: '100%' }}>Y: <span style={{ color: "blue" }}><b>{parseInt(parseFloat(d.packets[0]?.XYZ[0]?.split(',')[1]) * 100) / 100}</b></span></div>}
												{(d.packets.length > 0 && d.packets[0]?.XYZ) && <div style={{ width: '100%' }}>Z: <span style={{ color: "blue" }}><b>{parseInt(parseFloat(d.packets[0]?.XYZ[0]?.split(',')[2]) * 100) / 100}</b></span></div>}
											</div>
											<div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
												{(d.packets.length > 0 && d.packets[0]?.BAT !== null) && <div style={{ width: '100%' }}>마지막 수신 데이터: <span style={{ color: "blue" }}><b>{moment(d.packets[0]?.Sensing_Time).format('YYYY-MM-DD HH:mm:ss')}</b></span></div>}
											</div>
											{d.Error && d.ErrorBit.length > 0 && <div className='d-flex flex-row justify-content-between' style={{ marginTop: 2 }}>
												<div style={{ width: '100%' }}>이상감지: <span style={{ color: "red" }}><b>{errorLabel(d.Error)}</b></span></div>
											</div>}
										</div>
									</CardBody>
								</Card>
							</div>
						))}
					</CardBody>
				</Card>
				<UserAddModal isModalOpen={isUserAddOpen} setIsModalOpen={setIsUserAddOpen} selectedUser={info} />
			</Page>
		</PageWrapper>
	);
};

export default memo(NrDeviceList);
