import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { observer } from 'mobx-react';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from './contexts/themeContext';
import Aside from './layout/Aside/Aside';
import Wrapper from './layout/Wrapper/Wrapper';
import Portal from './layout/Portal/Portal';
import Toasts, { Toast, ToastContainer } from './components/bootstrap/Toasts';
import useDarkMode from './hooks/useDarkMode';
import COLORS from './common/data/enumColors';
import steps, { styles } from './steps';
import { Provider, rootStore } from './models';
import { setItem } from './lib/localstorage';
import contents from './routes/contentRoutes';
import DashboardPage from './pages/dashboard/page/DashboardPage';
import showNotification from './components/extras/showNotification';

const App = observer(() => {
	const location = useLocation();
	const navigate = useNavigate();
	const [hideAside, setHideAside] = useState(false);

	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);
	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus, lang } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".

	useEffect(() => {
		// console.log('is Login ?')
		rootStore.user
			.authMe()
			.then(async (me) => {
				if (location.pathname === '/') {
					console.log(me.type)
					console.log(me.model)
					if (me.type === 'WORKER') {
						navigate('/install')
					} else if (me.model === 'SR') {
						navigate('/srdashboard')
					} else if (me.model === 'AR') {
						navigate('/ardashboard')
					} else if (me.model === 'NR') {
						navigate('/nrdashboard')
					} else if (me.model === 'WR') {
						navigate('/wrdashboard')
					} else {
						navigate('/dashboard')
					}
				}
			})
			.catch((e) => {
				console.log(e);
				if (location.pathname !== '/srmobile') {
					navigate('/login')
					showNotification('로그인', '로그인에 실패하였습니다.' + '다시 확인해주세요' + '.', 'danger');
				}
			});

		let accessToken = new URL(window.location.href).searchParams.get('access-token');
		if (accessToken) {
			setItem('access-token', accessToken);
			setHideAside(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return (
		<Provider value={rootStore}>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
								zIndex: fullScreenStatus && 1,
								overflow: fullScreenStatus && 'scroll',
							}}>
							<Routes>
								{Object.entries(contents.withOutAsidePage).map(([key, page]) => (
									<Route key={key} path={page.path} />
								))}
								{!hideAside && <Route key="aside" path='*' element={<Aside />} />}
								{hideAside && <Route key="all" path='*' />}
							</Routes>
							<Wrapper />
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
			</ThemeProvider>
		</Provider>
	);
});

export default App;
