import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import useFilter from '../../../hooks/useFilter';
import { object } from 'prop-types';
import Popovers from '../../../components/bootstrap/Popovers';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { saveAs } from 'file-saver';
import showNotification from '../../../components/extras/showNotification';

let isLoaded = [];
const ArUsage = ({ detailViewDevice, params }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [loading, setLoading] = useState(true);
	const [filterData, setFilterData] = useState([]);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const [progress, setProgress] = useState(100);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 100,
				fixedHeight: false,
			}),
		[]
	);

	const errorbit = [
		{ label: '전체', value: 'all', color: '#F5BB00' },
		{ label: '정상', value: 'normal', color: '#E9897E' },
		{ label: '배터리부족', value: 'low_battery', color: '#CF6766' },
		{ label: '침수', value: 'flood', color: '#5A5A66' },
		{ label: '누수', value: 'leak', color: '#A2B8AD' },
		{ label: '장기미사용', value: 'long_term_unused', color: '#FFD700' },
		{ label: '동파', value: 'freeze', color: '#CF4500' },
		{ label: '고온', value: 'high_temperature', color: '#A8DDCA' },
		{ label: '검침기 연결', value: 'meter_connection', color: '#008000' },
		{ label: '검침기 통신', value: 'meter_communication', color: '#0000FF' },
		{ label: 'Data Limit', value: 'data_limit', color: '#800080' },
		{ label: '수질 오염', value: 'water_pollution', color: '#A52A2A' },
		{ label: '유량 과부화', value: 'flow_overload', color: '#D2691E' },
		{ label: '역류', value: 'backflow', color: '#FF00FF' },
		{ label: '옥내 누수', value: 'indoor_leak', color: '#1E90FF' },
		{ label: '배터리 부족', value: 'low_battery', color: '#8B0000' },
	];

	const errorLabel = (error) => {
		if (!error) return '정상';
		const reversedString = error.split('').reverse().join('');

		// 오른쪽에서부터 `1`이 나타나는 인덱스를 찾습니다.
		for (let i = 0; i < reversedString.length; i++) {
			if (reversedString[i] === '1') {
				return errorbit[i + 2].label;

			}
		}
	}

	const columns = useMemo(
		() => [
			// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
			{ grow: 1, minWidth: 200, name: '저장일자', key1: 'createdAt' },
			{ grow: 1, minWidth: 200, name: 'IMEI', key1: 'IMEI' },
			{ grow: 1, minWidth: 30, name: 'S/N', key1: 'SerialNumber' },
			{ grow: 1, minWidth: 30, name: 'SIG', key1: 'Sig' },
			{ grow: 1, minWidth: 30, name: 'MODEL', key1: 'Model' },
			// { grow: 1, minWidth: 30, name: 'Error', key1: 'Error' },
			{ grow: 1, minWidth: 30, name: '절전모드', key1: 'Sleep' },
			// { grow: 1, minWidth: 30, name: '검침 시작 시간', key1: 'Start_time' },
			// { grow: 1, minWidth: 30, name: '절전 시간', key1: 'Sleep_Time' },
			{ grow: 1, minWidth: 30, name: '측정 시간', key1: 'Sensing_Time' },
			// { grow: 1, minWidth: 30, name: '실내온도', key1: 'Temperature_In' },
			{ grow: 1, minWidth: 30, name: '온도', key1: 'Temperature_Out' },
			// { grow: 1, minWidth: 30, name: '실내습도', key1: 'Humidity_In' },
			{ grow: 1, minWidth: 30, name: '습도', key1: 'Humidity_Out' },
			{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
			{ grow: 1, minWidth: 30, name: '지온', key1: 'Ground_Temperature' },
			{ grow: 1, minWidth: 30, name: '지습', key1: 'Ground_Humidity' },
			{ grow: 1, minWidth: 30, name: 'EC', key1: 'EC' },
			{ grow: 1, minWidth: 30, name: 'pH', key1: 'pH' },
			{ grow: 1, minWidth: 30, name: 'CO2', key1: 'CO2' },
			{ grow: 1, minWidth: 30, name: '통신성공률', key1: 'E_RATE' },
			{ grow: 1, minWidth: 30, name: 'CID', key1: 'CID' },
			{ grow: 1, minWidth: 30, name: 'RSSI', key1: 'RSSI' },
			{ grow: 1, minWidth: 30, name: 'RSRP', key1: 'RSRP' },
			{ grow: 1, minWidth: 30, name: 'RSRQ', key1: 'RSRQ' },
			{ grow: 1, minWidth: 30, name: 'SNR', key1: 'SNR' },
			{ grow: 2, minWidth: 30, name: '펌웨어 버전', key1: 'FW_Version' },
		],
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									if (['USER', 'GROUP'].indexOf(user.me.type) < 0) {
										setDetailData(rowData)
										setArrayModalOpen(true)
									}
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'MIC':
					data = columnData ?
						(
							<Badge
								color='success'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.install?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.install?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.install?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData || 'N/A';
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
			if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
				if (isLoaded.indexOf(filterData.length) < 0) {
					isLoaded.push(filterData.length);
					getData();
				}
			} else {
				// const keysToCheck = ['id', 'name']; // 체크할 키들
				// const isDuplicate = checkDuplicate(originData, keysToCheck);
				// console.log(isDuplicate); // true
			}

		setScrollLeft(e.scrollLeft)
	};

	const getData = useCallback(async (load) => {
		// console.log(filterButtonContainer);
		setLoading(true);
		if (load === 'init') {
			isLoaded = [];
			setFilterData([]);
		}
		params.type = 'DATA'
		params.page = Math.floor(filterData.length / 30) + 1
		if (detailViewDevice) {
			params.searchText = detailViewDevice.IMEI
			params.searchType = 'IMEI'
		}
		const { data } = await PacketService.getPacketList2(params)
		setFilterData((prev) => [...prev, ...data.list.reverse()])
		setTotalCount(data.totalCount)
		setFilterCount(data.filterCount)

		setLoading(false);
	}, [params, filterData, detailViewDevice])

	useEffect(() => {
		getData('init');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, detailViewDevice])

	
	return (
		<div style={{ height: 'calc(90vh - 140px)', }}>
			{filterData?.length > 0 && (
				<AutoSizer
					style={{  scrollbarWidth: 'thin'}}
					onResize={() => {
						listRef.current.recomputeGridSize();
					}}>
					{({ width, height }) => {
						// console.log(width, height);
						return (
						<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width} style={{ scrollbarWidth: 'thin'}}>
								{({ adjustedWidth, getColumnWidth, registerChild }) => (
									<div className=''>
										<MultiGrid
											ref={listRef}
											// deferredMeasurementCache={cellCache}
											cellRenderer={cellRenderer}
											classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
											classNameTopRightGrid='border-light border-bottom fw-bold'
											classNameBottomLeftGrid='border-light border-end'
											// classNameBottomRightGrid="border border-0 border-light border-end-1"
											fixedColumnCount={2}
											fixedRowCount={1}
											height={height}
											width={width}
											overscanRowCount={5}
											overscanColumnCount={5}
											// columnCount={Object.keys(data[0]).length}
											columnCount={columns.length}
											columnWidth={cellCache.columnWidth}
											rowCount={filterData.length + 1}
											rowHeight={cellCache.rowHeight}
											// isScrolling={isScrolling}
											onScroll={onScrollHandler}
											scrollLeft={scrollLeft}
										/>
									</div>
								)}
							</ColumnSizer>
						);
					}}
				</AutoSizer>
			)}
		</div>
	);
};

export default memo(ArUsage);
