import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import Card, { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import moment from 'moment';
import * as XLSX from 'xlsx';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import useFilter from '../../../hooks/useFilter';
import Progress from '../../../components/bootstrap/Progress';
import NrDeviceModal from '../dashboard/nrchart/NrDeviceModal';
import UserAddModal from '../dashboard/nrchart/UserAddModal';
import Popovers from '../../../components/bootstrap/Popovers';
import MapModal from '../MapModal';
import DeviceService from '../../../services/DeviceService';
import Spinner from '../../../components/bootstrap/Spinner';

let isLoaded = [];
let prevPage = -1
let interval
let plus = 1
const NrCollectedData2 = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [loading, setLoading] = useState(true);
	const [timer, setTimer] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const [IMEI, setIMEI] = useState(null);
	const { originData, filterData, filterButtonContainer, setOriginData, renderFilterCollpase, renderFilterMenuButton } = useFilter({
		usingFilter: ['USER', 'GROUP'].indexOf(user.me.type) > -1 ? ['IMEI'] : ['IMEI', 'OP_code', '이름'],
		whatPage: '수집 데이터',
		defaultFilter: [],
	});
	const [refresh, setRefresh] = useState(false);
	const [progress, setProgress] = useState(100);
	const [wsocket, setWsocket] = useState(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [reconnect, setReconnect] = useState(0)
	const [isUserAddOpen, setIsUserAddOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [info, setInfo] = useState({});
	const [devices, setDevices] = useState([]);

	const [columns, setColumns] = useState([
		// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
		{ grow: 1, minWidth: 30, name: '측정시간', key1: 'SensingTime' },
		{ grow: 1, minWidth: 30, name: 'decibel', key1: 'decibel' },
		{ grow: 1, minWidth: 30, name: 'X', key1: 'x' },
		{ grow: 1, minWidth: 30, name: 'Y', key1: 'y' },
		{ grow: 1, minWidth: 30, name: 'Z', key1: 'z' },
		// { grow: 1, minWidth: 30, name: 'frequencies', key1: 'frequencies' },
		// { grow: 1, minWidth: 30, name: 'magnitudes', key1: 'magnitudes' },
		{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
	]);
	
	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 100,
				fixedHeight: false,
			}),
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'magnitudes':
					data = columnData[columns[columnIndex]?.key2] || 'N/A'
					break
				case 'BAT':
					data = parseInt(parseFloat(columnData) * 100)
					break
				case 'x':
					data = 
						columnData ? parseInt(parseFloat(columnData[0]) * 100) / 100 : 'N/A'
					break;
				case 'y':
					data = 
						columnData ? parseInt(parseFloat(columnData[0]) * 100) / 100 : 'N/A'
					break;
				case 'z':
					data = 
						columnData ? parseInt(parseFloat(columnData[0]) * 100) / 100 : 'N/A'
					break;
				case 'installName':
					data = rowData?.install?.installName || '별칭없음';
					break;
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									if (['USER', 'GROUP'].indexOf(user.me.type) < 0) {
										setDetailData(rowData)
										setArrayModalOpen(true)
									}
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'decibel':
					data = Math.ceil((columnData * 0.5) + 100)
					if (data < 40) data = 40
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.install?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.install?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.install?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData || 'N/A';
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								// setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
		// console.log(loading, e.scrollTop, e.clientHeight, e.scrollHeight, totalCount, filterData.length, isLoaded)
		// if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
		// 	if (isLoaded.indexOf(filterData.length) < 0) {
		// 		console.log('getData')
		// 		isLoaded.push(filterData.length);
		// 		getData();
		// 	}
		// } else {
		// 	// const keysToCheck = ['id', 'name']; // 체크할 키들
		// 	// const isDuplicate = checkDuplicate(originData, keysToCheck);
		// 	// console.log(isDuplicate); // true
		// }

		setScrollLeft(e.scrollLeft)
	};

	// const getData = async (load) => {
	// 	// console.log(filterButtonContainer);
	// 	setLoading(true);

	// 	if (load === 'init') isLoaded = [];
	// 	let where = {
	// 		currentPage: load === 'init' ? 0 : originData.length,
	// 		filter: filterButtonContainer,
	// 		// sort: sortColumn,
	// 		// order: sortDirection ? 'ASC' : 'DESC',
	// 	}

	// 	const resUserList = await PacketService.getPacketList(where)
	// 		.then((res) => res?.data || {
	// 			totalCount: 0,
	// 			filterCount: 0,
	// 			list: []
	// 		})
	// 		.catch((error) => {
	// 			// showNotification(t('직원 목록'), t('직원 목록을 불러오는 도중에 문제가 발생했습니다.'), 'danger')
	// 		});

	// 	setTotalCount(resUserList.totalCount);
	// 	setFilterCount(resUserList.filterCount);
	// 	setOriginData((prev) => [...prev, ...resUserList.list]);

	// 	setLoading(false);
	// };

	const getInfo = useCallback(async () => {
		const { data } = await DeviceService.getInfo({ IMEI })
		setInfo(data)
	}, [IMEI])

	useEffect(() => {
		if (IMEI) getInfo()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI, isUserAddOpen])

	useEffect(() => {
		if (user.me.IMEI) setIMEI(user.me.IMEI)
		else {
			DeviceService.getDashboardDeviceList().then(({ data }) => {
				if (data.length > 0) {
					setDevices(data)
					setIMEI(data[0].IMEI)
				}
			})
		}
	}, [user.me])
	
	useEffect(() => {
		console.log(columns)
	}, [columns])

	useEffect(() => {
		let ws

		async function fetchData() {
			const deviceInfo = await DeviceService.getDeviceInfo({ IMEI })
			console.log('deviceInfo', deviceInfo)

			if (deviceInfo.data?.device?.Model === 'RSX-511') {
				ws = new WebSocket('wss://sensor.metainnotech.co.kr/socket/v1/dashboard/data');
			} else {
				ws = new WebSocket('wss://sensor.rnslab.com/socket/v1/dashboard/data');
			}

			// 웹소켓 이벤트 리스너를 설정합니다.
			ws.onopen = () => {
				setWsocket(ws)
				console.log('웹소켓이 연결되었습니다.');
				if (IMEI) ws.send(JSON.stringify({ IMEI }));
			};

			ws.onmessage = (event) => {
				setIsPlaying(true)
				// 서버로부터 메시지를 수신했을 때 실행됩니다.
				let data = JSON.parse(event.data)
				// console.log(data[0])
				// console.log(data.length)

				// let temp = []
				// if (data[0]?.frequencies?.length > 0) {
				// 	for (let i = 0; i < data[0].frequencies.length; i++) {
				// 		temp.push({ grow: 1, minWidth: 30, name: data[0].frequencies[i] + 'Hz', key1: 'magnitudes', key2: i },)
				// 	}
				// }

				// setColumns([
				// 		// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
				// 		{ grow: 1, minWidth: 30, name: '측정시간', key1: 'SensingTime' },
				// 		{ grow: 1, minWidth: 30, name: 'decibel', key1: 'decibel' },
				// 		{ grow: 1, minWidth: 30, name: 'X', key1: 'x' },
				// 		{ grow: 1, minWidth: 30, name: 'Y', key1: 'y' },
				// 		{ grow: 1, minWidth: 30, name: 'Z', key1: 'z' },
				// 		// { grow: 1, minWidth: 30, name: 'frequencies', key1: 'frequencies' },
				// 		// { grow: 1, minWidth: 30, name: 'magnitudes', key1: 'magnitudes' },
				// 		{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
				// 		...temp
				// 	])

				setOriginData([...data])
				forceUpdate()

				setIsPlaying(false)
				setLoading(false)
				clearInterval(interval);
				ws.send(JSON.stringify({ IMEI }));
			};

			ws.onclose = () => {
				console.log('웹소켓이 닫혔습니다.');
			};
		}

		fetchData();

		// 컴포넌트가 언마운트될 때 웹소켓 연결을 종료합니다.
		return () => {
			ws.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reconnect]); 

	useEffect(() => {
		setOriginData([]);
		setLoading(true)
		// getData('init');
		clearInterval(interval);
		interval = setInterval(() => {
			setTimer((prev) => prev + plus);
		}, 50);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI])

	useEffect(() => {
		if (plus === 1 && timer >= 100) {
			plus = -1
		} else if (plus === -1 && timer < 1) {
			plus = 1
		}
	}, [timer])

	useEffect(() => {
		let timer2

		setInterval(() => {
			if (!wsocket) {
				setReconnect(r => r + 1)
			} else if (!isPlaying) {
				if (IMEI) wsocket.send(JSON.stringify({ IMEI }));
			}
		}, 5000)

		return (
			clearInterval(timer2)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const excelDownload = async () => {
		const wb = XLSX.utils.book_new();

		let temp = []
		filterData.map((x) => {
			setProgress((prev) => prev + 100 / filterData.length);
			x.decibel = Math.ceil((x.decibel * 0.5) + 100)

			let d = {
				'측정시간': moment(x.SensingTime).format('YYYY-MM-DD HH:mm:ss'),
				// '측정시': moment(x.SensingTime).format('HH'),
				// '측정분': moment(x.SensingTime).format('mm'),
				// '측정초': moment(x.SensingTime).format('ss'),
				'decibel': x.decibel < 40 ? 40 : x.decibel,
				'X': x.x[0] ? parseInt(parseFloat(x.x[0]) * 100) / 100 : 'N/A',
				'Y': x.y[0] ? parseInt(parseFloat(x.y[0]) * 100) / 100 : 'N/A',
				'Z': x.z[0] ? parseInt(parseFloat(x.z[0]) * 100) / 100 : 'N/A',
				'배터리': parseInt(parseFloat(x.BAT) * 100),
			}

			if (x?.frequencies?.length > 0) {
				for (let i = 0; i < x.frequencies.length; i++) {
					d[x.frequencies[i] + 'Hz'] = x.magnitudes[i]
				}
			}

			temp.push(d)
		})

		const ws = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws, '데이터');

		XLSX.writeFile(wb, `실시간 데이터수집 ${moment(filterData[filterData.length - 1].Sensing_Time).format('YYYY년 MM월 DD일 HH시')}_${IMEI}.xlsx`);
	}

	const mapFailed = () => {
		setIsUserAddOpen(true)
	}	

	return (
		<PageWrapper className='fluid' title={t('실시간 수집 데이터')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={'/nrdashboard'}>
							{t('실시간 수집 데이터')}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='Refresh'
						isDisable={loading}
						type={'button'}
						color={'warning'}
						onClick={() => {
							setLoading(true)
							setReconnect(r => r + 1)
						}}>
						{`${t('새로고침')}`}
					</Button>
					<Popovers trigger="hover" desc={'기기 변경'}>
						<Icon
							className="pointer"
							color='light'
							icon={'SwapHoriz'}
							style={{ marginTop: 5, fontSize: '3rem' }}
							onClick={() => {
								setIsOpen(true)
							}}>
						</Icon>
					</Popovers>
					<Button color='warning' icon='Info' onClick={() => setIsUserAddOpen(true)}>
						{t('기기 설치 정보')}
					</Button>
					{/* <Button color='danger' icon='Settings' onClick={() => navigate('/ardashboard')}>
						{t('기기 환경 설정')}
					</Button> */}
					<Button shadow='sm' isDisable={progress < 100} isOutline={progress < 100} color='success' icon='Download' onClick={excelDownload}>
						<Icon icon='FileEarmarkExcelFill' />
						{progress < 100 && <Progress style={{ width: 60 }}>
							<Progress
								style={{ width: 100 - progress + '%' }}
								isAnimated={true}
								color='success'
								value={
									progress
								}
							/>
							<Progress
								style={{ width: progress + '%' }}
								isAnimated={true}
								color='light'
								value={
									100 - progress
								}
							/>
							<div style={{
								color: '#46bcaa'
							}}><b>{progress}%</b></div>
						</Progress>}
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid' style={{ marginTop: 0 }} >
				<div>
					<Card className={`custom-box-shadow rounded-2`}>
						<CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} >
							<CardTitle style={{ fontSize: '1.5rem', marginLeft: 20, marginTop: 6 }}>{'단말기 실시간 데이터'}</CardTitle>
							<Card className={`custom-box-shadow rounded-2`} style={{ right: 10, border: '1px solid blue', height: 87, position: 'fixed', top: 45, zIndex: 99 }}>
								<CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} style={{ paddingTop: 25, fontSize: '1.1rem', backgroundColor: '#3e66c4', color: '#fff', paddingLeft: 10 }}>
									<MapModal markers={[info]} color={'light'} mapFailed={mapFailed} />
									<div style={{ marginRight: 8, textAlign: 'left' }}>
										<div className='d-flex flex-row justify-content-between'>
											<div><span style={{ color: "white" }}><b>{info?.install?.installName || '별칭없음'}</b></span></div>
											<div>&nbsp;&nbsp;&nbsp;</div>
											<div>IMEI: <span style={{ color: "white" }}><b>{info.IMEI}</b></span></div>
										</div>
										<div>마지막 수신 데이터: <span style={{ color: "white" }}><b>{info.updatedAt}</b></span></div>
									</div>
								</CardBody>
							</Card>
						</CardBody>
					</Card>
				</div>
				<Card stretch className={`custom-box-shadow rounded-2`} >
					<CardBody className={`rounded-2 align-items-center text-center p-0`} >
						 { loading &&
							<div className={`w-100 h-100 d-flex gap-2 justify-content-center align-items-center position-absolute top-50 start-50 translate-middle ${loading ? 'bg-light opacity-75' : ''}`}>
								<Spinner tag='span' color='primary' isGrow />
								<Spinner tag='span' color='primary' isGrow />
								<Spinner tag='span' color='primary' isGrow />
							</div>
						}
						{(!loading && filterData?.length > 0) && (
							<AutoSizer
								onResize={() => {
									listRef.current.recomputeGridSize();
								}}>
								{({ width, height }) => {
									// console.log(width, height);
									return (
										<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width}>
											{({ adjustedWidth, getColumnWidth, registerChild }) => (
												<div className=''>
													<MultiGrid
														ref={listRef}
														// deferredMeasurementCache={cellCache}
														cellRenderer={cellRenderer}
														classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
														classNameTopRightGrid='border-light border-bottom fw-bold'
														classNameBottomLeftGrid='border-light border-end'
														// classNameBottomRightGrid="border border-0 border-light border-end-1"
														fixedColumnCount={0}
														fixedRowCount={1}
														height={height}
														width={width}
														overscanRowCount={5}
														overscanColumnCount={5}
														// columnCount={Object.keys(data[0]).length}
														columnCount={columns.length}
														columnWidth={cellCache.columnWidth}
														rowCount={filterData.length + 1}
														rowHeight={cellCache.rowHeight}
														// isScrolling={isScrolling}
														onScroll={onScrollHandler}
														scrollLeft={scrollLeft}
													/>
												</div>
											)}
										</ColumnSizer>
									);
								}}
							</AutoSizer>
						)}

					</CardBody>
				</Card>
				<NrDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={devices} setIMEI={setIMEI} />
				<UserAddModal isModalOpen={isUserAddOpen} setIsModalOpen={setIsUserAddOpen} selectedUser={info} />
			</Page>
		</PageWrapper>
	);
};

export default memo(NrCollectedData2);
