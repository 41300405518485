import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import Popovers from '../../components/bootstrap/Popovers';
import Card, { CardBody, CardFooter, CardFooterRight } from '../../components/bootstrap/Card';
import { DateRange } from 'react-date-range';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import ReactSelect from 'react-select';
import Input from '../../components/bootstrap/forms/Input';
import * as locales from 'react-date-range/dist/locale';
import {
	addDays,
	format,
	startOfISOWeek,
	endOfISOWeek,
	startOfMonth,
	endOfMonth,
	endOfYear,
	getISODay,
	endOfWeek,
	startOfWeek,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import DeviceService from '../../services/DeviceService';
import MapModal from './MapModal';
import moment from 'moment';
import Icon from '../../components/icon/Icon';
import { set } from 'lodash';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import { useFormik } from 'formik';
import Progress from '../../components/bootstrap/Progress';
import { useMst } from '../../models';

const ArHeaderTop = observer(({ totalText, setParams, disableDeviceStatus, disableMap, disalbeTotalText, 
	startDate, endDate, durationType, disableExcelUp, disableArea, enableTab, type, disableSecond, acceptNumber,
	startDateType = 'DAY', disableExcelDown, markers, IMEI, excelDownload, excelUpload }) => {
	const { user } = useMst();
	const { t } = useTranslation(['translation', 'menu']);
	const [searchText, setSearchText] = useState('all');
	const [searchType, setSearchType] = useState('acceptNumber')
	const [searchTextArray, setSearchTypeArray] = useState(
		{ 
			acceptNumber: [{ label: '전체', value: 'all' }], 
			customerName: [{ label: '전체', value: 'all' }],
			IMEI: [{ label: '전체', value: 'all' }], 
			customerAddress: [{ label: '전체', value: 'all' }] 
		}
	);
	const [searchTypes, setSearchTypes] = useState([
		{ value: 'acceptNumber', label: '수용가 번호' },
		{ value: 'customerName', label: '수용가 이름' },
		{ value: 'SerialNumber', label: 'SerialNumber' },
		{ value: 'IMEI', label: 'IMEI      ' },
		{ value: 'customerAddress', label: '주소' },
	]);
	const [logState, setLogState] = useState('day');
	const [selectedTab, setSelectedTab] = useState('GRAPH');
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorData, setErrorData] = useState([]);
	const [gu, setGu] = useState([]);
	const [selectedGu, setSelectedGu] = useState({ label: '전체', value: 'all' });
	const [dong, setDong] = useState([]);
	const [selectedDong, setSelectedDong] = useState({ label: '전체', value: 'all' });
	const [selectionRange, setSelectionRange] = useState([
		{
			startDate: startDate ? new Date(startDate) : startOfISOWeek(new Date()), // addDays(new Date(), -7)
			endDate: endDate ? new Date(endDate) : endOfISOWeek(new Date()),
			key: 'selection',
		},
	]);
	const [tempSelectionRange, setTempSelectionRange] = useState([
		{
			startDate: startOfISOWeek(new Date()), // addDays(new Date(), -7)
			endDate: endOfISOWeek(new Date()),
			key: 'selection',
		},
	]);
	const [deviceStatus, setDeviceStatus] = useState('all');
	const [dateType, setDateType] = useState(startDateType);
	const [dateTypes, setDateTypes] = useState([]);
	const [selectedDateType, setSelectedDateType] = useState({});
	const [selectedDateType2, setSelectedDateType2] = useState({});
	const [progress, setProgress] = useState(100);
	const [yearList,] = useState([...Array(new Date().getFullYear() - 2024 + 1).keys()].map(i => ({ label: (2024 + i) + '년', value: 2024 + i })));
	const [monthList, ] = useState([...Array(12).keys()].map(i => ({ label: (i + 1) + '월', value: (i + 1) })))
	const [selectYear, setSelectYear] = useState(new Date().getFullYear());
	const [selectMonth, setSelectMonth] = useState(new Date().getMonth() + 1);

	const [isLoading, setIsLoading] = useState(0);

	const [tempParams, setTempParams] = useState({});

	const popRef = useRef({});
	const childRef = useRef();

	useEffect(() => {
		setProgress(user.me?.progress || 100);
	}, [user.me]);

	useEffect(() => {
		if (durationType && selectYear && selectMonth && user.me?.arday) {
			console.log(durationType, selectYear, selectMonth, user.me?.arday)
			setSelectionRange([
				{
					startDate: moment([selectYear, selectMonth - 1, user.me?.arday || 1]).toDate(),
					endDate: moment([selectYear, selectMonth, user.me?.arday || 1]).toDate(),
					key: 'selection',	
				},
			]);

			setTempSelectionRange([
				{
					startDate: moment([selectYear, selectMonth - 1, user.me?.arday || 1]).toDate(),
					endDate: moment([selectYear, selectMonth, user.me?.arday || 1]).toDate(),
					key: 'selection',
				},
			]);

			if (childRef.current)
				childRef.current.forceRefresh([
					{
						startDate: moment([selectYear, selectMonth - 1, user.me?.arday || 1]).toDate(),
						endDate: moment([selectYear, selectMonth, user.me?.arday || 1]).toDate(),
						key: 'selection',	
					},
				], groupId, logState);
		}
	}, [durationType, selectYear, selectMonth, user.me, logState]);

	useEffect(() => {
		let date = []

		switch (dateType) {
			case 'DAY':
				break;
			case 'MONTH':
				// 2024년 1월 부터 지금까지 년월 목록 
				date = Array.from({ length: moment().diff(moment('2024-01-01'), 'months') + 1 }, (v, i) => {
					return {
						label: moment('2024-01-01').add(i, 'months').format('YYYY년 MM월'),
						value: moment('2024-01-01').add(i, 'months').format('YYYY-MM-01'),
					};
				}).reverse();
				if (!selectedDateType?.value) setSelectedDateType(date[0])
				break;
			case 'YEAR':
				// 2024년 부터 지금까지 년 목록
				date = Array.from({ length: moment().diff(moment('2024-01-01'), 'years') + 1 }, (v, i) => {
					return {
						label: moment('2024-01-01').add(i, 'years').format('YYYY년'),
						value: moment('2024-01-01').add(i, 'years').format('YYYY-01-01'),
					};
				}).reverse();
				if (!selectedDateType2?.value) setSelectedDateType2(date[0])
				break;
			default:
				break;
		}

		setDateTypes(date)
	}, [dateType, selectedDateType, selectedDateType2]);

	useEffect(() => {
		setDong(selectedGu?.children || [{ label: '전체', value: 'all'}]);
		setSelectedDong({ label: '전체', value: 'all' });
	}, [gu, selectedGu]);

	useEffect(() => {
		let p = {
			searchType,
			searchText,
			dateType,
			logState,
			gu: selectedGu.value,
			dong: selectedDong.value,
			deviceStatus,
			startDate: selectionRange[0].startDate,
			endDate: selectionRange[0].endDate,
			selectedTab: selectedTab,
		}
		if (dateType === 'MONTH') {
			p.startDate = selectedDateType.value;
			p.endDate = endOfMonth(new Date(selectedDateType.value));
		} else if (dateType === 'YEAR') {
			p.startDate = selectedDateType2.value;
			p.endDate = endOfYear(new Date(selectedDateType2.value));
		}
		console.log('setParams', p);
		setTempParams(p);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText, logState, selectedGu, selectedDong, deviceStatus, selectionRange, 
		setParams, selectedTab, selectedDateType, selectedDateType2, dateType]);

	useEffect(() => {
		let p = {
			// searchType,
			// searchText,
			logState,
			gu: selectedGu.value,
			dong: selectedDong.value,
			deviceStatus,
			startDate: selectionRange[0].startDate,
			endDate: selectionRange[0].endDate,
		}
		if (dateType === 'MONTH') {
			p.startDate = selectedDateType.value;
			p.endDate = endOfMonth(new Date(selectedDateType.value));
		} else if (dateType === 'YEAR') {
			p.startDate = selectedDateType2.value;
			p.endDate = endOfYear(new Date(selectedDateType2.value));
		}

		console.log('getAutocomplete', p);
		getAutocomplete(p);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logState, selectedGu, selectedDong, deviceStatus, selectionRange, 
		setParams, selectedDateType, selectedDateType2]);

	useEffect(() => {
		setSearchText('all');
		// if (isSelectedFirst) {
		// 	console.log(searchTextArray)
		// 	if (searchTextArray[searchType].length > 1) {
		// 		setSearchText(searchTextArray[searchType][1].value);
		// 	}
		// }
	}, [searchType]);

	useEffect(() => {
		console.log('acceptNumber', searchTextArray['acceptNumber'].length, acceptNumber);
		// setSearchType('IMEI');
		if (searchTextArray['IMEI'].length > 1 && IMEI) {
			setSearchText(IMEI);
			setSearchType('IMEI');
		} else if (searchTextArray['acceptNumber'].length > 1 && acceptNumber) {
			setSearchText(acceptNumber);
			setSearchType('acceptNumber');
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI, acceptNumber, searchTextArray]);

	// console.log('isLoading', isLoading);
	useEffect(() => {
		if (isLoading === 0) {
			setParams(tempParams);
		}
	}, [isLoading, setParams, tempParams]);
	
	const getAutocomplete = useCallback((p) => {
		setIsLoading(i => i + 1);
		DeviceService.getAutocomplete(p).then((res) => {
			setIsLoading(i => i - 1);
			console.log(res.data)
			if (res.data) {
				setSearchTypeArray(res.data)
			}
		});
	}, []);

	const getArea = useCallback(() => {
		console.log('getArea');
		setIsLoading(i => i + 1);
		DeviceService.getArea().then((res) => {
			setIsLoading(i => i - 1);
			if (res.data) {
				setGu(res.data);
			}
		});
	}, []);

	const getDeviceStatus = useCallback(async () => {
		setIsLoading(i => i + 1);
		const { data } = await DeviceService.getDeviceStatus()
		setIsLoading(i => i - 1);
		setErrorData(data)
	}, [])

	useEffect(() => {
		if (type === 'SR') {
			setSearchTypes([
				{ value: 'IMEI', label: 'IMEI      ' },
				{ value: 'SerialNumber', label: 'SerialNumber' },
			]);
			setSearchType('IMEI');
		} 
	}, [type]);


	// console.log(selectionRange, gu, dong);

	useEffect(() => {
		getArea();
		getDeviceStatus()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const CustomDropdownIndicator = () => null;

	const inlineRadios = useFormik({
		initialValues: {
			radios: 'first',
		},
	});

	return (
		<div className="header-top">
			<SubHeader >
				{!disalbeTotalText &&
				<SubHeaderLeft>
						<InputGroup>
							<ReactSelect
								styles={{
									control: (provided) => ({
										...provided,
										width: 150,
									}),
								}}
								options={searchTypes}
								value={searchTypes.find((x) => x.value === searchType)}
								onChange={(e) => {
									setSearchType(e.value);
								}}
							/>
							<ReactSelect
								styles={{
									control: (provided) => ({
										...provided,
										width: 150,
										marginLeft: 2,
									}),
								}}
								options={searchTextArray[searchType]}
								value={searchTextArray[searchType].find((x) => x.value === searchText)}
								onChange={(e) => {
									setSearchText(e.value);
								}}
								components={{ DropdownIndicator: CustomDropdownIndicator }}
							/>
						</InputGroup>
				</SubHeaderLeft>}
				<SubHeaderCenter>
					{durationType === 'Monthly' && (
						<>
							<ReactSelect
								styles={{
									control: (provided) => ({
										...provided,
										width: 100,
										marginLeft: 10,
									}),
								}}
								options={yearList}
								value={yearList.find((x) => x.value === selectYear)}
								onChange={(e) => {
									setSelectYear(e.value);
								}}
								components={{ DropdownIndicator: CustomDropdownIndicator }}
							/>
							<ReactSelect
								styles={{
									control: (provided) => ({
										...provided,
										width: 80,
										marginLeft: -10,
									}),
								}}
								options={monthList}
								value={monthList.find((x) => x.value === selectMonth)}
								onChange={(e) => {
									setSelectMonth(e.value);
								}}
								components={{ DropdownIndicator: CustomDropdownIndicator }}
							/>
						</>)}
					{durationType !== 'Monthly' && (
					<ButtonGroup>
						<Button
							style={{ border: '1px solid #cecece', borderRight: '0' }}
							shadow='sm'
							type={'button'}
							color={dateType === 'DAY' ? 'info' : 'light'}
							onClick={() => {
								setDateType('DAY');
							}}>
							{t('일간')}
						</Button>
						<Button
							style={{ border: '1px solid #cecece', borderRight: '0', borderLeft: '0' }}
							shadow='sm'
							type={'button'}
							color={dateType === 'MONTH' ? 'info' : 'light'}
							onClick={() => {
								setDateType('MONTH');
							}}>
							{t('월간')}
						</Button>
						<Button
							style={{ border: '1px solid #cecece', borderLeft: '0' }}
							shadow='sm'
							type={'button'}
							color={dateType === 'YEAR' ? 'info' : 'light'}
							onClick={() => {
								setDateType('YEAR');
							}}>
							{t('연간')}
						</Button>
					</ButtonGroup>)}
					<div>
						<Button
							type='button'
							icon='ChevronLeft'
							isDisabled={isDisabled}
							onClick={() => {
								if (dateType === 'DAY') {
									setSelectionRange([
										{
											key: 'selection',
											startDate: moment(selectionRange[0].startDate)
												.subtract(moment(selectionRange[0].endDate)
												.diff(moment(selectionRange[0].startDate), logState), logState)
												// .startOf('isoWeek')
												.toDate(),
											endDate: moment(selectionRange[0].startDate)
												// .subtract(1, logState)
												// .endOf('isoWeek')
												.toDate(),
										},
									]);
									if (childRef.current)
										childRef.current.forceRefresh([
											{
												key: 'selection',
												startDate: moment(selectionRange[0].startDate)
													.subtract(moment(selectionRange[0].endDate)
														.diff(moment(selectionRange[0].startDate), logState), logState)
													// .startOf('isoWeek')
													.toDate(),
												endDate: moment(selectionRange[0].startDate)
													// .subtract(1, logState)
													// .endOf('isoWeek')
													.toDate(),
											},
										], groupId, logState);

									} else if (dateType === 'MONTH') {
										let dateIndex = dateTypes.findIndex((x) => x.value === selectedDateType.value);
										if (dateIndex < dateTypes.length - 1) {
											setSelectedDateType(dateTypes[dateIndex + 1]);
										}
									} else if (dateType === 'YEAR') {
										let dateIndex = dateTypes.findIndex((x) => x.value === selectedDateType2.value);
										if (dateIndex < dateTypes.length - 1) {
											setSelectedDateType2(dateTypes[dateIndex + 1]);
										}
									}
							}}
						/>
						<Popovers
							id='selectDate'
							placement={'bottom'}
							ref={popRef}
							desc={
								<div className='row' /* ref={popRef} */>
									<div className='col-xl-12'>
										<Card className='shadow-none w-100 mb-0'>
											<CardBody className='px-0 pb-0 row'>
												{dateType === 'DAY' && (
													<div className='col-sm-12 px-0'>
														<DateRange
															locale={locales['ko']}
															editableDateInputs={true}
															dragSelectionEnabled={isDisabled}
															dateDisplayFormat='yyyy-MM-dd'
															ranges={tempSelectionRange}
															maxDate={endOfISOWeek(new Date())}
															minDate={startOfISOWeek(new Date('2024-01-01'))}
															// showSelectionPreview={true}
															moveRangeOnFirstSelection={false}
															// retainEndDateOnFirstSelection={false}
															className='d-flex'
															// onRangeFocusChange={(e)=>{
															// 	console.log(e)
															// 	// setIsCalendar(e[1] === 1)
															// }}
															onChange={(item) => {
																// console.log('onChange > ', item);
																// setSelectionRange([item.selection]);
																setTempSelectionRange([item.selection]);
															}}
															months={2}
															direction='horizontal'
														//scroll={{enabled: true}}
														/>
													</div>
												)}
												{(dateType === 'MONTH' || dateType === 'YEAR') && (
													<div className='col-sm-12 border'>
														<div style={{maxHeight: '40vh', overflowY:'auto'}} >
															{dateTypes.map((item, index) => (
																<div key={index} style={{padding:5}}
																>
																	<Button
																		type='button'
																		color={dateType === 'MONTH' ? item.value === selectedDateType.value ? 'info' : 'light' :
																			dateType === 'YEAR' ? item.value === selectedDateType2.value ? 'info' : 'light' : 'light'
																		}
																		onClick={() => {
																			if (dateType === 'MONTH') setSelectedDateType(item)
																			if (dateType === 'YEAR') setSelectedDateType2(item)
																			Promise.all([
																				setSelectionRange(tempSelectionRange)
																			]).then(() => {
																				if (popRef.current) popRef.current.onOpen();
																				if (childRef.current)
																					childRef.current.forceRefresh(tempSelectionRange, groupId, logState);
																			})
																		}}>
																		{item.label}
																	</Button>
																</div>
															))}
														</div>
													</div>
												)}
											</CardBody>
											{dateType === 'DAY' && (
											<CardFooter className='py-0 my-0'>
												<CardFooterRight>
													<Button
														type='button'
														color='success'
														onClick={() => {
															// console.log('work')
															Promise.all([
																setSelectionRange(tempSelectionRange)
															]).then(() => {
																if (popRef.current) popRef.current.onOpen();
																if (childRef.current)
																	childRef.current.forceRefresh(tempSelectionRange, groupId, logState);
															})
														}}>
														{t('조회')}
													</Button>
												</CardFooterRight>
											</CardFooter>)}
										</Card>
									</div>
								</div>
							}
							// placement='bottom-end'
							className='mw-100'
							trigger='click'>
							<span className='text-dark fw-light' style={{ cursor: 'pointer' }}>
								{dateType === 'DAY'
									? `${format(
										selectionRange[0].startDate,
										'yyyy.MM.dd'
									)} ~ ${format(selectionRange[0].endDate, 'yyyy.MM.dd')}`
									:  dateType === 'MONTH' ? `${selectedDateType.label}`
									: dateType === 'YEAR' ? `${selectedDateType2.label}` : ''
								}
							</span>
						</Popovers>
						<Button
							type='button'
							icon='ChevronRight'
							isDisable={isDisabled}
							onClick={() => {
								if (dateType === 'DAY') {
									setSelectionRange([
										{
											key: 'selection',
											startDate: moment(selectionRange[0].endDate)
												// .add(1, logState)
												// .startOf('isoWeek')
												.toDate(),
											endDate: moment(selectionRange[0].endDate)
												.add(moment(selectionRange[0].endDate)
													.diff(moment(selectionRange[0].startDate), logState), logState)
												// .startOf('isoWeek')
												.toDate(),
										},
									]);
									if (childRef.current)
										childRef.current.forceRefresh([
											{
												key: 'selection',
												startDate: moment(selectionRange[0].endDate)
													// .add(1, logState)
													// .startOf('isoWeek')
													.toDate(),
												endDate: moment(selectionRange[0].endDate)
													.add(moment(selectionRange[0].endDate)
														.diff(moment(selectionRange[0].startDate), logState), logState)
													// .startOf('isoWeek')
													.toDate(),
											},
										], groupId, logState);
								} else if (dateType === 'MONTH') {
									let dateIndex = dateTypes.findIndex((x) => x.value === selectedDateType.value);
									if (dateIndex > 0) {
										setSelectedDateType(dateTypes[dateIndex - 1]);
									}
								} else if (dateType === 'YEAR') {
									let dateIndex = dateTypes.findIndex((x) => x.value === selectedDateType2.value);
									if (dateIndex > 0) {
										setSelectedDateType2(dateTypes[dateIndex - 1]);
									}
								}
							}}
						/>
						<Button
							type='button'
							color='info'
							isLight
							icon='CalendarCheck'
							isDisable={isDisabled}
							size='sm'
							onClick={() => {
								popRef.current.onOpen();
							}}>
							{/* {t('기간 선택')} */}
						</Button>
					</div>
				</SubHeaderCenter>
				<SubHeaderRight>
					<div style={{ width: 120 + (disableMap ? 50 : 0) + (disableExcelUp ? 80 : 0) }} />
					{!disableMap && <MapModal markers={markers} />}
					<div>
					{!disableExcelDown &&
							<Button shadow='sm' isDisable={progress < 100} isOutline={progress < 100} color='success' icon='Download' onClick={excelDownload}>
								<Icon icon='FileEarmarkExcelFill' />
								{progress < 100 && <Progress style={{ width: 60 }}>
									<Progress
										style={{ width: 100 - progress + '%' }}
										isAnimated={true}
										color='success'
										value={
											progress
										}
									/>
									<Progress
										style={{ width: progress + '%' }}
										isAnimated={true}
										color='light'
										value={
											100 - progress
										}
									/>
									<div style={{
										color: '#46bcaa'
									}}><b>{progress}%</b></div>
								</Progress>}
						</Button>}
					
					</div>
					{!disableExcelUp &&
						<Button shadow='sm' color='info' icon='Upload' onClick={excelUpload}>
						<Icon icon='FileEarmarkExcelFill' />
						{''}
					</Button>}
				</SubHeaderRight>
			</SubHeader>
		</div>
	)
})

export default ArHeaderTop;