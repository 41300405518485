import React, { useCallback, useEffect, useState } from 'react';
import { useMst } from '../../../models';
import { useLocation, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import Page from '../../../layout/Page/Page';
import Card, {  CardBody,  CardTitle } from '../../../components/bootstrap/Card';
import Button  from '../../../components/bootstrap/Button';
import DeviceService from '../../../services/DeviceService';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import XyzChartPage from './nrchart/xyzChart';
import FFTChartPage from './nrchart/fftChart';
import MicChartPage from './nrchart/micChart';
import PacketService from '../../../services/PacketService';
import StData from './nrchart/stData';
import Popovers from '../../../components/bootstrap/Popovers';
import NrDeviceModal from './nrchart/NrDeviceModal';
import UserAddModal from './nrchart/UserAddModal';
import MapModal from '../MapModal';

const NrDashboardPage = observer(({props}) => {
	const { user, company, notifiction } = useMst();
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation(['translation', 'menu']);
	const [viewTab, setViewTab] = useState('LIVE');
	const [IMEI, setIMEI] = useState('');
	const [info, setInfo] = useState({});

	const [devices , setDevices] = useState([]);
	const [isUserAddOpen, setIsUserAddOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const getInfo = useCallback(async () => {
		const { data } = await DeviceService.getInfo({ IMEI })
		setInfo(data)

		let viewTabTemp = location.state?.viewTab
		if (viewTabTemp) setViewTab(viewTabTemp) 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI])

	useEffect(() => {
		if (IMEI) getInfo()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI, isUserAddOpen])

	useEffect(() => {
		if (user.me.IMEI) setIMEI(user.me.IMEI)
		else {
			DeviceService.getDashboardDeviceList().then(({ data }) => {
				if (data.length > 0) {
					setDevices(data)
					setIMEI(data[0].IMEI)
				}
			})
		}
	}, [user.me])

	const mapFailed = () => {
		setIsUserAddOpen(true)
	}	

	return (
		<PageWrapper className='fluid' title={t('대시보드')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={'/nrdashboard'}>
							{t('대시보드')}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>	
				<SubHeaderRight>
					<Popovers trigger="hover" desc={'기기 변경'}>
						<Icon
							className="pointer"
							color='light'
							icon={'SwapHoriz'}
							style={{ marginTop: 5, fontSize: '3rem' }}
							onClick={() => {
								setIsOpen(true)
							}}>
						</Icon>
					</Popovers>
					<Button color='warning' icon='Info' onClick={() => setIsUserAddOpen(true)}>
						{t('기기 설치 정보')}
					</Button>
					{/* <Button color='danger' icon='Settings' onClick={() => navigate('/ardashboard')}>
						{t('기기 환경 설정')}
					</Button> */}
				</SubHeaderRight>
			</SubHeader>

			<Page container='fluid' style={{paddingTop: '0.7rem'}} >
				<div className='row'>
					<div>
						<Card className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} >
								<CardTitle style={{ fontSize: '1.5rem', marginLeft: 20, marginTop: 6 }}>{viewTab === 'STATISTICS' ? '기간 내 최대 소음 및 진동 가속도 값' : '단말기 실시간 데이터'}</CardTitle>
								<Card className={`custom-box-shadow rounded-2`} style={{ right: 10, border: '1px solid blue', height: 87, position: 'fixed', top: 45, zIndex: 99 }}>
									<CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} style={{ paddingTop: 25, fontSize: '1.1rem', backgroundColor: '#3e66c4', color: '#fff', paddingLeft: 10 }}>
										<MapModal markers={[info]} color={'light'} mapFailed={mapFailed} />
										<div style={{ marginRight: 8, textAlign: 'left' }}>
											<div className='d-flex flex-row justify-content-between'>
												<div><span style={{ color: "white" }}><b>{info?.install?.installName || '별칭없음'}</b></span></div>
												<div>&nbsp;&nbsp;&nbsp;</div>
												<div>IMEI: <span style={{ color: "white" }}><b>{info.IMEI}</b></span></div>
											</div>
											<div>마지막 수신 데이터: <span style={{ color: "white" }}><b>{info.updatedAt}</b></span></div>
										</div>
										<Popovers trigger="hover" desc={viewTab === 'STATISTICS' ? '실시간 정보' : '통계'}>
											<Button color='light'
												icon={viewTab === 'STATISTICS' ? 'Description' : 'BarChart'}
												size='lg'
												style={{ marginTop: 5, marginRight: 10 }}
												onClick={() => {
													setViewTab(viewTab === 'STATISTICS' ? 'LIVE' : 'STATISTICS')
												}}>
												{''}
											</Button>
										</Popovers>
									</CardBody>
								</Card>
							</CardBody>
						</Card>
					</div>
					{viewTab === 'STATISTICS' && (
						<div className='row'>
							<div className='col-xl-4 col-lg-4 col-md-4 text-center'>
								<Card className={`custom-box-shadow rounded-2`}>
									<CardBody className={`rounded-2 align-items-center text-center`}>
										<CardTitle style={{ marginTop: 5, fontSize: '1.5rem', fontWeight: 800 }}>일간</CardTitle>
										<StData IMEI={IMEI} type={'day'} />
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-4 col-lg-4 col-md-4 text-center'>
								<Card className={`custom-box-shadow rounded-2`}>
									<CardBody className={`rounded-2 align-items-center text-center`}>
										<CardTitle style={{ marginTop: 5, fontSize: '1.5rem', fontWeight: 800 }}>주간</CardTitle>
										<StData IMEI={IMEI} type={'week'} />
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-4 col-lg-4 col-md-4 text-center'>
								<Card className={`custom-box-shadow rounded-2`}>
									<CardBody className={`rounded-2 align-items-center text-center`}>
										<CardTitle style={{ marginTop: 5, fontSize: '1.5rem', fontWeight: 800 }}>월간</CardTitle>
										<StData IMEI={IMEI} type={'month'} />
									</CardBody>
								</Card>
							</div>
						</div>
					)}
					{viewTab === 'LIVE' && (
					<div className='col-xl-12 col-lg-12 col-md-12 text-center'>
						<Card className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`}>
									<MicChartPage IMEI={IMEI} />
							</CardBody>
						</Card>
						<Card className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`}>
								<XyzChartPage IMEI={IMEI} />
							</CardBody>
						</Card>
						<Card className={`custom-box-shadow rounded-2`}>
							<CardBody className={`rounded-2 align-items-center text-center`}>
								<FFTChartPage IMEI={IMEI} />
							</CardBody>
						</Card>
					</div>
					)}
				</div>
				<NrDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={devices} setIMEI={setIMEI} />
				<UserAddModal isModalOpen={isUserAddOpen} setIsModalOpen={setIsUserAddOpen} selectedUser={info} />
			</Page>
		</PageWrapper>
	);
});

export default NrDashboardPage;