import React, { useEffect, useState, useContext } from 'react';
import Card, { CardBody, CardHeader } from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import logo from '../../assets/img/aside-logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Checks from '../../components/bootstrap/forms/Checks';
import showNotification from '../../components/extras/showNotification';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import Icon from '../../components/icon/Icon';
import LANG, { getLangWithKey } from '../../lang';
import Label from '../../components/bootstrap/forms/Label';
import moment from 'moment';
import ThemeContext from '../../contexts/themeContext';
import DaumPostcode from 'react-daum-postcode';
import { serachAddress } from '../../lib/map';
import KakaoIcon from '../../assets/img/kakao_icon.png';
import config from '../../config';
import axios from 'axios';
import { getItem, removeItem, setItem } from '../../lib/localstorage';
import jwtDecode from 'jwt-decode';
import Select from '../../components/bootstrap/forms/Select';
import DeviceService from '../../services/DeviceService';
import { useMst } from '../../models';
import UserService from '../../services/UserService';

let requestData = new URL(window.location.href).searchParams.get('requestData');
let state = new URL(window.location.href).searchParams.get('state');
const code = new URL(window.location.href).searchParams.get('code');

const SignUp = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const { user } = useMst();
	const navigate = useNavigate();

	const { lang, setLang } = useContext(ThemeContext);

	const [openModal, setOpenModal] = useState(false);
	const [ModalLink, setModalLink] = useState('');

	const [isOpenAddress, setIsOpenAddress] = useState(false);
	const [complete, setComplete] = useState(false);
	const [isKakao, setIsKakao] = useState(true);
	const [isRequest, setIsRequest] = useState(true);
	const [kakaoData, setKakaoData] = useState({});
	const [recevieData, setRecevieData] = useState({});
	const [inviter, setInviter] = useState({});

	const [device, setDevice] = useState({});

	useEffect(() => {
		const IMEI = new URL(window.location.href).searchParams.get('IMEI');
		console.log({ IMEI });
		if (IMEI) setItem('IMEI', IMEI);
	}, []);

	useEffect(() => {
		if (user.me && user.me?.id) {
			const IM = getItem('IMEI');
			if (IM && IM !== 'null' && !device.IMEI) {
				DeviceService.getInfo({IMEI: IM}).then(d => {
					if (d?.data) {
						setDevice(d.data)

						loginData.values.id = d.data?.install?.id || ''
						loginData.values.IMEI = d.data?.IMEI || ''
						loginData.values.SerialNumber = d.data?.SerialNumber || ''
						loginData.values.installName = d.data?.install?.installName || ''
						loginData.values.customerName = d.data?.install?.customerName || ''
						loginData.values.customerEmail = d.data?.install?.customerEmail || ''
						loginData.values.customerPhone = d.data?.install?.customerPhone || ''
						loginData.values.customerAddress = d.data?.install?.customerAddress || ''
						loginData.values.customerAddressDetail = d.data?.install?.customerAddressDetail || ''
						loginData.values.acceptNumber = d.data?.install?.acceptNumber || ''
						loginData.values.meterStartValue = d.data?.install?.meterStartValue || 0
						loginData.values.meterEndValue = d.data?.install?.meterEndValue || 0
						loginData.values.gu = d.data?.install?.gu || ''
						loginData.values.dong = d.data?.install?.dong || ''
						loginData.values.lat = d.data?.install?.lat || 0
						loginData.values.lng = d.data?.install?.lng || 0
						loginData.values.userId = user.me.id
					}
				});
			}
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.me, device]);

	const getRequestData = async () => {
		if (requestData || state) {
			let requestData2 = window.atob(requestData || state);
			requestData2 = JSON.parse(decodeURIComponent(requestData2));
			console.log(requestData2);

			if (requestData2.companyId && requestData2.userId) {
				const response = await AuthService.searchJoinus({ companyId: requestData2.companyId, userId: requestData2.userId });
				console.log(response.data);
				if (response.data) {
					setRecevieData(requestData2);
					setInviter(response.data);
					setIsRequest(false);
					loginData.setFieldValue('companyId', response.data?.company?.id);
					loginData.setFieldValue('userId', response.data?.user?.id);
					loginData.setFieldValue('email', requestData2?.email);
					loginData.setFieldValue('name', requestData2?.name);
					loginData.setFieldValue('phone', requestData2.phone.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));

					if (!response.data?.user?.name || !response.data.company?.name) {
						alert('잘못된 접근입니다. 초대장을 다시 받아주세요.');
						window.history.back();
					}
				}
			}
		}
	};

	const getKaKaoData = async () => {
		if (code) {
			axios({
				url: 'https://kauth.kakao.com/oauth/token',
				method: 'POST',
				headers: {
					'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
				},
				data: new URLSearchParams({
					grant_type: 'authorization_code',
					client_id: config.KAKAO_API_KEY,
					redirect_uri: config.REDIRECT_URI + '/joinus',
					code: code,
					state: state,
				}),
			}).then((res) => {
				setItem('kakao-access-token', JSON.stringify(res.data));
				const decoded = jwtDecode(res.data.id_token);
				// console.log(decoded);

				axios({
					url: 'https://kapi.kakao.com/v2/user/me',
					method: 'GET',
					headers: {
						Authorization: `Bearer ${res.data.access_token}`,
						'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
					},
				}).then(async (res) => {
					// console.log(res.data);
					setKakaoData(res.data);
					setIsKakao(false);

					loginData.setFieldValue('sns', {
						id: res.data?.id,
						...res.data?.kakao_account,
						...res.data?.properties,
					});
				});
			});
		}
	};

	useEffect(() => {
		console.log(requestData);
		console.log(state);
		console.log(code);

		getRequestData();
		getKaKaoData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log(kakaoData, recevieData, isKakao, isRequest);
		if (!isRequest && !isKakao && !kakaoData?.kakao_account) {
			alert('잘못된 접근입니다. 다시 시도해주세요.');
			window.history.back();
		}

		// if (!isRequest && !isKakao && kakaoData?.kakao_account?.name !== recevieData?.name) {
		// 	alert('본인 인증한 이름이 일치하지 않습니다. 다시 시도해주세요.');
		// 	window.history.back();
		// }

		// if (!isRequest && !isKakao && kakaoData?.kakao_account?.phone_number.replace('+82 ', '0').replace(/-/gi, '') !== recevieData?.phone.replace(/-/gi, '')) {
		// 	alert('본인 인증한 전화번호가 일치하지 않습니다. 다시 시도해주세요.');
		// 	window.history.back();
		// }

		if (!isRequest && !isKakao && kakaoData?.kakao_account?.birthday && kakaoData?.kakao_account?.birthyear) {
			loginData.setFieldValue('birthday', kakaoData?.kakao_account?.birthyear + kakaoData?.kakao_account?.birthday);
		}

		if (!isRequest && !isKakao && kakaoData?.kakao_account?.gender) {
			loginData.setFieldValue('gender', kakaoData?.kakao_account?.gender === 'male' ? '남' : '여');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kakaoData, recevieData, isKakao, isRequest]);

	const loginData = useFormik({
		initialValues: {
			id: '',
			IMEI: '',
			SerialNumber: '',
			installName: '',
			customerName: '',
			customerPhone: '',
			customerEmail: '',
			customerAddress: '',
			customerAddressDetail: '',
			acceptNumber: '',
			meterStartValue: 0,
			meterEndValue: 0,
			userId: '',
			gu: '',
			dong: '',
			lat: 0,
			lng: 0,
		},
		validate: (values) => {
			const errors = {};

			return errors;
		},
		onSubmit: async (values) => {
			for (let key in values) {
				if (typeof values[key] === 'string') values[key] = values[key].trim();
			}
			if (values.cardDateYear && values.cardDateMonth) {
				values.cardDate = values.cardDateMonth + '/' + values.cardDateYear;
			}

			register(values);
			// setDisableRegisterBtn(true);
		},
	});

	//* 함수는 사용 순서대로 나열 *//

	const changeLanguage = (lng) => {
		i18n
			.changeLanguage(lng)
			.then(() => {
				moment.locale(lng);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
						<span>{`${getLangWithKey(lng)?.text}`}</span>
					</span>,
					t('You updated the language of the site.')
				);
			})
			.then(() => {
				setLang(lng);
			});
	};

	// 레이블 옆에 * (필수 사항)
	const labelWithErrors = (label, errors) => {
		return (
			<Label>
				<div className='d-flex'>
					<>{t(label)}</>
					<div className='text-danger px-1 fw-normal'>{errors && errors === '필수입니다' ? '* ' : !errors ? '' : ''}</div>
				</div>
			</Label>
		);
	};

	// 해당 필드 아래쪽에 나오는 에러메세지
	const errorMessage = (pyStyle, errors = '') => {
		return <div className={`text-danger px-2 py-${pyStyle}`}>{errors && errors !== '필수입니다' && errors !== '동의해주세요' ? '* ' + t(errors) : errors === '동의해주세요' ? ' *' : ''}</div>;
	};

	const AgreedModal = (type) => {
	};

	const register = async (values) => {
		await DeviceService.getInstallModify(values).then((response) => {
			if (response) {
				showNotification(t('기기설치 수정'), t('기기설치 수정을 완료 하였습니다'), 'info');
				refresh();
				setIsModalOpen(false);
				setIsSelectModalOpen(false);
				setSuccessAdd(true);
			} else {
				showNotification(t('기기설치 수정'), t('기기설치 수정에 실패 하였습니다'), 'danger');
			}
		});
	};

	const addAddress = async (data) => {
		let fullAddress = data.address;
		let extraAddress = '';
		try {
			if (data.addressType === 'R') {
				if (data.bname !== '') {
					extraAddress += data.bname;
				}
				if (data.buildingName !== '') {
					extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
				}
				fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
			}
			loginData.setFieldValue('address', fullAddress);

			const addressRespons = await serachAddress(fullAddress);
			if (addressRespons.documents) {
				//console.log(addressRespons.documents);
				loginData.setFieldValue('lat', parseFloat(addressRespons.documents[0].y));
				loginData.setFieldValue('lng', parseFloat(addressRespons.documents[0].x));
				loginData.setFieldValue('gu', data.sigungu);
				loginData.setFieldValue('dong', data.bname);
			}
		} catch (e) {
			//console.log('');
		} finally {
			setIsOpenAddress(false); // 창닫기
		}
	};

	const cardLists = [
		'기업 BC',
		'광주은행',
		'롯데카드',
		'KDB산업은행',
		'BC카드',
		'삼성카드',
		'새마을금고',
		'신한카드',
		'신협',
		'씨티카드',
		'우리BC카드(BC 매입)',
		'우리카드(우리 매입)',
		'우체국예금보험',
		'저축은행중앙회',
		'전북은행',
		'제주은행',
		'카카오뱅크',
		'케이뱅크',
		'토스뱅크',
		'하나카드',
		'현대카드',
		'KB국민카드',
		'NH농협카드',
		'Sh수협은행',
		'다이너스 클럽',
		'마스터카드',
		'유니온페이',
		'아메리칸 익스프레스',
		'JCB',
		'VISA',
	];

	const bankLists = [
		{ value: '산업은행|002|2261', label: '산업은행' },
		{ value: '기업은행|003|2262', label: '기업은행' },
		{ value: '국민은행|004|2263', label: '국민은행' },
		{ value: '농협|011|2272', label: '농협' },
		{ value: '우리은행|020|2264', label: '우리은행' },
		{ value: 'SC은행|023|2273', label: 'SC은행' },
		{ value: '한국씨티은행|027|2265', label: '한국씨티은행' },
		{ value: '대구은행|031|2274', label: '대구은행' },
		{ value: '부산은행|032|2266', label: '부산은행' },
		{ value: '광주은행|034|2275', label: '광주은행' },
		{ value: '제주은행|035|2267', label: '제주은행' },
		{ value: '전북은행|037|2931', label: '전북은행' },
		{ value: '경남은행|039|2276', label: '경남은행' },
		{ value: '새마을금고|045|2268', label: '새마을금고' },
		{ value: '신협중앙회|048|2277', label: '신협중앙회' },
		{ value: '우체국|071|2269', label: '우체국' },
		{ value: 'KEB하나은행|081|2278', label: 'KEB하나은행' },
		{ value: '신한은행|088|2270', label: '신한은행' },
		{ value: '케이뱅크|089|2929', label: '케이뱅크' },
		{ value: '카카오뱅크|090|4030', label: '카카오뱅크' },
		{ value: '유안타증권|209|2279', label: '유안타증권' },
		{ value: '삼성증권|240|2271', label: '삼성증권' },
	];

	return (
		<>
			<PageWrapper title={'SignUp'} className={classNames('bg-info', 'main-bg')}>
				<Page>
					<div className='row h-100 align-items-center'>
						<div className='col-xl-6 col-lg-6 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardHeader className='bg-info text-white text-center'>
									<div className='d-flex justify-content-between w-100'>
										<h1 className='brand-title aside-logo' style={{width: 180, height: 60}}>
											&nbsp;
										</h1>
										<div>
											<p><b>{user.me?.name}</b> ({user.me?.userId})</p>
											<Button color='danger' icon="Logout" onClick={() => {
												UserService.logout().then(async () => {
													await Promise.all([
														removeItem('access-token'),
														removeItem('refresh-token'),
														removeItem('rootState'),
														removeItem('webToken'),
														removeItem('authUser'),
														user.logout(),
													]).then(() => {
														navigate(`/login`);
													});
												});
											}}>
												로그아웃
											</Button>
										</div>
									</div>
								</CardHeader>
								{!device.IMEI && (
								<CardHeader>
									<div className='d-flex justify-content-center w-100'>
										<div className='h5'>{t('IMEI 정보가 없습니다. QR코드를 촬영해주세요.')}</div>
									</div>
								</CardHeader>)}
								{device.IMEI && (
								<CardBody>
									<div className='text-center'>
										<div className='d-flex justify-content-between'>
											<div className='h5' style={{marginTop: 15}}>{t('설치 정보 작성')}</div>
										</div>
										<div className='d-flex justify-content-between'>
											<div className='h6'>IMEI: {device.IMEI}</div>
											<div className='h6'>S/N: {device.SerialNumber}</div>
										</div>
										<hr />
									</div>

									<form className='row' onSubmit={loginData.handleSubmit}>
										<div className='col-12'>
											<FormGroup className='mb-4'>
													{labelWithErrors('단말기 이름', loginData.errors.installName)}
												<InputGroup>
													<Input
														className='py-3'
															id='installName'
														type='text'
														placeholder='단말기 이름을 입력하세요.'
															value={loginData.values?.installName}
														size={8}
														max={8}
														onChange={(e) => {
															// loginData.handleChange
															loginData.setFieldValue('installName', e.target.value);
														}}
													/>
												</InputGroup>
											</FormGroup>
											<FormGroup className='mb-4'>
													{labelWithErrors('고객 이름', loginData.errors.customerName)}
												<InputGroup>
													<Input
														className='py-3'
															id='customerName'
														type='text'
														placeholder='고객 이름을 입력하세요.'
															value={loginData.values?.customerName}
														size={8}
														max={8}
														onChange={(e) => {
															// loginData.handleChange
															loginData.setFieldValue('customerName', e.target.value);
														}}
													/>
												</InputGroup>
											</FormGroup>
											<FormGroup className='mb-4'>
													{labelWithErrors('고객 주소', loginData.errors.customerAddress)}
												<InputGroup>
													<Input
														className='py-3'
															id='customerAddress'
														type='text'
														placeholder='주소검색'
															readOnly={!!loginData.values.customerAddress}
															value={loginData.values.customerAddress}
														onChange={loginData.handleChange}
														onClick={() => {
															if (loginData.values.customerAddress.length === 0) setIsOpenAddress(true);
														}}
													/>
													<Button
														type='button'
														color='info'
														onClick={() => {
															setIsOpenAddress(true);
														}}>
														{t('주소 검색')}
													</Button>
												</InputGroup>
												<InputGroup>
														<Input className='py-3' id='customerAddressDetail' type='text' placeholder='상세주소' value={loginData.values.customerAddressDetail} onChange={loginData.handleChange} />
												</InputGroup>
											</FormGroup>
												<FormGroup className='mb-4'>
													{labelWithErrors('고객 연락처', loginData.errors.customerPhone)}
													<InputGroup>
														<Input
															className='py-3'
															id='customerPhone'
															type='text'
															placeholder='고객 연락처를 입력하세요.'
															value={loginData.values?.customerPhone}
															size={8}
															max={8}
															onChange={(e) => {
																// loginData.handleChange
																loginData.setFieldValue('customerPhone', e.target.value);
															}}
														/>
													</InputGroup>
												</FormGroup>
												<FormGroup className='mb-4'>
													{labelWithErrors('고객 이메일', loginData.errors.customerEmail)}
													<InputGroup>
														<Input
															className='py-3'
															id='customerEmail'
															type='text'
															placeholder='고객 이메일을 입력하세요.'
															value={loginData.values?.customerEmail}
															size={8}
															max={8}
															onChange={(e) => {
																// loginData.handleChange
																loginData.setFieldValue('customerEmail', e.target.value);
															}}
														/>
													</InputGroup>
												</FormGroup>
												<FormGroup className='mb-4'>
													{labelWithErrors('수용가 번호', loginData.errors.acceptNumber)}
													<InputGroup>
														<Input
															className='py-3'
															id='acceptNumber'
															type='text'
															placeholder='수용가번호를 입력하세요.'
															value={loginData.values?.acceptNumber}
															size={8}
															max={8}
															onChange={(e) => {
																// loginData.handleChange
																loginData.setFieldValue('acceptNumber', e.target.value);
															}}
														/>
													</InputGroup>
												</FormGroup>
												<FormGroup className='mb-4'>
													{labelWithErrors('기존 계량기 이번달 검침 시작 값', loginData.errors.meterStartValue)}
													<InputGroup>
														<Input
															className='py-3'
															id='meterStartValue'
															type='text'
															placeholder='기존 계량기 이번달 검침 시작 값을 입력하세요.'
															value={loginData.values?.meterStartValue}
															size={8}
															max={8}
															onChange={(e) => {
																// loginData.handleChange
																loginData.setFieldValue('meterStartValue', e.target.value);
															}}
														/>
													</InputGroup>
												</FormGroup>
												<FormGroup className='mb-4'>
													{labelWithErrors('새로운 계량기의 시작값', loginData.errors.meterEndValue)}
													<InputGroup>
														<Input
															className='py-3'
															id='meterEndValue'
															type='text'
															placeholder='새로운 계량기의 시작값을 입력하세요.'
															value={loginData.values?.meterEndValue}
															size={8}
															max={8}
															onChange={(e) => {
																// loginData.handleChange
																loginData.setFieldValue('meterEndValue', e.target.value);
															}}
														/>
													</InputGroup>
												</FormGroup>

											<div className='my-5'>
												<Button className='w-100 mb-2 py-3' color='info' disabled={!loginData.isValid} type='submit' onClick={() => {}}>
													{t('설치 정보 저장')}
												</Button>
											</div>
										</div>
									</form>
								</CardBody>)}
							</Card>
						</div>
					</div>

					<Modal isOpen={openModal} setIsOpen={setOpenModal}>
						<ModalBody>
							<iframe width='100%' height={window.innerHeight - 200} frameBorder='0' src={ModalLink} />
						</ModalBody>
						<ModalFooter className='bg-info d-flex justify-content-center' isCentered>
							<Button
								className='text-white'
								size='lg'
								onClick={() => {
									setOpenModal(false);
								}}>
								{t('닫기')}
							</Button>
						</ModalFooter>
					</Modal>
					<Modal isOpen={isOpenAddress} fullScreen='xxl' size='xl' setIsOpen={setIsOpenAddress} isStaticBackdrop={true} isCentered={true}>
						<ModalHeader setIsOpen={setIsOpenAddress}></ModalHeader>
						<ModalBody>
							<DaumPostcode onComplete={addAddress} style={{ height: '50vh' }} />
						</ModalBody>
						<ModalFooter></ModalFooter>
					</Modal>
				</Page>
			</PageWrapper>
		</>
	);
};
export default SignUp;
