import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import { getItem, setItem } from '../../lib/localstorage';
import { useMst } from '../../models'

const Brand = ({ asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus }) => {
	const { user } = useMst()

	useEffect(() => {
		const asideStatusItem = getItem('asideStatus')
		const asideSubStatusItem = getItem('asideSubStatus')

		if (asideStatusItem) setAsideStatus(JSON.parse(asideStatusItem));
		if (asideSubStatusItem) setAsideSubStatus(JSON.parse(asideSubStatusItem));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='brand'>
			<div className='brand-logo'>
				{/* { user.type === 'SUPERADMIN' && */}
				<h1 className='brand-title aside-logo'>
					<Link to='/' aria-label='LogoAside' alt='ita'></Link>
				</h1>
				{/* }
				{ user.type !== 'SUPERADMIN' &&
				<h1 className='brand-title '>
					<Link to='/' aria-label='LogoAside' alt='ita'>{user?.nickname || user?.name}</Link>
				</h1>} */}
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => {
					setItem('asideStatus', (!asideStatus).toString())
					setItem('asideSubStatus', (!asideSubStatus).toString())
					setAsideStatus(!asideStatus);
					setAsideSubStatus(!asideSubStatus);
				}}>
				<Icon icon='FirstPage' color="light" className='brand-aside-toggle-close' />
				<Icon icon='LastPage' color="light" className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
