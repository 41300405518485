import React, { useEffect, useState } from "react";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button, { ButtonGroup } from "../../components/bootstrap/Button";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";
import showNotification from "../../components/extras/showNotification";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft } from "../../layout/SubHeader/SubHeader";
import Breadcrumb, { BreadcrumbItem } from "../../components/bootstrap/Breadcrumb";
import { useMst } from '../../models';

const ArSystemSetting = () => {
    const { t } = useTranslation(['translation', 'menu']);
    const { user } = useMst();

    const [storageDate, setStorageDate] = useState(1095)
    const [logoutTime, setLogoutTime] = useState(0)

    useEffect(() => {
        if (user.me.arday) {
            setLogoutTime(user.me.arday)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.me])


    const saveSystemSetting = () => {
        UserService.updateArSystemSetting({
            id: user.me.id, 
            arday: logoutTime
        })
    }

    return (
        <PageWrapper className='full' title={t('시스템 설정')}>
            <SubHeader className={'belt-header'}>
                <SubHeaderLeft className={'flex-wrap'}>
                    <Breadcrumb list={null} tag={'nav'}>
                        <BreadcrumbItem tag='nav' to={'/dashboard'}>
                            {t('시스템 설정')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </SubHeaderLeft>
            </SubHeader>
            <div style={{width: 300, textAlign: 'center'}}>
                <Card shadow='sm' borderSize={1} borderColor='light' className='rounded'>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{t('수집 데이터 보관 기간(일)')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <div className='row'>
                            <div className='col-sm-10'>
                                <FormGroup>
                                    <Input
                                        type={'text'}
                                        value={storageDate}
                                        placeholder={t('수집 데이터 보관 기간일을 입력해주세요')}
                                        onChange={(e) => setStorageDate(e.target.value)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                    <CardHeader borderSize={1} borderColor='light' className='rounded'>
                        <CardTitle>{`${t('검침 기준일 설정')} `}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <div className='row'>
                            <div className='col-sm-10'>
                                <FormGroup>
                                    <Input
                                        type={'text'}
                                        value={logoutTime}
                                        placeholder={t('검침 기준일을 입력해주세요')}
                                        onChange={(e) => setLogoutTime(e.target.value)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter borderSize={1} borderColor='light' className='rounded'>
                        <ButtonGroup>
                            <Button
                                type={'submit'}
                                color={'info'}
                                //isOutline={true}
                                // isDisable={!formik.isValid}
                                onClick={() => {
                                    saveSystemSetting()
                                    // if (!formik.isValid) {
                                    showNotification(t('시스템 설정'), t('시스템 설정 저장 하였습니다.'), 'success');
                                    // } else {
                                    //     formik.handleSubmit();
                                    // }
                                }}>
                                {t('저장')}
                            </Button>
                        </ButtonGroup>
                    </CardFooter>
                </Card>
            </div>
        </PageWrapper>
    )
}

export default React.memo(ArSystemSetting);