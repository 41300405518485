import { APIClient } from '../lib/apiHelper';

const { get, post, put } = new APIClient();

export default {
	getPacketList: (data) => post('/packet/list', data),
	getPacketList2: (data) => post('/packet/list2', data),
	getUseage: (data) => post('/packet/useage', data),
	getStData: (data) => post('/dashboard/stData', data),
};
