import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import Badge from '../components/bootstrap/Badge';
import Button, { ButtonGroup } from '../components/bootstrap/Button';
import Card, { CardBody } from '../components/bootstrap/Card';
import Collapse from '../components/bootstrap/Collapse';
import Input from '../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../components/bootstrap/forms/InputGroup';
import Select from '../components/bootstrap/forms/Select';
import { useMst } from '../models';
import showNotification from '../components/extras/showNotification';
import { getItem, removeItem, setItem } from '../lib/localstorage';
import { useLocation } from 'react-router-dom';

const LEVELS = [
	{ value: 'NR', label: '층간소음'},
	{ value: 'AR ER', label: '수도검침'},
	{ value: 'WR', label: '수질관리'},
	{ value: 'SR', label: '스마트팜'},
	{ value: 'XR', label: '미지정'},
];

const SAVE_FILTER = 'SAVE_FILTER';
const DELETE_FILTER = 'DELETE_FILTER';

const useFilter = ({ usingFilter = [], whatPage = '', defaultFilter = [] }) => {
	const location = useLocation();
	const whatPages = location.pathname;

	const { t } = useTranslation(['translation', 'menu']);
	const { company } = useMst();
	const [isFilterOpen, setIsOpenFilter] = useState(false);
	const [savedFilter, setSavedFilter] = useReducer((container, action) => {
		switch (action.type) {
			case SAVE_FILTER:
				// setItem(`${whatPage}_filter`, JSON.stringify(action.container));
				setItem(`${whatPages}_filter`, JSON.stringify(action.container));
				showNotification(t('필터'), t('현재 적용중인 필터가 저장되었습니다.'), 'success');
				return action.container;
			case DELETE_FILTER:
				// removeItem(`${whatPage}_filter`);
				removeItem(`${whatPages}_filter`);
				showNotification(t('필터'), t('저장된 필터가 삭제되었습니다.'), 'success');
				return null;
			default:
				return container;
		}
	}, JSON.parse(getItem(`${whatPages}_filter`)));
	const [filterButtonContainer, setFilterButtonContainer] = useState(savedFilter || defaultFilter);
	const [originData, setOriginData] = useState([]);
	const [filterData, setFilterData] = useState([]);

	const [groups, setGroups] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [ranks, setRanks] = useState([]);
	const [positions, setPositions] = useState([]);
	const [userEmploymentStatus, setUserEmploymentStatus] = useState([
		{ value: 'SUCCESS', label: t('재직') },
		{ value: 'LEAVE', label: t('퇴직') },
		// { value: 'REST', label: '휴직' },
	]);

	const [searchText, setSearchText] = useState('');
	const [searchGroupText, setSearchGroupText] = useState('');
	const [searchIDText, setSearchIDText] = useState('');
	const [searchIMEIText, setSearchIMEIText] = useState('');
	const [searchSNText, setSearchSNText] = useState('');
	const [searchCompanyText, setSearchCompanyText] = useState('');
	const [searchPartnerText, setSearchPartnerText] = useState('');
	
	const [selectedGroup, setSelectedGroup] = useState({});
	const [selectedDepartment, setSelectedDepartment] = useState({});
	const [selectedRank, setSelectedRank] = useState({});
	const [selectedPosition, setSelectedPosition] = useState({});
	const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState({});
	const [selectedLevels, setSelectedLevels] = useState({});

	//근무기록페이지 전용 state
	const [selectedWorkingLogTap, setSelectedWorkingLogTap] = useState('day');

	const applyFilter = useCallback(
		(userList, buttonList, pageName) => {
			let array = [...userList];

			if (buttonList.length > 0) {
				buttonList.map((ary) => {
					try {
						const filterValueInt = ary.value.map((v) => parseInt(v.filterValue)) || [];
						const filterValueString = ary.value.map((v) => v.filterValue) || [];
						// 공통된 필터 로직을 사용하면 해당 case if문의 배열내에 페이지 이름 추가
						switch (ary.key) {
							case 'IMEI':
								break
							case 'SerialNumber':
								break
							case 'OP_code':
								break
							case '아이디':
								break
							case '이름':
								if (['/work/log'].includes(pageName)) {
									// array = array.filter((u) => u[0]?.name.includes(filterValueString[0]));

									const filteredBooleanList = array.map((u) => filterValueString.map((name) => u[0]?.name.includes(name))).map((a) => a.includes(true));
									let nameFilteredUserList = [];
									for (let i = 0; i <= filteredBooleanList.length; i++) {
										if (filteredBooleanList[i] === true) {
											nameFilteredUserList.push(array[i]);
										}
									}
									array = nameFilteredUserList;
								} else if (array[0]?.method || array[0]?.email) {
									// console.log(filterValueString);
									console.log(array);

									// const filteredBooleanList = array.map((u) => filterValueString.map((name) => u?.name?.includes(name))).map((a) => a.includes(true));
									// // console.log(filteredBooleanList);
									// let nameFilteredUserList = [];
									// for (let i = 0; i <= filteredBooleanList.length; i++) {
									// 	if (filteredBooleanList[i] === true) {
									// 		nameFilteredUserList.push(array[i]);
									// 	}
									// }
									// array = nameFilteredUserList;
								} else {
									// const filteredBooleanList = array.map((u) => filterValueString.map((name) => u?.user?.name?.includes(name))).map((a) => a.includes(true));
									// let nameFilteredUserList = [];
									// for (let i = 0; i <= filteredBooleanList.length; i++) {
									// 	if (filteredBooleanList[i] === true) {
									// 		nameFilteredUserList.push(array[i]);
									// 	}
									// }
									// array = nameFilteredUserList;
								}
								break;
							case '추천인':
								if(array[0]?.partner?.name) {
									const filteredBooleanList = array.map((u) => filterValueString.map((name) => u?.partner?.name?.includes(name))).map((a) => a.includes(true));
									let nameFilteredUserList = [];
									for (let i = 0; i <= filteredBooleanList.length; i++) {
										if (filteredBooleanList[i] === true) {
											nameFilteredUserList.push(array[i]);
										}
									}
									array = nameFilteredUserList;
								} else {
									const filteredBooleanList = array.map((u) => filterValueString.map((name) => u?.user?.name?.includes(name))).map((a) => a.includes(true));
									let nameFilteredUserList = [];
									for (let i = 0; i <= filteredBooleanList.length; i++) {
										if (filteredBooleanList[i] === true) {
											nameFilteredUserList.push(array[i]);
										}
									}
									array = nameFilteredUserList;
								}
								break;
							case 'OP_Code':
								
								array = array.filter((u) => filterValueString.includes(u?.levels || '신청서 제출'));

								// const filteredBooleanList = array.map((u) => filterValueString.map((name) => u?.levels?.includes(name))).map((a) => a.includes(true));
								// let nameFilteredUserList = [];
								// for (let i = 0; i <= filteredBooleanList.length; i++) {
								// 	if (filteredBooleanList[i] === true) {
								// 		nameFilteredUserList.push(array[i]);
								// 	}
								// }
								// array = nameFilteredUserList;
								break;
							case '지점':
							case '지점명':
								// console.log('지점명 필터 적용', array[0]?.userCompanies);
								// console.log('지점명 필터 적용', filterValueString);
								if (array[0]?.userCompanies) {
									const filteredBooleanList = array
										.map((u) => filterValueString.map((name) => u?.userCompanies.map((uc) => uc?.company?.name?.includes(name)).includes(true)))
										.map((a) => a.includes(true));
									// console.log('지점명 필터 적용', filteredBooleanList);
									let nameFilteredUserList = [];
									for (let i = 0; i <= filteredBooleanList.length; i++) {
										if (filteredBooleanList[i] === true) {
											nameFilteredUserList.push(array[i]);
										}
									}
									array = nameFilteredUserList;
								} else {
									const filteredBooleanList = array.map((u) => filterValueString.map((name) => u?.company?.name?.includes(name))).map((a) => a.includes(true));
									let nameFilteredUserList = [];
									for (let i = 0; i <= filteredBooleanList.length; i++) {
										if (filteredBooleanList[i] === true) {
											nameFilteredUserList.push(array[i]);
										}
									}
									array = nameFilteredUserList;
								}
								break;
							case '직급':
								if (['/work/log'].includes(pageName)) {
									array = array.filter((u) => filterValueInt.includes(u[0]?.users?.rank?.id));
								}
								if (['/member/list'].includes(pageName)) {
									array = array.filter((u) => filterValueInt.includes(u?.rank?.id));
								}
								break;

							case '직책':
								if (['/member/list'].includes(pageName)) {
									array = array.filter((u) => filterValueInt.includes(u?.position?.id));
								}
								break;

							case '부서':
								if (['근무기록페이지'].includes(pageName)) {
									array = array.filter((u) => filterValueInt.includes(u[0]?.users?.department?.department?.id));
								} else {
									array = array.filter((u) => filterValueInt.findIndex((v) => u?.departments?.find((d) => d.departmentId === v && d.main)) > -1);
								}
								break;

							case '그룹':
								// if (['/member/list'].includes(pageName)) {
								// 	array = array.filter((u) => filterValueInt.includes(u?.group?.id));
								// }
								break;

							case '근무상태':
								if (['/member/list'].includes(pageName)) {
									array = array.filter((u) => filterValueString.includes(u.state));
								}
								break;

							case '지각자':
								if (['/work/log'].includes(pageName)) {
									array = array.filter((u) => u.some((log) => (log?.tardy === true ? true : false)));
								}
								break;

							case '퇴근 미체크자':
								if (['/work/log'].includes(pageName)) {
									// 출근 기록은 있지만 퇴근 기록이 없는 사람
									array = array.filter((u) => u.some((log) => (!log?.etimeReal && log?.stimeReal ? true : false)));
								}
								break;

							case '외부 출근자':
								if (['/work/log'].includes(pageName)) {
									array = array.filter((u) => u.some((log) => (log?.differentPlace ? (log?.differentPlace.some((difPla) => difPla === true) ? true : false) : false)));
								}
								break;
						}
					} catch (error) {
						//console.log('error =========', error);
					}
				});

				setFilterData(array);
			}
		},
		[setFilterData]
	);

	const filterListCheckNInsertNApply = useCallback(
		({ filterKeyword, filterValue, filterText, filterButtonContainer }) => {
			let tempFilterList = [...filterButtonContainer];
			const itemIndex = tempFilterList.findIndex((item) => item.key === filterKeyword);

			console.log(filterKeyword, filterValue);

			if (tempFilterList.length <= 0 || itemIndex === -1) {
				//비었거나, 같은 부류의 key가 아닐 경우 새로 생성

				let tempValue = [];
				if(filterKeyword === '현재단계') {
					tempFilterList.push({
						key: filterKeyword,
						value: [{ filterValue: filterValue, filterText: filterValue }],
					});
				} else {
					filterValue.split(' ').map((value) => {
						if (value === '') return;
						tempValue.push({ filterValue: value, filterText: value });
					});

					tempFilterList.push({
						key: filterKeyword,
						value: tempValue,
					});
				}
			}

			if (tempFilterList.length > 0 && itemIndex !== -1) {
				//이미 존재하는 key일 경우 해당 배열에 삽입

				if (filterKeyword === '이름' || filterKeyword === '지점명') {
					if (filterValue.length > 0) {
						filterValue.split(' ').map((value) => {
							if (value === '') return;
							const indexCheck = tempFilterList[itemIndex].value.findIndex((item) => item.filterValue === value);
							if (indexCheck > -1) return;
							if (indexCheck <= -1) {
								tempFilterList[itemIndex].value.push({
									filterValue: value,
									filterText: value,
								});
							}
						});
					}
				} else if (filterKeyword === '현재단계') {
					console.log('AAAAAAAAAAA')
					const indexCheck = tempFilterList[itemIndex].value.findIndex((item) => item.filterValue === filterValue);
					if (indexCheck > -1) return;
					if (indexCheck <= -1) {
						console.log('BBBBBBBBBBBBB')
						tempFilterList[itemIndex].value.push({
							filterValue: filterValue,
							filterText: filterValue,
						});
					}
				} else {
					const indexCheck = tempFilterList[itemIndex].value.findIndex((item) => item.filterValue === filterValue);
					if (indexCheck > -1) return;
					if (indexCheck <= -1) {
						filterValue.split(' ').map((value) => {
							if (value === '') return;
							tempFilterList[itemIndex].value.push({ filterValue: value, filterText: value });
						});

						// tempFilterList[itemIndex].value.push({
						// 	filterValue,
						// 	filterText,
						// });
					}
				}
			}

			console.log('tempFilterList', tempFilterList);

			setFilterButtonContainer(tempFilterList);
			applyFilter(originData, tempFilterList, whatPages);
		},
		[originData, applyFilter, whatPages]
	);

	const buttonClickHandler = useCallback(
		(keyword, value) => {
			const buttonIndex = filterButtonContainer.findIndex((button) => button.key === keyword);
			if (buttonIndex !== -1) {
				const filtered = [...filterButtonContainer];
				filtered.splice(buttonIndex, 1);
				setFilterButtonContainer(filtered);
			}
			if (buttonIndex === -1) {
				filterListCheckNInsertNApply({
					filterValue: value,
					filterKeyword: keyword,
					filterText: keyword,
					filterButtonContainer: filterButtonContainer,
				});
			}
		},
		[filterButtonContainer, filterListCheckNInsertNApply]
	);

	const isUsingFilter = useCallback(
		(filterName) => {
			if (usingFilter.length > 0) {
				return usingFilter.includes(filterName);
			}
		},
		[usingFilter]
	);

	const isActiveFilter = useCallback(
		(filterKey) => {
			return filterButtonContainer.filter((filter) => filter.key === filterKey).length > 0;
		},
		[filterButtonContainer]
	);

	const invokedNumOfFilter = () => {
		let n = 0;
		filterButtonContainer.map((filter) => (n += filter.value.length));
		return n;
	};

	const filterInputResetHandler = () => {
		setSearchText('');
		setSearchCompanyText('');
		setSearchPartnerText('');
		setSelectedGroup({});
		setSelectedDepartment({});
		setSelectedRank({});
		setSelectedPosition({});
		setSelectedEmploymentStatus({});
		setSelectedLevels({});
	};

	const filterResetHanlder = useCallback(() => {
		setFilterButtonContainer([]);
		filterInputResetHandler();
	}, []);

	useEffect(() => {

		// console.log('whatPages', whatPages);
		if(['/superadmin/exchange/list'].includes(whatPages)) {
			setFilterData(originData);
			return;
		} 

		if (filterButtonContainer.length > 0) {
			applyFilter(originData, filterButtonContainer, whatPages);
		} else {
			setFilterData(originData);
			filterInputResetHandler();
		}
	}, [originData, filterButtonContainer, applyFilter, whatPages]);

	useEffect(() => {
		if (filterButtonContainer.length > 0 && selectedWorkingLogTap !== 'day') {
			const filteredWorkingLogTap = filterButtonContainer.filter((button) => button.key !== '지각자' && button.key !== '퇴근 미체크자' && button.key !== '외부 출근자');
			setFilterButtonContainer(filteredWorkingLogTap);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedWorkingLogTap]);

	useEffectOnce(() => {
		const sortByName = (a, b) => {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		};
	});

	const renderFilterMenuButton = () => {
		return (
			<Button
				icon='FilterAlt'
				color='light'
				onClick={() => {
					setIsOpenFilter((prev) => !prev);
				}}>
				{t('필터')} <Badge>{invokedNumOfFilter()}</Badge>
			</Button>
		);
	};

	const renderFilterCollpase = () => {
		return (
			<Collapse className={'row col-lg-12 border-bottom border-light'} isOpen={isFilterOpen} style={{marginLeft: '0.1rem'}} >
				<Card stretch className={`custom-box-shadow rounded-2`} style={{ marginTop: 0}}>
					<CardBody className={`rounded-2 align-items-center text-left`} >
						<div className='col-lg-12 d-flex flex-row'>	
				{isUsingFilter('OP_code') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('OP Code')}</InputGroupText>
							<Select
								placeholder={t('OP Code 선택')}
								ariaLabel='Select OP Code'
								list={LEVELS}
								value={selectedLevels?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: 'OP_code',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedLevels(LEVELS[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)}
				{isUsingFilter('IMEI') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('IMEI')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('IMEI 검색')}
								value={searchIMEIText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchIMEIText,
											filterKeyword: 'IMEI',
											filterText: searchIMEIText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchIMEIText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchIMEIText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('SerialNumber') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('SERIAL')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('SERIAL 검색')}
								value={searchSNText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchSNText,
											filterKeyword: 'SerialNumber',
											filterText: searchSNText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchSNText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchSNText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				
				{isUsingFilter('아이디') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('아이디')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('아이디 검색')}
								value={searchIDText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchIDText,
											filterKeyword: '아이디',
											filterText: searchIDText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchIDText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchIDText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('이름') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('이름')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('이름 검색')}
								value={searchText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchText,
											filterKeyword: '이름',
											filterText: searchText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('그룹') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('그룹')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('그룹 검색')}
								value={searchGroupText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchGroupText,
											filterKeyword: '그룹',
											filterText: searchGroupText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchGroupText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchGroupText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('추천인') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('추천인')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('추천인 검색')}
								value={searchPartnerText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchPartnerText,
											filterKeyword: '추천인',
											filterText: searchPartnerText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchPartnerText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchPartnerText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('지점명') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('지점명')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('지점명 검색')}
								value={searchCompanyText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchCompanyText,
											filterKeyword: '지점명',
											filterText: searchCompanyText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchCompanyText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchCompanyText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('지점') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('지점')}</InputGroupText>
							<Input
								type='text'
								placeholder={t('지점 검색')}
								value={searchCompanyText}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										filterListCheckNInsertNApply({
											filterValue: searchCompanyText,
											filterKeyword: '지점',
											filterText: searchCompanyText,
											filterButtonContainer: filterButtonContainer,
										});
										setSearchCompanyText('');
									}
								}}
								onChange={(e) => {
									const removeGapValue = e.target.value; //.trim();
									setSearchCompanyText(removeGapValue);
									// filterListCheckNInsertNApply({
									// 	filterValue: removeGapValue,
									// 	filterKeyword: '이름',
									// 	filterText: removeGapValue,
									// 	filterButtonContainer: filterButtonContainer,
									// });
								}}
							/>
							{/* <Icon icon='filterAlt' /> */}
						</InputGroup>
					</div>
				)}
				{isUsingFilter('사용자') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('사용자')}</InputGroupText>
							<Select
								placeholder={t('사용자 선택')}
								ariaLabel='사용자 선택'
								list={LEVELS}
								value={selectedLevels?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: 'userId',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedLevels(LEVELS[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)}
				{/* {isUsingFilter('그룹') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('그룹')}</InputGroupText>
							<Select
								placeholder={t('그룹 선택')}
								ariaLabel='Select Departments'
								list={groups}
								value={selectedGroup?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: '그룹',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedGroup(groups[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)} */}
				{isUsingFilter('부서') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('부서')}</InputGroupText>
							<Select
								placeholder={t('부서 선택')}
								ariaLabel='Select Departments'
								list={departments}
								value={selectedDepartment?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: '부서',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedDepartment(departments[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)}
				{isUsingFilter('직급') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('직급')}</InputGroupText>
							<Select
								placeholder={t('직급 선택')}
								ariaLabel='Select Ranks'
								list={ranks}
								value={selectedRank?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: '직급',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedRank(ranks[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)}
				{isUsingFilter('직책') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('직책')}</InputGroupText>
							<Select
								placeholder={t('직책 선택')}
								ariaLabel='Select Positions'
								list={positions}
								value={selectedPosition?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: '직책',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedPosition(positions[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)}
				{isUsingFilter('근무상태') && (
					<div className='col-lg-2 p-1'>
						<InputGroup>
							<InputGroupText>{t('근무상태')}</InputGroupText>
							<Select
								placeholder={t('근무상태 선택')}
								ariaLabel='Select UserEmploymentStatus'
								list={userEmploymentStatus}
								value={selectedEmploymentStatus?.value || ''}
								onChange={(e) => {
									const selectedIndex = e.nativeEvent.target.options.selectedIndex;
									filterListCheckNInsertNApply({
										filterValue: e.target.value,
										filterKeyword: '근무상태',
										filterText: e.target[selectedIndex].text,
										filterButtonContainer: filterButtonContainer,
									});
									setSelectedEmploymentStatus(userEmploymentStatus[selectedIndex - 1]);
								}}
							/>
						</InputGroup>
					</div>
				)}
				{isUsingFilter('근무기록') && selectedWorkingLogTap === 'day' && (
					<div className='col-lg-4 p-1'>
						<Button className='me-2' color='danger' isOutline={!isActiveFilter('지각자')} isActive={isActiveFilter('지각자')} onClick={() => buttonClickHandler('지각자', '지각자')}>
							{t('지각자')}
						</Button>
						<Button
							className='me-2'
							color='warning'
							isOutline={!isActiveFilter('퇴근 미체크자')}
							isActive={isActiveFilter('퇴근 미체크자')}
							onClick={() => buttonClickHandler('퇴근 미체크자', '퇴근 미체크자')}>
							{t('퇴근 미체크자')}
						</Button>
						<Button
							className='me-2'
							color='primary'
							isOutline={!isActiveFilter('외부 출근자')}
							isActive={isActiveFilter('외부 출근자')}
							onClick={() => buttonClickHandler('외부 출근자', '외부 출근자')}>
							{t('외부 출근자')}
						</Button>
					</div>
				)}
				</div>
				<div>
					<Card className='d-flex p-0 m-1 border-0 shadow-none'>
						<CardBody className='px-4 py-2'>
							{filterButtonContainer &&
								filterButtonContainer.length > 0 &&
								filterButtonContainer.map((keyList, key1) => {
									return keyList.value.map((item, key2) => {
										return (
											item.filterText !== '' && (
												<Button
													className='m-1 fw-light'
													key={key2}
													type='button'
													size='sm'
													color='primary'
													icon='close'
													onClick={() => {
														let tempFilterList = [...filterButtonContainer];
														tempFilterList[key1].value.splice(key2, 1);

														switch (tempFilterList[key1].key) {
															case '이름':
																setSearchText('');
																break;
															case '부서':
																setSelectedDepartment({});
																break;
															case '그룹':
																setSelectedGroup({});
																break;
															case '직급':
																setSelectedRank({});
																break;
															case '직책':
																setSelectedPosition({});
																break;
															case '근무상태':
																setSelectedEmploymentStatus({});
																break;
														}

														if (tempFilterList[key1].value.length <= 0) {
															//객체내에 value가 비었으면, 해당 객체제거
															tempFilterList.splice(key1, 1);
														}
														setFilterButtonContainer(tempFilterList);
													}}>
													{keyList.key === item.filterText ? `${t(item.filterText)}` : `${t(keyList.key)} - ${t(item.filterText)}`}
												</Button>
											)
										);
									});
								})}
							{filterButtonContainer && filterButtonContainer.length > 0 && (
								<ButtonGroup className='m-2'>
									<Button type='button' color='light' onClick={filterResetHanlder}>
										{t('초기화')}
									</Button>
								</ButtonGroup>
							)}
							<ButtonGroup className='m-2'>
								<Button type='button' color='success' isOutline onClick={() => setSavedFilter({ type: SAVE_FILTER, container: filterButtonContainer })}>
									{t('필터 저장')}
								</Button>
								{savedFilter ? (
									<Button type='button' color='danger' isOutline onClick={() => setSavedFilter({ type: DELETE_FILTER })}>
										{t('필터 삭제')}
									</Button>
								) : (
									<></>
								)}
							</ButtonGroup>
						</CardBody>
					</Card>
				</div>
					</CardBody>
				</Card>
			</Collapse>
		);
	};

	return {
		groups,
		departments,
		ranks,
		positions,
		originData,
		filterData,
		filterButtonContainer,
		setOriginData,
		setFilterData,
		setSelectedWorkingLogTap,
		renderFilterMenuButton,
		renderFilterCollpase,
	};
};

export default useFilter;
