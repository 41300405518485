import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import CollectedDataList from './ArCollectedDataList';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import useFilter from '../../../hooks/useFilter';
import { object } from 'prop-types';
import Popovers from '../../../components/bootstrap/Popovers';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import DeviceService from '../../../services/DeviceService';
import ReactApexChart from 'react-apexcharts';
import zIndex from '@mui/material/styles/zIndex';
import Icon from '../../../components/icon/Icon';
import ArHeaderTop from '../arHeaderTop';
import moment from 'moment';
import * as XLSX from 'xlsx';

let isLoaded = [];
const ArCollectedData = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [params, setParams] = useState({});
	const [loading, setLoading] = useState(true);
	const [filterData, setFilterData] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const [chartSeries, setChartSeries] = useState([]);
	const [info, setInfo] = useState({});
	const [IMEI, setIMEI] = useState('');
	const [acceptNumber, setAcceptNumber] = useState('');
	const [selectedTab, setSelectedTab] = useState('GRAPH');
	const [totalWaterMeter, setTotalWaterMeter] = useState(0);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 100,
				fixedHeight: false,
			}),
		[]
	);

	const columns = useMemo(
		() => [
			// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
			{ grow: 1, minWidth: 200, name: '저장일자', key1: 'createdAt' },
			{ grow: 1, minWidth: 200, name: '수용가 명', key1: 'customerName' },
			{ grow: 1, minWidth: 200, name: '수용가 번호', key1: 'acceptNumber' },
			{ grow: 1, minWidth: 200, name: '주소', key1: 'customerAddress' },
			{ grow: 1, minWidth: 30, name: '계량기 번호', key1: 'IDENT_NR' },
			{ grow: 1, minWidth: 30, name: '계량기 구경(mm)', key1: 'Diameter' },
			{ grow: 1, minWidth: 30, name: '보고 주기', key1: 'AR_term' },
			{ grow: 1, minWidth: 30, name: '검침 주기', key1: 'SR_term' },
			{ grow: 1, minWidth: 30, name: '검침 시간', key1: 'Sensing_Time' },
			{ grow: 1, minWidth: 30, name: '검침값(m³)', key1: 'Water_Meter' },
			{ grow: 1, minWidth: 30, name: '요금(원)', key1: 'Price' },
			{ grow: 1, minWidth: 30, name: '검침값 이력(m³)', key1: 'Water_Meter' },
			{ grow: 1, minWidth: 30, name: '온도', key1: 'Temperature_In' },
			{ grow: 1, minWidth: 30, name: '습도', key1: 'Humidity_In' },
			{ grow: 1, minWidth: 30, name: '통신성공률', key1: 'E_RATE' },
			{ grow: 1, minWidth: 30, name: '기기 상태', key1: 'Error' },
			{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
			{ grow: 1, minWidth: 30, name: '탁도(NTU)', key1: 'NTU' },
			{ grow: 1, minWidth: 200, name: 'IMEI', key1: 'IMEI' },
			{ grow: 1, minWidth: 30, name: 'SERIAL', key1: 'SerialNumber' },
			{ grow: 1, minWidth: 30, name: 'RSSI', key1: 'RSSI' },
			{ grow: 1, minWidth: 30, name: 'RSRP', key1: 'RSRP' },
			{ grow: 1, minWidth: 30, name: 'RSRQ', key1: 'RSRQ' },
			{ grow: 1, minWidth: 30, name: 'SNR', key1: 'SNR' },
			{ grow: 2, minWidth: 30, name: '펌웨어 버전', key1: 'FW_Version' },
		],
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									if (['USER', 'GROUP'].indexOf(user.me.type) < 0) {
										setDetailData(rowData)
										setArrayModalOpen(true)
									}
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Price':
					data = rowData?.Water_Meter ?
						(
							// <Badge
							// 	color='danger'
							// 	onClick={() => {
							// 		// setDetailData(Array.isArray(columnData) ? columnData : [columnData])
							// 		// setArrayModalOpen(true)
							// 	}}>
								parseInt(rowData.Water_Meter[0] * 30) + '원'
							// </Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'MIC':
					data = columnData ?
						(
							<Badge
								color='success'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.install?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.install?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.install?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData || 'N/A';
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								// setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
		if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
			if (isLoaded.indexOf(filterData.length) < 0) {
				isLoaded.push(filterData.length);
				getData();
			}
		} else {
			// const keysToCheck = ['id', 'name']; // 체크할 키들
			// const isDuplicate = checkDuplicate(originData, keysToCheck);
			// console.log(isDuplicate); // true
		}

		setScrollLeft(e.scrollLeft)
	};

	const getData = useCallback(async (load) => {
		// console.log(filterButtonContainer);
		setLoading(true);
		if (load === 'init') {
			isLoaded = [];
			setChartData([]);
			setFilterData([]);
			setTotalCount(0);
			setFilterCount(0);
		}

		const { data } = await PacketService.getPacketList2(params)
		setChartData([...data.list])
		setFilterData(data.list.reverse())
		setTotalCount(data.totalCount)
		setFilterCount(data.filterCount)
		const total = data.list.length > 0 ? (data.list[0]?.Water_Meter[data.list[0]?.Water_Meter.length - 1] || 0) - (data.list[data.list.length - 1]?.Water_Meter[0] || 0) : 0

		setTotalWaterMeter(total.toFixed(3))
		setLoading(false);
	}, [params])

	const getInfo = useCallback(async () => {
		console.log('getInfo - IMEI', IMEI)
		const { data } = await DeviceService.getInfo({ IMEI })
		console.log(data);
		setInfo(data)
		setAcceptNumber(data.install?.acceptNumber || '')
	}, [IMEI])

	useEffect(() => {
		console.log('useEffect - IMEIs', IMEI)
		if (IMEI) getInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI])

	useEffect(() => {
		if (params.startDate && params.endDate && params.dateType && params.searchText !== 'all')
			getData('init');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	useEffect(() => {
		if (user.me.IMEI) setIMEI(user.me.IMEI)
		else {
			DeviceService.getDashboardDeviceList().then(({ data }) => {
				if (data.length > 0) {
					setIMEI(data[0].IMEI)
				}
			})
		}
	}, [user.me])

	const getDisplayedIndexes = (count) => {
		const totalDisplayed = 20;
		const interval = count / totalDisplayed;
		const indexes = [];

		for (let i = 0; i < totalDisplayed; i++) {
			const index = Math.floor(i * interval);
			indexes.push(index);
		}

		return indexes;
	}

	// console.log(filterData.map((d, i) => d.Water_Meter[0]))

	let options = {
		title: {
			enabled: true,
			text: '검침값 추이',
			align: 'left'
		},
		legend: {
			show: true
		},
		series: [{
			name: '검침값',
			data: chartData.length > 0 ? chartData.map((d, i) => d?.Water_Meter ? parseFloat(d?.Water_Meter[0]?.toFixed(3) || 0) : 0) : [],
		}],
		colors: [
			'#06c',
			'#5752D1',
			'#B8BBBE',
			'#C9190B',
			'#4CB140',
			'#009596',
			'#F4C145',
			'#EC7A08',
			'#7D1007',
			'#002F5D',
			'#23511E',
			'#003737',
			'#2A265F',
			'#C58C00',
			'#8F4700',
			'#2C0000',
			'#6A6E73',
		],
		labels: chartData.length > 0 ? params.dateType === 'MONTH' 
			? chartData.map((d, i) => moment(d.Sensing_Time).format('YYYY-MM-DD')) 
			: params.dateType === 'YEAR' ? chartData.map((d, i) => moment(d.Sensing_Time).format('YYYY-MM'))
				: chartData.map((d, i) => moment(d.Sensing_Time).format('YYYY-MM-DD HH:mm:ss'))
			: [],
		chart: {
			title: {
				text: '검침값 추이',
				align: 'left'
			},
			type: 'bar',
			height: 500,
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			},
			offsetX: -20
		},
		dataLabels: {
			enabled: true,
			enabledOnSeries: undefined,
			formatter: function (val, opts) {
				if (chartData.length < 23) {
					return [val, parseInt(parseFloat(val) * 30) + '원'];
				} else {
					if (getDisplayedIndexes(chartData.length).includes(opts.dataPointIndex)) {
						return [val,  parseInt(parseFloat(val) * 30 ) + '원'];
					} else {
						return ''
					}
				}
			},
			// textAnchor: 'middle',
			distributed: false,
			offsetX: 0,
			offsetY: -15,
			// 글자색

			style: {
				fontSize: '14px',
				fontFamily: 'Helvetica, Arial, sans-serif',
				// fontWeight: 'bold',
				colors: ['#fff', '#C9190B']
			},
			background: {
				enabled: true,
				foreColor: '#C9190B',
				padding: 7,
				borderRadius: 5,
				borderWidth: 1,
				borderColor: '#C9190B',
				opacity: 0.9,
				dropShadow: {
					enabled: true,
					top: 1,
					left: 1,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			dropShadow: {
				enabled: false,
				top: 1,
				left: 1,
				blur: 1,
				color: '#000',
				opacity: 0.45
			}
		},
		markers: {
			size: 0,
		},
		title: {
			text: '',
			align: 'center'
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			width: 0,
			dashArray: 0,
		},
		fill: {
			type: params.dateType === 'DAY' ? 'solid' : 'solid',
			gradient: params.dateType === 'DAY' ? {
				shade: 'dark',
				type: 'vertical',
				opacityFrom: 0.4,
				opacityTo: 1,
				colorStops: []
			} : {
					color: '#C9190B' // Replace with your solid color code
			}
		},
		// stroke: {
		// 	show: true,
		// 	lineCap: 'butt',
		// 	colors: undefined,
		// 	width: 1,
		// 	dashArray: 0,
		// },
		// fill: {
		//     type: 'gradient',
		//     gradient: {
		//         shadeIntensity: 1,
		//         // inverseColors: false,
		//         opacityFrom: 0.5,
		//         opacityTo: 0,
		//         stops: [0, 90, 100]
		//     },
		// },
		yaxis: {
			labels: {
				formatter: function (val) {
					return val;
				},
			},
			title: {
				text: '',
				offsetX: 32,
				offsetY: -222,
				rotate: 0,
			},
		},
		xaxis: {
			labels: {
				formatter: function (val, i) {
					return val;
				},
			},
			title: {
				text: ''
			},
		},
		grid: {
			borderColor: '#e7e7e7',
			row: {
				colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
				opacity: 0.5
			},
		},
		tooltip: {
			shared: false,
			y: {
				formatter: function (val) {
					return val + ' m<sup>3</sup>'
				}
			}
		}
	};

	// console.log(params)
	const excelDownload = async () => {
		let tempData = []
		let data = []

		let count = 0;
		let customerName = ''
		do {
			params.type = 'DATA'
			params.page = Math.floor(tempData.length / 30) + 1
			const response = await PacketService.getPacketList2({ ...params, excel: true })
			data = response.data?.list?.reverse() || []
			if (count === 0) count = response.data?.totalCount || 0

			tempData = tempData.concat(data)
			user.setProgress(Math.floor((tempData.length / count) * 100))
		} while (data.length > 0 && tempData.length < count)

		const wb = XLSX.utils.book_new();

		let temp = []
		let dayFormat = 'YYYY-MM-DD'
		let monthFormat = 'YYYY-MM'
		let yearFormat = 'YYYY'

		let day = {}
		let month = {}
		let year = {}

		tempData.map((x) => {
			let dayKey = moment(x.Sensing_Time).format(dayFormat)
			let monthKey = moment(x.Sensing_Time).format(monthFormat)
			let yearKey = moment(x.Sensing_Time).format(yearFormat)
			customerName = x.install?.customerName || ''

			if (!day[dayKey + '_' + x.install?.acceptNumber]) {
				day[dayKey + '_' + x.install?.acceptNumber] = {
					'일자': dayKey,
					'수용가명': x.install?.customerName || '',
					'수용가번호': x.install?.acceptNumber || '',
					'계량기번호': x?.IDENT_NR || '',
					'총 사용량': x?.Water_Meter[0] || '',
					'통신성공률': x?.E_RATE || '',
					'IMEI': x?.IMEI || '',
					'SerialNumber': x?.SerialNumber || '',
					'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
					'계량기구경': x?.Diameter || '',
					'보고주기': x?.AR_term || '',
					'검침주기': x?.SR_term || '',
					'온도': x?.Temperature_In || '',
					'습도': x?.Humidity_In || '',
					'기기상태': x?.Status || '',
					'배터리': x?.BAT || '',
					'탁도': x?.NTU || '',
				}
			}

			if (!month[monthKey + '_' + x.install?.acceptNumber]) {
				month[monthKey + '_' + x.install?.acceptNumber] = {
					'연도': monthKey.split('-')[0],
					'월': monthKey.split('-')[1],
					'수용가명': x.install?.customerName || '',
					'수용가번호': x.install?.acceptNumber || '',
					'계량기번호': x?.IDENT_NR || '',
					'총 사용량': x?.Water_Meter[0] || '',
					'통신성공률': x?.E_RATE || '',
					'IMEI': x?.IMEI || '',
					'SerialNumber': x?.SerialNumber || '',
					'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
					'계량기구경': x?.Diameter || '',
					'보고주기': x?.AR_term || '',
					'검침주기': x?.SR_term || '',
					'온도': x?.Temperature_In || '',
					'습도': x?.Humidity_In || '',
					'기기상태': x?.Status || '',
					'배터리': x?.BAT || '',
					'탁도': x?.NTU || '',
				}
			}

			if (!year[yearKey + '_' + x.install?.acceptNumber]) {
				year[yearKey + '_' + x.install?.acceptNumber] = {
					'연도': yearKey,
					'수용가명': x.install?.customerName || '',
					'수용가번호': x.install?.acceptNumber || '',
					'계량기번호': x?.IDENT_NR || '',
					'총 사용량': x?.Water_Meter[0] || '',
					'통신성공률': x?.E_RATE || '',
					'IMEI': x?.IMEI || '',
					'SerialNumber': x?.SerialNumber || '',
					'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
					'계량기구경': x?.Diameter || '',
					'보고주기': x?.AR_term || '',
					'검침주기': x?.SR_term || '',
					'온도': x?.Temperature_In || '',
					'습도': x?.Humidity_In || '',
					'기기상태': x?.Status || '',
					'배터리': x?.BAT || '',
					'탁도': x?.NTU || '',
				}
			}

			day[dayKey + '_' + x.install?.acceptNumber]['총 사용량'] += x.Water_Meter[0]
			month[monthKey + '_' + x.install?.acceptNumber]['총 사용량'] += x.Water_Meter[0]
			year[yearKey + '_' + x.install?.acceptNumber]['총 사용량'] += x.Water_Meter[0]

			temp.push({
				'일자': moment(x.Sensing_Time).format('YYYY-MM-DD HH:mm:ss'),
				'수용가명': x.install?.customerName || '',
				'수용가번호': x.install?.acceptNumber || '',
				'계량기번호': x?.IDENT_NR || '',
				'총 사용량': x?.Water_Meter[0] || '',
				'통신성공률': x?.E_RATE || '',
				'IMEI': x?.IMEI || '',
				'SerialNumber': x?.SerialNumber || '',
				'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
				'계량기구경': x?.Diameter || '',
				'보고주기': x?.AR_term || '',
				'검침주기': x?.SR_term || '',
				'온도': x?.Temperature_In || '',
				'습도': x?.Humidity_In || '',
				'기기상태': x?.Status || '',
				'배터리': x?.BAT || '',
				'탁도': x?.NTU || '',
			})
		})

		const ws = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws, '일자별');

		temp = []
		Object.keys(month).map((key) => {
			temp.push(month[key])
		})

		const ws2 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws2, '월별');

		temp = []
		Object.keys(year).map((key) => {
			temp.push(year[key])
		})

		const ws3 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws3, '연도별');

		XLSX.writeFile(wb, `${customerName} 데이터수집 ${moment(params.startDate).format('YYYY년 MM월 DD일')}~${moment(params.endDate).format('YYYY년 MM월 DD일')}_${moment().format('YYYY년 MM월 DD일')}.xlsx`);
	}

	return (
		<PageWrapper className='full' title={t('수용가별 모니터링')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={'/arcollectedData'}>
							{t('수용가별 모니터링')}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid' style={{ paddingTop: '0.7rem' }} >
				<Card stretch className={`custom-box-shadow rounded-2 p-0`} >
					<CardBody className={`rounded-2 align-items-center text-center p-3 py-1`} >
						<ArHeaderTop setParams={setParams}
						disableSecond excelDownload={excelDownload}
						acceptNumber={acceptNumber} 
						 disableExcelUp disableArea disableDeviceStatus markers={[info]} />
					</CardBody>
				</Card>
				<div className='row'>
					<div className='col-xl-2 col-lg-2 col-md-2 text-center' style={{padding: 10, display: 'flex', flexDirection: 'column', marginTop: -10 }}>
						
						<Card className={`custom-box-shadow rounded-2`}
							style={{ border: '1px solid #5d87ff' }}
							onClick={(e) => {
							}}
						>
							<CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '1rem', backgroundColor: '#fff', color: '#000' }}>
								<div className='d-flex flex-row justify-content-between'>
									<div><CardTitle style={{ color: '#294faa', fontSize: '1.5rem', marginTop: 0 }}>{'수용가 정보'}</CardTitle></div>
									{/* <div>
										<Icon icon='Gear-Fill' fontSize={20} forceFamily='bootstrap'
											onClick={(e) => {
											}}
										/>
										<Icon icon='Close' fontSize={25} onClick={(e) => {
										}} style={{ cursor: 'pointer' }} />
									</div> */}
								</div>
								<hr />
								<div className='w-100 text-left' style={{ marginTop: 0 }}>
									<div className='d-flex flex-row justify-content-between mt-3'>
										<div style={{ fontSize: '1.0rem' }}>계량기번호: <b>{info?.packets ? info?.packets[0]?.IDENT_NR : ''}</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>수용가번호: <b>{info?.install?.acceptNumber || ''}</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>수용가명: <b>{info?.install?.customerName || ''}</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>주소: <b>{info?.install?.customerAddress || ''}</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>상세주소: <b>{info?.install?.customerAddressDetail || ''}</b></div>
									</div>
								</div>
							</CardBody>
						</Card>
						<Card className={`custom-box-shadow rounded-2`}
							style={{ border: '1px solid #fa896b' }}
							onClick={(e) => {
							}}
						>
							<CardBody className={`rounded-2 align-items-center text-center`} style={{ padding: '1rem', backgroundColor: '#fff', color: '#000' }}>
								<div className='d-flex flex-row justify-content-between'>
									<div><CardTitle style={{ color: '#fa896b', fontSize: '1.5rem', marginTop: 0 }}>{'기기 정보'}</CardTitle></div>
									{/* <div>
										<Icon icon='Gear-Fill' fontSize={20} forceFamily='bootstrap'
											onClick={(e) => {
											}}
										/>
										<Icon icon='Close' fontSize={25} onClick={(e) => {
										}} style={{ cursor: 'pointer' }} />
									</div> */}
								</div>
								<hr />
								<div className='w-100 text-left' style={{ marginTop: 0 }}>
									<div className='d-flex flex-row justify-content-between mt-3'>
										<div style={{ fontSize: '1.0rem' }}>기기 상태: <b>{info?.ErrorStatus?.map(e => e.label).join(',')}</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>온도: <b>{info?.packets ? info?.packets[0]?.Temperature_In : ''}℃</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>습도: <b>{info?.packets ? info?.packets[0]?.Humidity_In : ''}%</b></div>
									</div>
									<div className='d-flex flex-row justify-content-between mt-2'>
										<div style={{ fontSize: '1.0rem' }}>배터리: <b>{info?.packets ? info?.packets[0]?.BAT : ''}%</b></div>
									</div>
								</div>
							</CardBody>
						</Card>
						
					</div>
					<div className='col-xl-10 col-lg-10 col-md-10 text-center' >
						<Card stretch className={`custom-box-shadow rounded-2 p-0`} style={{ height: 'calc(90vh - 70px)' }}>
							<CardBody className={`rounded-2 align-items-center text-center p-3 py-1`} >
								<div className='d-flex flex-row justify-content-between'>
									<div>&nbsp;</div>
									<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #294faa' }}>
										<CardBody className={`rounded-2 align-items-center text-center`} >
											<CardTitle style={{ color: '#294faa', fontSize: '1.5rem' }}>기간 내 검침값: <span style={{ color: 'blue' }}>{totalWaterMeter}</span> (m³)</CardTitle>
										</CardBody>
									</Card>
									<Button color='info'
										icon={selectedTab === 'GRAPH' ? 'Description' : 'BarChart'}
										size='lg'
										style={{ marginTop: 5 }}
										onClick={() => {
											setSelectedTab(selectedTab === 'GRAPH' ? 'DATA' : 'GRAPH')
										}}>
										{''}
									</Button>
								</div>
								<div className='d-flex flex-column' style={{ flex: 1, padding: 10, height: '90%' }}>
									{(selectedTab === 'DATA' && filterData?.length > 0) && (
										<AutoSizer
											onResize={() => {
												listRef.current.recomputeGridSize();
											}}>
											{({ width, height }) => {
												// console.log(width, height);
												return (
													<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width}>
														{({ adjustedWidth, getColumnWidth, registerChild }) => (
															<div className=''>
																<MultiGrid
																	ref={listRef}
																	// deferredMeasurementCache={cellCache}
																	cellRenderer={cellRenderer}
																	classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
																	classNameTopRightGrid='border-light border-bottom fw-bold'
																	classNameBottomLeftGrid='border-light border-end'
																	// classNameBottomRightGrid="border border-0 border-light border-end-1"
																	fixedColumnCount={3}
																	fixedRowCount={1}
																	height={height}
																	width={width}
																	overscanRowCount={5}
																	overscanColumnCount={5}
																	// columnCount={Object.keys(data[0]).length}
																	columnCount={columns.length}
																	columnWidth={cellCache.columnWidth}
																	rowCount={filterData.length + 1}
																	rowHeight={cellCache.rowHeight}
																	// isScrolling={isScrolling}
																	onScroll={onScrollHandler}
																	scrollLeft={scrollLeft}
																/>
															</div>
														)}
													</ColumnSizer>
												);
											}}
										</AutoSizer>
									)}
									{(selectedTab === 'GRAPH') && (
										<ReactApexChart
											style={{ flex: 1, paddingLeft: 70 }}
											width={'100%'}
											height={'90%'}
											options={options}
											series={options.series}
											type={params.dateType === 'DAY' ? 'area' : 'bar'}
										/>
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default memo(ArCollectedData);
