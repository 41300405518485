import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import moment from 'moment';
import * as XLSX from 'xlsx';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import useFilter from '../../../hooks/useFilter';
import Progress from '../../../components/bootstrap/Progress';
import { useNavigate } from 'react-router-dom';

let isLoaded = [];
let prevPage = -1
const NrCollectedData = () => {
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [loading, setLoading] = useState(true);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const { originData, filterData, filterButtonContainer, setOriginData, renderFilterCollpase, renderFilterMenuButton } = useFilter({
		usingFilter: ['USER', 'GROUP'].indexOf(user.me.type) > -1 ? ['IMEI'] : ['IMEI', 'OP_code', '이름'],
		whatPage: '수집 데이터',
		defaultFilter: [],
	});
	const [refresh, setRefresh] = useState(false);
	const [progress, setProgress] = useState(100);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 100,
				fixedHeight: false,
			}),
		[]
	);

	const columns = useMemo(
		() => [
			// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
			{ grow: 1, minWidth: 200, name: '저장일자', key1: 'createdAt' },
			{ grow: 1, minWidth: 200, name: 'IMEI', key1: 'IMEI' },
			{ grow: 1, minWidth: 200, name: '별칭', key1: 'installName' },
			{ grow: 1, minWidth: 200, name: '주소', key1: 'customerAddress' },
			{ grow: 1, minWidth: 200, name: '상세주소', key1: 'customerAddressDetail' },
			{ grow: 1, minWidth: 30, name: '측정시간', key1: 'Sensing_Time' },
			{ grow: 1, minWidth: 30, name: 'MIC', key1: 'MIC' },
			{ grow: 1, minWidth: 30, name: 'X', key1: 'X' },
			{ grow: 1, minWidth: 30, name: 'Y', key1: 'Y' },
			{ grow: 1, minWidth: 30, name: 'Z', key1: 'Z' },
			{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
		],
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'BAT':
					data = parseInt(parseFloat(columnData) * 100)
					break
				case 'X':
					data = <Badge color='danger' >
							{rowData.XYZ[0] ? parseInt(parseFloat(rowData.XYZ[0]?.split(',')[0]) * 100) / 100 : 'N/A'}
						</Badge>
					break;
				case 'Y':
					data = <Badge color='info' >
							{rowData.XYZ[0] ? parseInt(parseFloat(rowData.XYZ[0]?.split(',')[1]) * 100) / 100 : 'N/A'}
						</Badge>
					break;
				case 'Z':
					data = <Badge color='success' >
							{rowData.XYZ[0] ? parseInt(parseFloat(rowData.XYZ[0]?.split(',')[2]) * 100) / 100 : 'N/A'}
						</Badge>
					break;
				case 'installName':
					data = rowData?.install?.installName || '별칭없음';
					break;
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									user.setIMEI(columnData);
									navigate('/nrdashboard', { state: { viewTab: 'STATISTICS' } });
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'MIC':
					data = columnData ?
						(
							<b>{columnData[0] || columnData}</b>
						) : 'N/A'
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.install?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.install?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.install?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData || 'N/A';
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								// setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
		console.log(loading, e.scrollTop, e.clientHeight, e.scrollHeight, totalCount, filterData.length, isLoaded)
		if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
			if (isLoaded.indexOf(filterData.length) < 0) {
				console.log('getData')
				isLoaded.push(filterData.length);
				getData();
			}
		} else {
			// const keysToCheck = ['id', 'name']; // 체크할 키들
			// const isDuplicate = checkDuplicate(originData, keysToCheck);
			// console.log(isDuplicate); // true
		}

		setScrollLeft(e.scrollLeft)
	};

	const getData = async (load) => {
		// console.log(filterButtonContainer);
		setLoading(true);

		if (load === 'init') isLoaded = [];
		let where = {
			currentPage: load === 'init' ? 0 : originData.length,
			filter: filterButtonContainer,
			// sort: sortColumn,
			// order: sortDirection ? 'ASC' : 'DESC',
		}

		const resUserList = await PacketService.getPacketList(where)
			.then((res) => res?.data || {
				totalCount: 0,
				filterCount: 0,
				list: []
			})
			.catch((error) => {
				// showNotification(t('직원 목록'), t('직원 목록을 불러오는 도중에 문제가 발생했습니다.'), 'danger')
			});

		setTotalCount(resUserList.totalCount);
		setFilterCount(resUserList.filterCount);
		setOriginData((prev) => [...prev, ...resUserList.list]);

		setLoading(false);
	};

	useEffect(() => {
		setOriginData([]);
		getData('init');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterButtonContainer]);


	useEffect(() => {
		setOriginData([]);
		getData('init');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const excelDownload = async () => {
		const wb = XLSX.utils.book_new();

		let temp = []
		filterData.map((x) => {
			setProgress((prev) => prev + 100 / filterData.length);

			temp.push({
				'저장일자': moment(x.createdAt).format('YYYY-MM-DD HH:mm:ss'),
				'IMEI': x?.IMEI || '',
				'별칭': x.install?.installName || '별칭없음',
				'주소': (x?.customerAddress || ''),
				'상세주소': (x?.customerAddressDetail || ''),
				'측정시간': moment(x.Sensing_Time).format('YYYY-MM-DD HH:mm:ss'),
				'MIC': x.MIC[0] || '',
				'X': x.XYZ[0] ? parseInt(parseFloat(x.XYZ[0]?.split(',')[0]) * 100) / 100 : 'N/A',
				'Y': x.XYZ[0] ? parseInt(parseFloat(x.XYZ[0]?.split(',')[1]) * 100) / 100 : 'N/A',
				'Z': x.XYZ[0] ? parseInt(parseFloat(x.XYZ[0]?.split(',')[2]) * 100) / 100 : 'N/A',
				'배터리': parseInt(parseFloat(x.BAT) * 100),
			})
		})

		const ws = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws, '데이터');

		XLSX.writeFile(wb, `데이터수집 ${moment(filterData[filterData.length - 1].Sensing_Time).format('YYYY년 MM월 DD일')}~${moment(filterData[filterData.length - 1].Sensing_Time).format('YYYY년 MM월 DD일')}_${moment().format('YYYY년 MM월 DD일')}.xlsx`);
	}

	return (
		<PageWrapper className='fluid' title={t('수집 데이터')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft className={'flex-wrap'}>
					<span className='ms-2 title'>{`${t('총')} : ${totalCount || 0} ${t('건')} / ${t('필터된 건수')} : ${filterCount || 0} ${t('건')}`}</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button shadow='sm' isDisable={progress < 100} isOutline={progress < 100} color='success' icon='Download' onClick={excelDownload}>
						<Icon icon='FileEarmarkExcelFill' />
						{progress < 100 && <Progress style={{ width: 60 }}>
							<Progress
								style={{ width: 100 - progress + '%' }}
								isAnimated={true}
								color='success'
								value={
									progress
								}
							/>
							<Progress
								style={{ width: progress + '%' }}
								isAnimated={true}
								color='light'
								value={
									100 - progress
								}
							/>
							<div style={{
								color: '#46bcaa'
							}}><b>{progress}%</b></div>
						</Progress>}
					</Button>
					{renderFilterMenuButton()}
					<Button
						icon='Refresh'
						isDisable={loading}
						type={'button'}
						color={'warning'}
						onClick={() => {
							setOriginData([]);
							getData('init');
						}}>
						{`${t('새로고침')}`}
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid' style={{ marginTop: 0 }} >
				{renderFilterCollpase()}
				<Card stretch className={`custom-box-shadow rounded-2`} >
					<CardBody className={`rounded-2 align-items-center text-center p-0`} >
						{filterData?.length > 0 && (
							<AutoSizer
								onResize={() => {
									listRef.current.recomputeGridSize();
								}}>
								{({ width, height }) => {
									// console.log(width, height);
									return (
										<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width}>
											{({ adjustedWidth, getColumnWidth, registerChild }) => (
												<div className=''>
													<MultiGrid
														ref={listRef}
														// deferredMeasurementCache={cellCache}
														cellRenderer={cellRenderer}
														classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
														classNameTopRightGrid='border-light border-bottom fw-bold'
														classNameBottomLeftGrid='border-light border-end'
														// classNameBottomRightGrid="border border-0 border-light border-end-1"
														fixedColumnCount={3}
														fixedRowCount={1}
														height={height}
														width={width}
														overscanRowCount={5}
														overscanColumnCount={5}
														// columnCount={Object.keys(data[0]).length}
														columnCount={columns.length}
														columnWidth={cellCache.columnWidth}
														rowCount={filterData.length + 1}
														rowHeight={cellCache.rowHeight}
														// isScrolling={isScrolling}
														onScroll={onScrollHandler}
														scrollLeft={scrollLeft}
													/>
												</div>
											)}
										</ColumnSizer>
									);
								}}
							</AutoSizer>
						)}

					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default memo(NrCollectedData);
