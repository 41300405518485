import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import CollectedDataList from './ArCollectedDataList';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import useFilter from '../../../hooks/useFilter';
import { object } from 'prop-types';
import Popovers from '../../../components/bootstrap/Popovers';
import Card, { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import ArHeaderTop from '../arHeaderTop';
import moment from 'moment';
import * as XLSX from 'xlsx';

let isLoaded = [];
const ArUsage = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [params, setParams] = useState({});
	const [loading, setLoading] = useState(true);
	const [filterData, setFilterData] = useState([]);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 150,
				fixedHeight: false,
			}),
		[]
	);

	const columns = useMemo(
		() => [
			// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
			{ grow: 1, minWidth: 200, name: '수용가 번호', key1: 'acceptNumber' },
			{ grow: 1, minWidth: 200, name: '수용가 명', key1: 'customerName' },
			{ grow: 1, minWidth: 200, name: '주소', key1: 'customerAddress' },
			{ grow: 1, minWidth: 200, name: '이전 계량기 누적적산 종료값', key1: 'AmeterStartValue' },
			{ grow: 1, minWidth: 30, name: '누적적산 시작값', key1: 'BmeterEndValue' },
			{ grow: 1, minWidth: 30, name: '계량기 시작값', key1: 'Cmeter' },
			{ grow: 1, minWidth: 30, name: '계량기 종료값', key1: 'Dmeter' },
			{ grow: 1, minWidth: 30, name: '누적적산 종료값', key1: 'Emeter' },
			{ grow: 1, minWidth: 30, name: '한달 사용량', key1: 'Fmeter' },
			{ grow: 1, minWidth: 30, name: '누적적산 값', key1: 'Gmeter' },
		],
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									if (['USER', 'GROUP'].indexOf(user.me.type) < 0) {
										setDetailData(rowData)
										setArrayModalOpen(true)
									}
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'MIC':
					data = columnData ?
						(
							<Badge
								color='success'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData;
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								// setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
		if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
			if (isLoaded.indexOf(filterData.length) < 0) {
				isLoaded.push(filterData.length);
				getData();
			}
		} else {
			// const keysToCheck = ['id', 'name']; // 체크할 키들
			// const isDuplicate = checkDuplicate(originData, keysToCheck);
			// console.log(isDuplicate); // true
		}

		setScrollLeft(e.scrollLeft)
	};

	const getData = useCallback(async (load) => {
		// console.log(filterButtonContainer);
		setLoading(true);
		if (load === 'init') {
			isLoaded = [];
			setFilterData([]);
		}

		params.startDate = moment(params.startDate).format('YYYY-MM-DD 00:00:00');
		params.endDate = moment(params.endDate).format('YYYY-MM-DD 23:59:59');

		const { data } = await PacketService.getUseage(params)
		// console.log(data);

		setFilterData([...data])
		// setTotalCount(data.totalCount)
		// setFilterCount(data.filterCount)

		setLoading(false);
	}, [params])

	useEffect(() => {
		if (params.startDate && params.endDate) getData('init');
		console.log('params', params);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const excelDownload = async () => {
		let tempData = []
		let data = []

		let customerName = ''
		const response = await PacketService.getUseage({ ...params, excel: true })
		data = response?.data || []
		tempData = tempData.concat(data)

		const wb = XLSX.utils.book_new();

		let temp = []
		tempData.map((x) => {
			console.log(x);
			temp.push({
				'수용가번호': x?.acceptNumber || '',
				'수용가명': x?.customerName || '',
				'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
				'이전 계량기 누적적산 종료값': x.AmeterStartValue,
				'누적적산 시작값': x.BmeterEndValue,
				'계량기 시작값': x.Cmeter,
				'계량기 종료값': x.Dmeter,
				'누적적산 종료값': x.Emetser,
				'한달 사용량': x.Fmeter,
				'누적적산 값': x.Gmeter,
			})
		})

		const ws = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws, '누적 사용량');

		XLSX.writeFile(wb, `누적 사용량 조회 ${moment(params.startDate).format('YYYY년 MM월 DD일')}~${moment(params.endDate).format('YYYY년 MM월 DD일')}_${moment().format('YYYY년 MM월 DD일')}.xlsx`);
	}

	return (
		<PageWrapper className='fluid' title={t('수집 데이터')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={'/arcollectedData'}>
							{t('수용가별 모니터링')}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid' style={{ paddingTop: '0.7rem' }} >
				<Card stretch className={`custom-box-shadow rounded-2 p-0`} >
					<CardBody className={`rounded-2 align-items-center text-center p-3 py-1`} >
						<ArHeaderTop setParams={setParams} disableMap disableArea disableDeviceStatus disableSecond
							excelDownload={excelDownload}
							disableExcelUp durationType='Monthly' totalText={`전체 데이터: ${filterCount}/${totalCount}`} />
					</CardBody>
				</Card>
				<div className='row'>
					<Card stretch className={`custom-box-shadow rounded-2 p-0`} style={{ height: 'calc(90vh - 70px)' }}>
						<CardBody className={`rounded-2 align-items-center text-center p-3 py-1`} >
							<div className='d-flex flex-row justify-content-between'>
								{/* <CardTitle style={{ fontSize: '1.5rem', marginTop: 15 }}>기간 내 검침값: <span style={{ color: 'blue' }}>{totalWaterMeter}</span> (m³)</CardTitle> */}
							</div>
							<div className='d-flex flex-column' style={{ flex: 1, padding: 10, height: '95%' }}>
								{filterData?.length > 0 && (
									<AutoSizer
										onResize={() => {
											listRef.current.recomputeGridSize();
										}}>
										{({ width, height }) => {
											// console.log(width, height);
											return (
												<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width}>
													{({ adjustedWidth, getColumnWidth, registerChild }) => (
														<div className=''>
															<MultiGrid
																ref={listRef}
																// deferredMeasurementCache={cellCache}
																cellRenderer={cellRenderer}
																classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
																classNameTopRightGrid='border-light border-bottom fw-bold'
																classNameBottomLeftGrid='border-light border-end'
																// classNameBottomRightGrid="border border-0 border-light border-end-1"
																fixedColumnCount={2}
																fixedRowCount={1}
																height={height}
																width={width}
																overscanRowCount={5}
																overscanColumnCount={5}
																// columnCount={Object.keys(data[0]).length}
																columnCount={columns.length}
																columnWidth={cellCache.columnWidth}
																rowCount={filterData.length + 1}
																rowHeight={cellCache.rowHeight}
																// isScrolling={isScrolling}
																onScroll={onScrollHandler}
																scrollLeft={scrollLeft}
															/>
														</div>
													)}
												</ColumnSizer>
											);
										}}
									</AutoSizer>
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default memo(ArUsage);
