import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { observer } from 'mobx-react';
import ReactApexChart from 'react-apexcharts';

const XyzChartPage = observer(() => {
    const [sensingTime, setSensingTime] = useState('')
    const [xyz, setXyz] = useState([])
//     const [xyz, setXyz] = useState([
//         {
//             name: 'r',
//             data: [
//                 9.7005, 9.7034, 9.6865, 9.684, 9.6843, 9.6957,
//                 9.6937, 9.7011, 9.696, 9.7035, 9.6959, 9.6866,
//                 9.6958, 9.6747, 9.7056, 9.6796, 9.6985, 9.6794,
//                 9.6865, 9.6889, 9.6866, 9.6889, 9.6864, 9.7008,
//                 9.7031, 9.6819, 9.7077, 9.6773, 9.7055, 9.6935,
//                 9.6913, 9.6959, 9.6842, 9.6915, 9.691, 9.7033,
//                 9.7102, 9.7007, 9.6843, 9.6842, 9.7055, 9.6934,
//                 9.6984, 9.7059, 9.689, 9.691, 9.6817, 9.6817,
//                 9.6818, 9.6773, 9.6819, 9.6888, 9.689, 9.6986,
//                 9.6864, 9.679, 9.6889, 9.6937, 9.6768, 9.6794
//             ]
//         },
//         {
//             name: 'θ',
//             data: [
//                 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
//                 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
//                 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
//                 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
//                 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
//             ],
//             yaxis: 2
//         },
//         {
//             name: 'φ',
//             data: [
//                 0, 8, 4, 5, 2, 8, 8, 3, 6, 3, 5, 2,
//                 2, 11, 3, 9, 3, 3, 9, 1, 7, 3, 4, 3,
//                 1, 0, 4, 3, 0, 6, 0, 5, 2, 1, 2, 4,
//                 0, 1, 4, 9, 6, 6, 4, 2, 2, 8, 7, 7,
//                 6, 9, 6, 8, 2, 8, 3, 0, 6, 3, 7, 8
//             ],
//             yaxis: 2
//         }
// ])
    const [xyzLabels, setXyzLabels] = useState([])
    const [minXyz, setMinXyz] = useState(0)
    const [maxXyz, setMaxXyz] = useState(0)
    const [avgXyz, setAvgXyz] = useState(0)
    const [wsocket, setWsocket] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [reconnect, setReconnect] = useState(0)
    const [screenWidth, setScreenWidth] = useState(0)

    const IMEI = new URL(window.location.href).searchParams.get('IMEI');

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let timer

        setInterval(() => {
            if (!wsocket) {
                setReconnect(r => r + 1)
            } else if (!isPlaying) {
                wsocket.send(JSON.stringify({ IMEI }));
            }
        }, 5000)

        return (
            clearInterval(timer)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let temp = []
        for (let i = 0; i < 60; i++) {
            if (i % 10 === 0)
                 temp.push((i - 60) + ' s')
            else if (i === 59) temp.push('0 s')
            else temp.push('')
        }
        setXyzLabels(temp)

        let ws
        async function fetchData() {
            const deviceInfo = await DeviceService.getDeviceInfo({ IMEI })
            console.log('deviceInfo', deviceInfo)
            if (deviceInfo.Model === 'RSX-511') {
                ws = new WebSocket('wss://sensor.metainnotech.co.kr/socket/v1/dashboard/xyz2');
            } else {
                ws = new WebSocket('wss://sensor.rnslab.com/socket/v1/dashboard/xyz2');
            }

            // 웹소켓 이벤트 리스너를 설정합니다.
            ws.onopen = () => {
                setWsocket(ws)
                console.log('웹소켓이 연결되었습니다.');
                ws.send(JSON.stringify({ IMEI, type: 'xyz2' }));
                setIsPlaying(false)

            };

            ws.onmessage = (event) => {
                setIsPlaying(true)

                // 서버로부터 메시지를 수신했을 때 실행됩니다.
                let data = JSON.parse(event.data)

                if (data?.xyz) setXyz(data.xyz)
                if (data?.xyzMax) setMaxXyz(data.xyzMax)
                if (data?.xyzMin) setMinXyz(data.xyzMin)
                if (data.xyzAvg) setAvgXyz(data.xyzAvg)

                ws.send(JSON.stringify({ IMEI }));
                setIsPlaying(false)

            };

            ws.onclose = () => {
                console.log('웹소켓이 닫혔습니다.');
            };

        }

        fetchData();

        // 컴포넌트가 언마운트될 때 웹소켓 연결을 종료합니다.
        return () => {
            ws.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconnect]);

    let options = {
        series: xyz,
        // labels: xyzLabels,
        chart: {
            animations: {
                enabled: false
            },
            type: 'area',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20,
        },
        colors: [
            '#ff6666', // red
            '#6666ff', // blue
            '#008000', // green
        ],
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        yaxis: [
            {
                forceNiceScale: true,
                showAlways: true,
                seriesName: 'r',
                labels: {
                    formatter: function (val) {
                        return val.toFixed(1);
                    },
                },
                title: {
                    text: 'm/s²',
                    offsetX: 27,
                    offsetY: -97,
                    rotate: 0,
                    style: {
                        fontWeight: '#000',
                    }
                },
                tickAmount: 8,
                min: 0,
                max: 60,
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true
                }
            },
            {
                forceNiceScale: true,
                showAlways: true,
                tickAmount: 8,
                seriesName: 'θ',
                opposite: true,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0) + '°';
                    },
                },
                min: 0,
                max: 360,
            },
        ],
        xaxis: {
            categories: xyzLabels,
            // tickAmount: 7,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
                rotate: 0,
                rotateAlways: false,
            },
            title: {
                text: '',
                rotate: 0,
            },
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val) {
                    return val + ' '
                }
            }
        },
        annotations: {
            xaxis: xyzLabels.map(x =>
                x !== '' ? {
                    x: x, // x 축의 값을 기준으로 세로선을 그립니다.
                    borderColor: '#cecece',
                    strokeDashArray: 0,
                    opacity: 0.5,
                } : '').filter(x => x !== ''),
        }
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h2>진동</h2>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>r: {xyz[0]?.data[xyz[0]?.data?.length - 1].toFixed(2)}m/s²</div>
                <div style={{ marginLeft: 20 }}>θ: {xyz[1]?.data[xyz[1]?.data?.length - 1]}°</div>
                <div style={{ marginLeft: 20 }}>φ: {xyz[2]?.data[xyz[2]?.data?.length - 1]}°</div>
            </div>
            <ReactApexChart
                style={{ marginLeft: 5 }}
                options={options}
                series={options.series}
                width={screenWidth + 45}
                type={'line'}
                height={250}
            />
        </>
    );
});

export default XyzChartPage;
