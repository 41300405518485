import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMst } from '../../../models';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import useDarkMode from '../../../hooks/useDarkMode';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import DonutChart from './DonutChart';
import AdminDashboard from '../../dashboard/component/AdminDashboard';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Page from '../../../layout/Page/Page';
import moment from 'moment';
import ReactSelect from 'react-select';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import { Badge } from '../../../components/icon/material-icons';
import ReactApexChart from 'react-apexcharts';
import Popovers from '../../../components/bootstrap/Popovers';
import * as locales from 'react-date-range/dist/locale';
import {
	addDays,
	format,
	startOfISOWeek,
	endOfISOWeek,
	startOfMonth,
	endOfMonth,
	getISODay,
	endOfWeek,
	startOfWeek,
} from 'date-fns';
import { DateRange } from 'react-date-range';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import DeviceService from '../../../services/DeviceService';
import HeaderTop from '../HeaderTop';
import ArDeviceModal from './ArDeviceModal';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PacketService from '../../../services/PacketService';


const ArDashboardPage = observer(() => {
	const { user, company, notifiction } = useMst();
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);

	const [dailySum, setDailySum] = useState(0);
	const [monthlySum, setMonthlySum] = useState(0);
	const [yearlySum, setYearlySum] = useState(0);
	const [rank, setRank] = useState([]);
	const [chartLabels, setChartLabels] = useState([]);
	const [chartSeries, setChartSeries] = useState([]);
	const [deviceChartData, setDeviceChartData] = useState([]);
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [devices, setDevices] = useState([]);
	const [status, setStatus] = useState('정상');
	const [params, setParams] = useState({});
	const [isOpen, setIsOpen] = useState(false);

	const generateRandomCoordinates = (center, range, numPoints) => {
		const { lat, lng } = center;
		const coordinates = [];

		for (let i = 0; i < numPoints; i++) {
			const randomLat = lat + (Math.random() - 0.5) * range;
			const randomLng = lng + (Math.random() - 0.5) * range;
			coordinates.push({ lat: randomLat, lng: randomLng });
		}

		return coordinates;
	}

	const getData = useCallback(async () => {
		const { data } = await DeviceService.getTopdata(params)
		setDailySum(data.dailySum)
		setMonthlySum(data.monthlySum)
		setYearlySum(data.yearlySum)
		setRank(data.rank)
	}, [params])

	const getChart = useCallback(async () => {
		const { data } = await DeviceService.getChart(params)
		setChartLabels(data.labels)
		setChartSeries(data.series)
	}, [params])

	const getDeviceChart = useCallback(async () => {
		const { data } = await DeviceService.getDeviceChart(params)
		setDeviceChartData(data)

		let temp = []
		data.filter((x) => x.count > 0).map(d => temp = temp.concat(d.devices))

		setDevices(temp)

	}, [params])

	useEffect(() => {
		getData()
		getChart()
		getDeviceChart()
	}, [getData, getChart, getDeviceChart]);

	let options = {
		title: {
			enabled: true,
			text: '검침값 추이',
			align: 'left'
		},
		legend: {
			show: true
		},
		series: chartSeries,
		colors: [
			'#06C',
			'#5752D1',
			'#C9190B',
			'#4CB140',
			'#009596',
			'#F4C145',
			'#EC7A08',
			'#7D1007',
			'#B8BBBE',
			'#002F5D',
			'#23511E',
			'#003737',
			'#2A265F',
			'#C58C00',
			'#8F4700',
			'#2C0000',
			'#6A6E73',
		],
		labels: chartLabels,
		chart: {
			title: {
				text: '검침값 추이',
				align: 'left'
			},
			type: 'area',
			height: 500,
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			},
			offsetX: -20
		},
		dataLabels: {
			enabled: true
		},
		markers: {
			size: 0,
		},
		title: {
			text: '',
			align: 'center'
		},
		stroke: {
			show: true,
			lineCap: 'butt',
			colors: undefined,
			width: 1,
			dashArray: 0,
		},
		// fill: {
		//     type: 'gradient',
		//     gradient: {
		//         shadeIntensity: 1,
		//         inverseColors: false,
		//         opacityFrom: 0.5,
		//         opacityTo: 0,
		//         stops: [0, 90, 100]
		//     },
		// },
		yaxis: {
			labels: {
				formatter: function (val) {
					return val;
				},
			},
			title: {
				text: '(m³)',
				offsetX: 25,
				offsetY: -120,
				rotate: 0,
			},
		},
		xaxis: {
			labels: {
				formatter: function (val, i) {
					return val;
				},
			},
			title: {
				text: ''
			},
		},
		grid: {
			borderColor: '#e7e7e7',
			row: {
				colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
				opacity: 0.5
			},
		},
		tooltip: {
			shared: false,
			y: {
				formatter: function (val) {
					return val + ' m<sup>3</sup>'
				}
			}
		}
	};

	const excelDownload = async () => {
		let tempData = []
		let data = []

		let count = 0;
		do {
			params.type = 'DATA'
			params.page = Math.floor(tempData.length / 30) + 1
			const response = await PacketService.getPacketList2(params)
			data = response.data?.list?.reverse() || []
			if (count === 0) count = response.data?.totalCount || 0

			tempData = tempData.concat(data)
			user.setProgress(Math.floor((tempData.length / count) * 100))
		} while (data.length > 0 && tempData.length < count) 

		const wb = XLSX.utils.book_new();

		let temp = []
		let dayFormat = 'YYYY-MM-DD'
		let monthFormat = 'YYYY-MM'
		let yearFormat = 'YYYY'

		let day = {}
		let month = {}
		let year = {}

		tempData.map((x) => {
			let dayKey = moment(x.Sensing_Time).format(dayFormat)
			let monthKey = moment(x.Sensing_Time).format(monthFormat)
			let yearKey = moment(x.Sensing_Time).format(yearFormat)

			if (!day[dayKey]) {
				day[dayKey] = 0
			}

			if (!month[monthKey]) {
				month[monthKey] = 0
			}

			if (!year[yearKey]) {
				year[yearKey] = 0
			}

			day[dayKey] += x.Water_Meter[0]
			month[monthKey] += x.Water_Meter[0]
			year[yearKey] += x.Water_Meter[0]

			temp.push({
				'검침시간': moment(x.Sensing_Time).format('YYYY-MM-DD HH:mm:ss'),
				'수용가명': x.install?.customerName || '',
				'수용가번호': x.install?.acceptNumber || '',
				'주소': (x?.customerAddress || '') + ' ' + (x?.customerAddressDetail || ''),
				'계량기번호': x?.water_meter_number || '',
				'계량기구경': x?.Diameter || '',
				'보고주기': x?.AR_term || '',
				'검침주기': x?.SR_term || '',
				'사용량': x?.Water_Meter[0] || '',
				'온도': x?.Temperature_In || '',
				'습도': x?.Humidity_In || '',
				'통신성공률': x?.E_RATE || '',
				'기기상태': x?.Status || '',
				'배터리': x?.BAT || '',
				'탁도': x?.BAT || '',
				'IMEI': x?.IMEI || '',
				'SerialNumber': x?.SerialNumber || '',
			})
		})

		const ws = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws, '데이터');

		temp = []
		Object.keys(day).map((key) => {
			temp.push({
				'일자': key,
				'총 사용량(m³)': day[key]
			})
		})

		const ws2 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws2, '일자별');

		temp = []
		Object.keys(month).map((key) => {
			temp.push({	
				'연도': key.split('-')[0],
				'월': key.split('-')[1],
				'총 사용량(m³)': month[ key]	
			})
		})

		const ws3 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws3, '월별');

		temp = []
		Object.keys(year).map((key) => {
			temp.push({
				'연도': key,
				'총 사용량(m³)': year[key]
			})
		})

		const ws4 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws4, '연도별');

		temp = []
		deviceChartData.map((x) => {
			temp.push({
				'장비상태': x.label,
				'장비수': x.count
			})
		})

		const ws5 = XLSX.utils.json_to_sheet(temp);
		XLSX.utils.book_append_sheet(wb, ws5, '장비현황');	

		XLSX.writeFile(wb, `대시보드 ${moment(params.startDate).format('YYYY년 MM월 DD일')}~${moment(params.endDate).format('YYYY년 MM월 DD일')}_${moment().format('YYYY년 MM월 DD일')}.xlsx`);
	}

	const excelUpload = async () => {
	}


	return (
		<PageWrapper className='fluid' title={t('대시보드')}>
			<SubHeader className={'belt-header'}>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={'/ardashboard'}>
							{t('대시보드')}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>	
				<SubHeaderRight>
					<InputGroup>
						<InputGroupText>
							{moment().format(`YYYY${t('년')} MM${t('월')} DD${t('일')} (dd)`)}
							{/* {moment().format('YYYY') + t('대시보드년')}
							{moment().format('MM') + t('대시보드월')}
							{moment().format('DD') + t('대시보드일')} */}
						</InputGroupText>
						{/* <Button
						color={'info'}
						onClick={() => {
							setIsOpenSendMail(true);
						}}>
						{t('근태보고 메일 발송')}
					</Button> */}
					</InputGroup>
				</SubHeaderRight>
			</SubHeader>

			<Page container='fluid' style={{paddingTop: '0.7rem'}} >
				<Card stretch className={`custom-box-shadow rounded-2 p-0`} >
					<CardBody className={`rounded-2 align-items-center text-center p-3 py-1`} >
						<HeaderTop
							style={{ boxShadow: '0 0.6rem 1.2rem rgba(0, 0, 0, 0.3);' }}
							totalText={'전체 수용가 수: ' + (deviceChartData.length > 0 ? deviceChartData.filter(x => x.count > 0)?.map(m => m?.count || 0)?.reduce((a, b) => a + b) : 0)}
							setParams={setParams}
							disableExcelUp={true}
							markers={devices}
							excelDownload={excelDownload}
							excelUpload={excelUpload}
							startDate={moment().subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD')}
							endDate={moment().format('YYYY-MM-DD')}
						/>
					</CardBody>
				</Card>
				<div className='row'>
					<div className='col-xl-9'>
						<Card stretch className={`custom-box-shadow rounded-2`} style={{height: 200}}>
							<CardBody className={`rounded-2 align-items-center text-center d-flex flex-row justify-content-between`} >
								{/* <div className='row' style={{padding:20}}> */}
								<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 175 }}>
										<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #2F3268', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);'}}>
											<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#2F3268', color: '#ffffff' }}>
												<CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{'일일 사용량'}(m<sup>3</sup>)</CardTitle>
												<CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{dailySum}</CardSubTitle>
											</CardBody>
										</Card>
									</div>
								<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 175 }}>
										<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #6799FF', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
											<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#6799FF', color: '#ffffff' }}>
												<CardTitle style={{ fontSize: '1.5rem', marginTop: 10   }}>{'월간 사용량'}(m<sup>3</sup>)</CardTitle>
												<CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{monthlySum}</CardSubTitle>
											</CardBody>
										</Card>
									</div>
								<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 175 }}>
										<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #FE808B', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
											<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#FE808B', color: '#ffffff' }}>
												<CardTitle  style={{ fontSize: '1.5rem', marginTop: 10 }}>{'연간누적 사용량'}(m<sup>3</sup>)</CardTitle>
												<CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{yearlySum}</CardSubTitle>
											</CardBody>
										</Card>
									</div>
								<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10, height: 175 }}>
										<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #FFB063', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
											<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#FFB063', color: '#ffffff' }}>
												<CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{'최다 사용 수용가'}(m<sup>3</sup>)</CardTitle>
												{ rank.map((x, i) => (
													<CardSubTitle key={i} className='mt-2' style={{ textAlign: 'center', fontSize: '1.2rem', marginTop: 10 }}>{`${i + 1}. ${x[0]} (${x[1]}`}m<sup>3</sup>)</CardSubTitle>
												))}
												{ rank.length < 1 && <CardSubTitle className='mt-2' style={{ textAlign: 'center', fontSize: '1.2rem', marginTop: 10 }}>없음</CardSubTitle>}
											</CardBody>
										</Card>
									</div>
								{/* </div> */}
							</CardBody>
						</Card>
						<div>
							<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #fafafa', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
								<CardBody className={`rounded-2  justify-content-center`} style={{ backgroundColor: '#ffffff', color: '#2F3268' }}>
								<div><h4><b>검침값 추이</b></h4></div>
								<ReactApexChart
									options={options}
									series={options.series}
									width={'100%'}
									type={'line'}
									height={350}
								/>
								</CardBody>
							</Card>
						</div>
					</div>
					<div className='col-xl-3 col-lg-3 col-md-3 text-center' style={{ padding: 0 }}>
						<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #fafafa', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1);' }}>
							<CardBody className={`rounded-2  justify-content-center`} style={{ backgroundColor: '#ffffff', color: '#2F3268' }}>
								<ReactApexChart
									options={{
										title: {
											text: '장비 현황',
											align: 'center'
										},
										colors: deviceChartData.filter((x) => x.count > 0).map((x) => x.color),
										chart: {
											type: 'donut',
											height: 250,
											events: {
												dataPointSelection: (event, chartContext, config) => {
													// 데이터 포인트가 클릭되었을 때 실행될 함수
													console.log("Data Point Clicked: ", config.dataPointIndex);
													console.log("Series Index: ", config.seriesIndex);

													setSelectedDevices(deviceChartData.filter((x) => x.count > 0)[config.dataPointIndex].devices)
													setStatus(deviceChartData.filter((x) => x.count > 0)[config.dataPointIndex].label)
													setIsOpen(true);
													// 원하는 동작을 추가하세요
												}
											},
										},
										stroke: {
											width: 0,
										},
										labels: deviceChartData.filter((x) => x.count > 0).map((x) => x.label),
										dataLabels: {
											enabled: false,
										},
										plotOptions: {
											pie: {
												expandOnClick: true,
												donut: {
													labels: {
														show: true,
														name: {
															show: true,
															fontSize: '24px',
															fontFamily: 'Poppins',
															fontWeight: 900,
															offsetY: 0,
															formatter(val) {
																return val;
															},
														},
														value: {
															show: true,
															fontSize: '16px',
															fontFamily: 'Poppins',
															fontWeight: 900,
															offsetY: 16,
															formatter(val) {
																return val;
															},
														},
													},
												},
											},
										},
										legend: {
											show: false,
											position: 'bottom',
											floating: true,
											offsetY: 100,
											height: 200,
											showForSingleSeries: true
										},
									}}
									series={deviceChartData.filter((x) => x.count > 0).map((x) => x.count)}
									width={'100%'}
									type={'donut'}
									height={250}
								/>
								<div style={{padding: 30}}>
									{deviceChartData.filter((x) => x.count > 0).map((x, i) => (
										<div key={i} className='row pointer' 
											onClick={() => {
												setSelectedDevices(x.devices)
												setStatus(x.label)
												setIsOpen(true)
											}}
										>
											<div style={{ backgroundColor: x.color, width: 25, height: 25, margin: 5}} />
											<div className='col text-start pt-2'><b>{x.label} ({x.count})</b></div>
										</div>
									))}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
				<ArDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={selectedDevices} status={status} />
			</Page>
		</PageWrapper>
	);
});

export default ArDashboardPage;