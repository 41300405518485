import React, { useState, useContext, useEffect } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import logo from '../../../assets/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey } from '../../../lang';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/icon/Icon';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import showNotification from '../../../components/extras/showNotification';
import UserService from '../../../services/UserService';
import { useMst } from '../../../models';
import ThemeContext from '../../../contexts/themeContext';
import moment from 'moment';

const Login = (props) => {
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const { t, i18n } = useTranslation(['translation', 'menu']);
	const { user } = useMst();
	const { setNoticeCount, lang, setLang } = useContext(ThemeContext);
	const [visiblePW, setVisiblePW] = useState(false);
	// const { updateUser } = useChannelIOApi(); // 새로운 버전에서 x

	const loginData = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: (values) => {
			if (!values.email || !values.password) {
				showNotification(t('로그인'), t('필수 정보를 확인해주세요.'), 'danger');
			} else {
				requestLogin(values);
			}
		},
	});

	const changeLanguage = (lng) => {
		i18n
			.changeLanguage(lng)
			.then(() => {
				moment.locale(lng);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
						<span>{`${getLangWithKey(lng)?.text}`}</span>
					</span>,
					t('You updated the language of the site.')
				);
			})
			.then(() => {
				setLang(lng);
			});
	};

	// 로그인 시 읽지 않은 게시물 카운트
	const readNoticeCount = async () => {
		const noticeCount = await BoardService.readCount('notice', user.me.currentCompanyId);
		setNoticeCount(noticeCount?.data || 0);
	};

	const requestLogin = async (values) => {
		const loginData = await UserService.login(values);
		if (loginData?.message) {
			// 로그인 실패 시
			showNotification(t('로그인'), t(loginData?.message), 'danger');
		} else {
			user
				.authMe()
				.then(async (me) => {
					if (me.type === 'WORKER') {
						navigate('/install')
					} else if (me.model === 'SR') {
						navigate('/srdashboard')
					} else if (me.model === 'AR') {
						navigate('/ardashboard')
					} else if (me.model === 'NR') {
						navigate('/nrdashboard')
					} else if (me.model === 'WR') {
						navigate('/wrdashboard')
					} else {
						navigate('/dashboard')
					}
				})
				.catch((e) => {
					console.log(e);
					showNotification(t('로그인'), t('로그인에 실패하였습니다.') + t('다시 확인해주세요') + '.', 'danger');
				});
		}
	};

	return (
		<PageWrapper title={'Login'} className={classNames('bg-info', 'main-bg')}>
			<Page>
				<div className='row h-100 align-items-center'>
					<div className='col-xl-4 col-lg-6 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5' style={{fontSize: 50}}>
									<img className='thumbnail' width='300' src={logo} alt='RNSLab' />
									{/* <logo /> */}
									{/* RNSLab */}
								</div>
								<div
									className={classNames('rounded-pill', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row'>
										<div className='col'>
											<Button color={darkModeStatus ? 'light' : 'dark'} className='rounded-pill w-100' size='lg' onClick={() => {}}>
												{t('로그인')}
											</Button>
										</div>

										{/* <div className='col'>
											<Link to={'/' + demoPages.signUp.path}>
												<Button color={darkModeStatus ? 'light' : 'dark'} isLight className='rounded-pill w-100' size='lg' onClick={() => {}}>
													{t('회원가입')}
												</Button>
											</Link>
										</div> */}
									</div>
								</div>
								<div className='text-center my-5'>
									<div className='fw-bold h1'>{t('환영합니다')},</div>
									<div className='h4 text-muted'>{t('로그인 후 이용해주세요')}!</div>
								</div>

								<form onSubmit={loginData.handleSubmit}>
									<FormGroup className='mb-4' label={t('아이디 또는 이메일')}>
										<Input id='email' placeholder='아이디 또는 이메일' className='py-3' type='text' value={loginData.values.email} onChange={loginData.handleChange} />
									</FormGroup>

									<FormGroup label={t('비밀번호')}>
										<InputGroup>
											<Input id='password' placeholder='비밀번호' className='py-3' type={visiblePW ? 'text' : 'password'} value={loginData.values.password} onChange={loginData.handleChange} />
											<Button onClick={() => setVisiblePW(!visiblePW)}>
												<Icon icon={visiblePW ? 'Visibility' : 'VisibilityOff'} />
											</Button>
										</InputGroup>
									</FormGroup>

									<div className='my-5 py-3'>
										<Button
											id='login-btn'
											className='w-100 mb-3 py-3'
											color='info'
											type='submit'
											onClick={() => {
												if (!loginData.isValid) {
													showNotification(t('로그인'), t('아이디 또는 비밀번호가 틀렸습니다.'), 'danger');
												}
											}}>
											{t('로그인')}
										</Button>
										{/* <Link to={'/' + pawsswordFind.PasswdReset.path}>
											<Button className='w-100 mt-1 py-3' id='resetPW-btn' color='light'>
												{t('비밀번호 찾기')}
											</Button>
										</Link> */}
									</div>
								</form>
							</CardBody>
						</Card>

						<div className='text-center'>
							{/* <Link to={'/' + demoPages.term01.path}>
								<Button
									className='btn-transperant py-1'
									color='light'
									style={{
										backgroundColor: 'rgba(0, 0, 0, 0.1)',
										borderColor: 'rgba(0, 0, 0, 0.1)',
										color: '#fff',
									}}>
									{t('개인정보취급방침')}
								</Button>
							</Link>
							<Link to={'/' + demoPages.term02.path}>
								<Button
									className='btn-transperant py-1'
									color='light'
									style={{
										backgroundColor: 'rgba(0, 0, 0, 0.1)',
										borderColor: 'rgba(0, 0, 0, 0.1)',
										color: '#fff',
									}}>
									{t('서비스이용약관 ')}
								</Button>
							</Link>
							<Link to={'/' + demoPages.term03.path}>
								<Button
									className='btn-transperant py-1'
									color='light'
									style={{
										backgroundColor: 'rgba(0, 0, 0, 0.1)',
										borderColor: 'rgba(0, 0, 0, 0.1)',
										color: '#fff',
									}}>
									{t('위치기반서비스 이용약관')}
								</Button>
							</Link> */}
							{/* <a
								href={'//ita.bhz.co.kr/terms01.html'}
								target={'_blank'}
								rel='noopener noreferrer'
								className={classNames('link-light text-decoration-none me-3', {
									'link-light': false,
									'link-light': true,
								})}>
								{t('개인정보취급방침')}
							</a>
							<a
								href='//ita.bhz.co.kr/terms.html'
								target={'_blank'}
								rel='noopener noreferrer'
								className={classNames('link-light text-decoration-none', {
									'link-light': false,
									'link-light': true,
								})}>
								{t('서비스 이용약관')}
							</a> */}
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
export default Login;
